import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-rollback-progress-dialog',
    template: `
        <div class="sized-modal py1">
            <div
                class="modal-content flex justify-center items-center flex-column p4"
            >
                <span
                    class="icon icon-data-integration-black lg-icon m-b2"
                ></span>
                <h3 class="sized-title text-center">
                    Please wait while we apply your new settings
                </h3>
                <p class="sized-txt text-center gray-mid-text">
                    We are currently updating your device to the versions you
                    selected. Once the update is complete your device will
                    restart automatically.
                </p>
                <aw-spinner [spinnerColor]="'spinner-blue-only'"></aw-spinner>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RollbackProgressDialogComponent {}

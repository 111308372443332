import { SYSTEM_COMMANDS_TYPES } from '@/shared/constants/system-command';
import { Injectable } from '@angular/core';
import { IIpcResponseHandler } from '../../interfaces';
import { IpcObserverService } from './ipc-observer.service';

@Injectable({ providedIn: 'root' })
export class DefaultResponseService implements IIpcResponseHandler {
    constructor(private _observerService: IpcObserverService) {}

    handleResponse(response): any {
        const { command } = response;
        switch (command) {
            case SYSTEM_COMMANDS_TYPES.FACTORY_DEFAULT:
                this._observerService.notifyChanges({
                    command: SYSTEM_COMMANDS_TYPES.FACTORY_DEFAULT,
                    data: response,
                });
                break;
            default:
                break;
        }
        return response;
    }
}

import { ConfirmModalComponent } from '@/core/components/confirm-modal/confirm-modal.component';
import { LostNetworkModalComponent } from '@/core/components/lost-network-modal/lost-network-modal.component';
import { LowBatteryModalComponent } from '@/core/components/low-battery-modal/low-battery-modal.component';
import { SettingsModalComponent } from '@/settings/settings-modal/settings-modal.component';
import { SettingsPinModalComponent } from '@/settings/settings-pin-modal/settings-pin-modal.component';
import { SessionService, VideoService } from '@/shared/services';
import {
    selectActiveModal,
    selectActiveToast,
    selectAppUpdate,
} from '@/shared/storage/selectors';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AwToastInterface } from '@aw-hospital/aw-components-lib/src/interfaces';
import { ToastService } from '@aw-hospital/aw-components-lib/src/services/toast';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { exhaustMap, filter, tap, withLatestFrom } from 'rxjs/operators';
import { setPtzFeccPositionHome, setPtzFeccPositionPrivacy } from '../ptz';
import * as LayoutAction from './layout.actions';
import { setAppUpdate } from './layout.actions';
import { IActiveModal, IActiveToast, IAppUpdate } from './layout.reducer';

@Injectable()
export class LayoutEffects {
    modalMap = new Map<string, any>();
    noty: AwToastInterface;
    constructor(
        private _action$: Actions,
        public _matDialog: MatDialog,
        private _toastService: ToastService,
        private _store: Store,
        private _videoService: VideoService,
        private _router: Router,
        private _sessionService: SessionService
    ) {
        this._setupModal();
        this._setupToast();
    }

    private _setupModal() {
        this.modalMap.set(
            SettingsModalComponent.dialogName,
            SettingsModalComponent
        );
        this.modalMap.set(
            SettingsPinModalComponent.dialogName,
            SettingsPinModalComponent
        );
        this.modalMap.set(
            ConfirmModalComponent.dialogName,
            ConfirmModalComponent
        );
        this.modalMap.set(
            LostNetworkModalComponent.dialogName,
            LostNetworkModalComponent
        );
        this.modalMap.set(
            LowBatteryModalComponent.dialogName,
            LowBatteryModalComponent
        );

        this._store
            .select(selectActiveModal)
            .subscribe((modal: IActiveModal) => {
                const component =
                    !!modal && this.modalMap.get(modal.componentName);
                const modelRef$ =
                    !!modal &&
                    !this._matDialog.getDialogById(modal.config.id) &&
                    this._matDialog.open(component, modal.config);

                !!modal?.getDialogRef && modal.getDialogRef(modelRef$);
                !!modal?.getModalId && modal.getModalId(modal.config.id);

                !!modelRef$ &&
                    modelRef$
                        ?.afterClosed()
                        .toPromise()
                        .then((msg) => {
                            this._store.dispatch(
                                LayoutAction.removeActiveModal({
                                    modal: {
                                        componentName: modal.componentName,
                                        modalKey: modelRef$.id,
                                    },
                                })
                            );
                            !!modal?.afterClosed && modal?.afterClosed(msg);
                        });

                !!modelRef$ &&
                    modelRef$
                        ?.afterOpened()
                        .toPromise()
                        .then((message) => {
                            !!modal?.afterOpened && modal.afterOpened(message);
                        });
            });
    }

    private _setupToast() {
        this._store
            .select(selectActiveToast)
            .subscribe((toast: IActiveToast) => {
                if (toast) {
                    let isActionExecuted = false;
                    const toastRef = this._toastService.show(toast.options);
                    this.noty = toastRef;
                    toastRef.toastClose.subscribe((msg) => {
                        this._store.dispatch(
                            LayoutAction.removeActiveToast({
                                toast: {
                                    toastKey: toast.toastKey,
                                },
                            })
                        );
                        !isActionExecuted &&
                            toast.closeCallback &&
                            toast.closeCallback(msg);
                    });

                    toastRef.toastAction.subscribe((msg) => {
                        this._store.dispatch(
                            LayoutAction.removeActiveToast({
                                toast: {
                                    toastKey: toast.toastKey,
                                },
                            })
                        );
                        isActionExecuted = true;
                        toast.actionCallback && toast.actionCallback(msg);
                    });
                }
            });
    }

    openModal$ = createEffect(() =>
        this._action$.pipe(
            ofType(LayoutAction.openModal),
            exhaustMap((payload) => {
                const key =
                    payload.modal.componentName + '_' + new Date().getTime();
                const modal = {
                    componentName: payload.modal.componentName,
                    config: { ...payload.modal.config, id: key },
                    modalKey: key,
                    afterClosed: payload.modal?.afterClosed,
                    afterOpened: payload.modal?.afterOpened,
                    getDialogRef: payload.modal?.getDialogRef,
                } as IActiveModal;
                return of(LayoutAction.addActiveModal({ modal }));
            })
        )
    );

    closeAllModal$ = createEffect(
        () =>
            this._action$.pipe(
                ofType(LayoutAction.closeAllModal),
                tap(() => {
                    this._matDialog.closeAll();
                })
            ),
        { dispatch: false }
    );

    closeModal$ = createEffect(
        () =>
            this._action$.pipe(
                ofType(LayoutAction.closeModal),
                tap((payload) => {
                    this._matDialog.getDialogById(payload.modalId).close();
                })
            ),
        { dispatch: false }
    );

    showToast$ = createEffect(() =>
        this._action$.pipe(
            ofType(LayoutAction.showToast),
            exhaustMap((payload) => {
                const key = `${Date.now()}`;
                const toast = {
                    toastKey: key,
                    options: { ...payload.toast.options },
                    closeCallback: payload.toast?.closeCallback,
                    actionCallback: payload.toast?.actionCallback,
                } as IActiveToast;
                return of(LayoutAction.addActiveToast({ toast }));
            })
        )
    );

    rejoinConvergeCall$ = createEffect(() =>
        this._action$.pipe(
            ofType(LayoutAction.rejoinConvergeCall),
            filter(() => this._videoService.isConvergeCallRoom()),
            exhaustMap(() => {
                const twilioRoom = this._videoService.getRoom();

                // Check if there is already a toast opened and close it.
                if (this.noty && this.noty.toast) {
                    this.noty.toast.close();
                    this.noty = null;
                }

                const toast = {
                    options: {
                        timeout: false,
                        content: {
                            title: 'Your call has ended',
                            description: 'You can rejoin this call again',
                        },
                        action: 'Join',
                    },
                    closeCallback: (): void => {
                        this._videoService.removeRoom();
                    },
                    actionCallback: (): void => {
                        this._store.dispatch(setPtzFeccPositionHome());
                        this._videoService.setRoom({ data: twilioRoom });
                        this._router.navigate(['/converge-video']);
                    },
                    // The types of 'options.timeout' are incompatible between these types.
                    // Type 'boolean' is not comparable to type 'number'.
                } as unknown as IActiveToast;

                return of(LayoutAction.showToast({ toast }));
            })
        )
    );
    // Application Update
    setAvailableAppUpdate$ = createEffect(() =>
        this._action$.pipe(
            ofType(LayoutAction.setAppUpdateAvailable),
            withLatestFrom(this._store.select(selectAppUpdate)),
            exhaustMap(([payload, appUpdate]) => {
                const appUpdateAvailable = {
                    ...appUpdate,
                    ...({
                        currentVersion: payload.appUpdate.currentVersion,
                        previousVersion: payload.appUpdate.previousVersion,
                        isAppUpdateAvailable:
                            payload.appUpdate.isAppUpdateAvailable,
                    } as IAppUpdate),
                };
                return [setAppUpdate({ appUpdate: appUpdateAvailable })];
            })
        )
    );

    redirectToMain$ = createEffect(
        () =>
            this._action$.pipe(
                ofType(LayoutAction.navigateToMain),
                tap(() => {
                    if (this._sessionService.isTVKit) {
                        this._router.navigateByUrl('/intmhomescreen');
                    } else if (this._sessionService.allowCaseCreation) {
                        this._router.navigateByUrl('/main');
                    } else {
                        this._router.navigateByUrl('/empty-dashboard');
                    }
                }),
                tap(() => this._store.dispatch(setPtzFeccPositionPrivacy()))
            ),
        { dispatch: false }
    );

    closeActiveToast$ = createEffect(
        () =>
            this._action$.pipe(
                ofType(LayoutAction.closeActiveToast),
                withLatestFrom(this._store.select(selectActiveToast)),
                tap(([, activateToast]) => {
                    if (activateToast) {
                        this.noty.toast.close();
                        return of(
                            LayoutAction.removeActiveToast({
                                toast: {
                                    toastKey: activateToast.toastKey,
                                },
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );
}

import { Injectable, OnDestroy, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    Observable,
    SubscriptionLike,
    Subject,
    Observer,
    interval,
} from 'rxjs';
import { filter, share, distinctUntilChanged, takeWhile } from 'rxjs/operators';
import { WebSocketSubject, WebSocketSubjectConfig } from 'rxjs/webSocket';

import {
    IWsMessage,
    IWebSocketConfig,
    IWebsocketService,
} from '../shared/interfaces/websocket';
import { config } from './websocket.config';
import * as AuthActions from '../shared/storage/auth/auth.actions';
@Injectable({
    providedIn: 'root',
})
export class WebsocketService implements IWebsocketService, OnDestroy {
    config: WebSocketSubjectConfig<IWsMessage>;
    statusSub: SubscriptionLike;
    reconnection$: Observable<number>;
    websocket$: WebSocketSubject<IWsMessage>;
    connection$: Observer<boolean>;
    wsMessages$: Subject<IWsMessage>;
    reconnectInterval: number;
    reconnectAttempts: number;
    status: Observable<boolean>;

    constructor(
        @Inject(config) wsConfig: IWebSocketConfig,
        private _store: Store
    ) {
        this.wsMessages$ = new Subject<IWsMessage>();

        this.reconnectInterval = wsConfig.reconnectInterval || 5000;
        this.reconnectAttempts = wsConfig.reconnectAttempts || 10;

        this.config = {
            url: wsConfig.url,
            closeObserver: {
                next: () => {
                    this.websocket$ = null;
                    this.connection$.next(false);
                },
            },
            openObserver: {
                next: () => {
                    this.connection$.next(true);
                },
            },
        };

        // connection status
        this.status = new Observable<boolean>((observer) => {
            this.connection$ = observer;
        }).pipe(share(), distinctUntilChanged());

        // run reconnect if not connection
        this.statusSub = this.status.subscribe((isConnected) => {
            console.log('...is connected', isConnected);
            if (typeof isConnected === 'boolean') {
                this._store.dispatch(
                    AuthActions.authSetIsWebsocketConnected({
                        isWebsocketConnected: isConnected,
                    })
                );
                if (!this.reconnection$ && !isConnected) {
                    this.reconnect();
                }
            }
        });

        this.connect();
    }

    ngOnDestroy(): void {
        this.statusSub.unsubscribe();
    }
    /* istanbul ignore next */
    connect(): void {
        console.log('..welcome to websocket connect');
        this.websocket$ = new WebSocketSubject(this.config);

        this.websocket$.subscribe(
            (message) => {
                return this.wsMessages$.next(message);
            },
            (err) => {
                console.log('..welcome to websocket connect err', err);
                if (!this.websocket$) {
                    this.reconnect();
                }
            }
        );
    }
    /* istanbul ignore next */
    reconnect(): void {
        console.log('..welcome to websocket reconnect');
        this.reconnection$ = interval(this.reconnectInterval).pipe(
            takeWhile(
                (v, index) => index < this.reconnectAttempts && !this.websocket$
            )
        );

        this.reconnection$.subscribe(
            () => this.connect(),
            (err) => {
                console.log('..welcome to websocket reconnect err', err);
            },
            () => {
                // Subject complete if reconnect attemts ending
                this.reconnection$ = null;

                if (!this.websocket$) {
                    this.wsMessages$.complete();
                    this.connection$.complete();
                }
            }
        );
    }

    on(event: string = null): Observable<any> {
        return this.wsMessages$.pipe(
            filter(
                (filteredSocketRes: any) =>
                    event === null || filteredSocketRes.command === event
            )
        );
    }

    send(payload: any): void {
        if (this.websocket$) {
            this.websocket$.next(payload as any);
        }
    }
}

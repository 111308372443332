import { Injectable } from '@angular/core';
import { StorageService } from '../storage';
import { IIpcResponseHandler, INetwork } from '../../interfaces';
import { IpcObserverService } from './ipc-observer.service';
import { MetricService } from '../metrics';
// eslint-disable-next-line import/no-extraneous-dependencies
import map from 'lodash/map';
import { SYSTEM_COMMANDS_TYPES } from '@/shared/constants/system-command';
import { Store } from '@ngrx/store';
import * as AuthActions from '../../../shared/storage/auth/auth.actions';
import { interval } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NetworkResponseService implements IIpcResponseHandler {
    private networkMetrics = {};
    constructor(
        private _store: Store,
        private _observerService: IpcObserverService,
        private _storageService: StorageService,
        private _metricService: MetricService
    ) {
        interval(60 * 1000).subscribe(
            () => {
                this._metricService.enqueue(this.networkMetrics);
            },
            (error) => console.error(error)
        );
    }
    handleResponse(response): any {
        const { network: networkResponse } = response;
        const { command } = networkResponse;
        switch (command) {
            case SYSTEM_COMMANDS_TYPES.ADD_DOMAIN_TO_DNS:
                this._observerService.notifyChanges({
                    command: SYSTEM_COMMANDS_TYPES.ADD_DOMAIN_TO_DNS,
                    data: response,
                });
                break;
            case SYSTEM_COMMANDS_TYPES.ADD_SERVER_ARRAY_TO_DNS:
                this._observerService.notifyChanges({
                    command: SYSTEM_COMMANDS_TYPES.ADD_SERVER_ARRAY_TO_DNS,
                    data: networkResponse,
                });
                break;
            case SYSTEM_COMMANDS_TYPES.CONNECT:
                this._observerService.notifyChanges({
                    command: SYSTEM_COMMANDS_TYPES.CONNECT_WIFI,
                    data: networkResponse,
                });
                break;
            case SYSTEM_COMMANDS_TYPES.DELETE_NETWORK:
                this._observerService.notifyChanges({
                    command: SYSTEM_COMMANDS_TYPES.DELETE_NETWORK,
                    data: networkResponse,
                });
                break;
            case SYSTEM_COMMANDS_TYPES.GET_DNS_SERVERS:
                this._observerService.notifyChanges({
                    command: SYSTEM_COMMANDS_TYPES.GET_DNS_SERVERS,
                    data: networkResponse,
                });
                break;
            case SYSTEM_COMMANDS_TYPES.GET_LIST:
                if (networkResponse && networkResponse.network_list) {
                    networkResponse.network_list.sort((a, b) =>
                        a.active < b.active
                            ? 1
                            : a.active === b.active
                            ? a.ssid > b.ssid
                                ? 1
                                : -1
                            : -1
                    );
                }

                const networkListResponse = networkResponse.network_list;
                const networkList: INetwork[] = map(
                    networkListResponse,
                    (n) => {
                        return this.convertNetworkData(n);
                    }
                );
                this._storageService.refreshNetworkList(
                    networkResponse.device_list,
                    networkList
                );
                break;
            case SYSTEM_COMMANDS_TYPES.GET_NETWORK_CONFIG:
                this._observerService.notifyChanges({
                    command: SYSTEM_COMMANDS_TYPES.GET_NETWORK_CONFIG,
                    data: networkResponse,
                });
                break;
            case SYSTEM_COMMANDS_TYPES.GET_NETWORK_STATUS:
                this.handleGetNetworkStatus(networkResponse);
                break;
            case SYSTEM_COMMANDS_TYPES.GET_HOSTNAME:
                this._storageService.updateDeviceInfoByKey(
                    'hostName',
                    networkResponse.hostname
                );
                break;
            case SYSTEM_COMMANDS_TYPES.GET_LOCKED_BAND:
                this._observerService.notifyChanges({
                    command: SYSTEM_COMMANDS_TYPES.GET_LOCKED_BAND,
                    data: networkResponse,
                });
                break;
            case SYSTEM_COMMANDS_TYPES.RESOLVE_DEFAULT_DOMAIN:
                this._store.dispatch(
                    AuthActions.authSetIsInternetConnected({
                        isInternetConnected: networkResponse.success === 'true',
                    })
                );
                break;
            case SYSTEM_COMMANDS_TYPES.RESOLVE_DOMAIN:
                const isInternetConnected = networkResponse.success === 'true';
                this._store.dispatch(
                    AuthActions.authSetIsInternetConnected({
                        isInternetConnected: isInternetConnected,
                    })
                );
                this._observerService.notifyChanges({
                    command: SYSTEM_COMMANDS_TYPES.RESOLVE_DOMAIN,
                    data: {
                        ...networkResponse,
                        success: isInternetConnected,
                        reserved: response.reserved,
                    },
                });
                break;
            case SYSTEM_COMMANDS_TYPES.SET_HOSTNAME:
                this._observerService.notifyChanges({
                    command: SYSTEM_COMMANDS_TYPES.SET_HOSTNAME,
                    data: { success: networkResponse.success },
                });
                break;
            case SYSTEM_COMMANDS_TYPES.SET_IP_CONFIG:
                this._observerService.notifyChanges({
                    command: SYSTEM_COMMANDS_TYPES.SET_IP_CONFIG,
                    data: networkResponse,
                });
                break;
            case SYSTEM_COMMANDS_TYPES.SET_LOCKED_BAND:
                this._observerService.notifyChanges({
                    command: SYSTEM_COMMANDS_TYPES.SET_LOCKED_BAND,
                    data: networkResponse,
                });
                break;
            case SYSTEM_COMMANDS_TYPES.GET_ROAMING_THRESHOLD:
                this._observerService.notifyChanges({
                    command: SYSTEM_COMMANDS_TYPES.GET_ROAMING_THRESHOLD,
                    data: networkResponse,
                });
                break;
            case SYSTEM_COMMANDS_TYPES.SET_ROAMING_THRESHOLD:
                this._observerService.notifyChanges({
                    command: SYSTEM_COMMANDS_TYPES.SET_ROAMING_THRESHOLD,
                    data: networkResponse,
                });
                break;
            default:
                break;
        }
    }

    handleGetNetworkStatus(networkResponse): void {
        // device.general.connection will be blank if device is not connected
        // We want to populate hw_address irrespective of connection state
        const {
            device_list: deviceList,
            active_wifi_network: activeWifiNetwork,
        } = networkResponse;
        const ethDevice = deviceList.find(
            (device) => device.general.type === 'ETHERNET'
        );
        if (ethDevice) {
            this._storageService.updateDeviceInfoByKey(
                'ethHwAddress',
                ethDevice.general.hw_addr
            );
        }

        //Axis camera Ethernet to USB connection
        const ethCamera = deviceList.find(
            (device) =>
                device.general.type === 'ETHERNET' &&
                device.general.connection === 'netplan-eth1'
        );

        const wifiDevice = deviceList.find(
            (device) => device.general.type === 'WIFI'
        );
        if (wifiDevice) {
            this._storageService.updateDeviceInfoByKey(
                'wifiHwAddress',
                wifiDevice.general.hw_addr
            );
        }

        let activeDevice;
        deviceList.forEach((device) => {
            if (
                device.general.type === 'ETHERNET' &&
                device.general.state === 'CONNECTED' &&
                device.general.connection === 'netplan-eth0'
            ) {
                activeDevice = device;
                return false;
            } else if (
                device.general.type === 'WIFI' &&
                device.general.state === 'CONNECTED'
            ) {
                activeDevice = device;
            }
        });

        let metrics = null;
        if (activeDevice) {
            metrics = {
                network: activeDevice.general.type,
                hw_address: activeDevice.general.hw_addr,
                ipv4_gateway: activeDevice.ipv4.gateway,
                ipv4_dns: activeDevice.ipv4.dns.join(','),
                ipv4_domain: activeDevice.ipv4.domain.join(','),
                ipv4_address: activeDevice.ipv4.address.join(','),
                ipv6_address: activeDevice.ipv6.address.join(','),
                eth_camera_connected: ethCamera ? true : false,
            };

            if (activeDevice.general.type === 'WIFI') {
                metrics.wifi_ssid = networkResponse.active_wifi_network.ssid;
                metrics.wifi_bars = networkResponse.active_wifi_network.bars;
                metrics.wifi_signal =
                    networkResponse.active_wifi_network.signal + ' %';
                metrics.wifi_channel =
                    networkResponse.active_wifi_network.channel;
                metrics.wifi_frequency =
                    (
                        parseFloat(
                            networkResponse.active_wifi_network.frequency
                        ) / 1000
                    ).toString() + ' GHz';
                metrics.wifi_locked_band =
                    networkResponse.active_wifi_network.locked_band;
            }
            this.networkMetrics = metrics;
        }

        this._storageService.refreshNetworkDevice(
            deviceList,
            activeWifiNetwork ? this.convertNetworkData(activeWifiNetwork) : {}
        );
    }

    convertNetworkData(networkData): INetwork {
        const convertedNetworkData: INetwork = {
            ...networkData,
            active: networkData.active === 'true',
            needs_password: networkData.needs_password === 'true',
            needs_username: networkData.needs_username === 'true',
            stored: networkData.stored === 'true',
            channel:
                Number(networkData.channel) !== NaN
                    ? Number(networkData.channel)
                    : 0,
            frequency:
                Number(networkData.frequency) !== NaN
                    ? Number(networkData.frequency)
                    : 0,
            rate:
                Number(networkData.rate) !== NaN ? Number(networkData.rate) : 0,
            signal:
                Number(networkData.signal) !== NaN
                    ? Number(networkData.signal)
                    : 0,
            bars:
                Number(networkData.bars) !== NaN ? Number(networkData.bars) : 0,
        };
        return convertedNetworkData;
    }
}

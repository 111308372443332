import { Injectable } from '@angular/core';
import { LoggerService } from '../logger';
import { MetricService } from '../metrics';
// eslint-disable-next-line import/no-extraneous-dependencies
import find from 'lodash/find';
// eslint-disable-next-line import/no-extraneous-dependencies
import each from 'lodash/each';
import { IpcService } from '../ipc';
import { of, Subscription, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { FeccPosition } from '@enums';

@Injectable({ providedIn: 'root' })
export class PTZAxisService {
    private _host = 'https://localhost:1440';
    private _username = 'root';
    private _pasword = 'iEnU0RjI';
    private _cameraDetected = false;
    private _previousState = false;
    private _axisCameraPollingStatus: Subscription = null;

    constructor(
        private _loggerService: LoggerService,
        private _metricService: MetricService,
        private _ipcService: IpcService,
        private _httpClient: HttpClient
    ) {}

    init(): void {
        this._loggerService.info('ptzaxis:init', 'init');
        this.startPollingAxisCameraStatus();
    }
    getAxisCodec(url: string, params?: any) {
        let httpClient = null;
        if (!!params) {
            httpClient = this._httpClient.get(url, params);
        } else {
            httpClient = this._httpClient.get(url);
        }
        return httpClient.pipe(
            map((resp: any) => {
                if (!!resp?.exception) {
                    throw resp.exception;
                }
                return resp;
            })
        );
    }
    postAxisCodec(url: string, params?: any) {
        return this._httpClient.post(url, params).pipe(
            map((resp: any) => {
                if (!!resp?.exception) {
                    throw resp.exception;
                }
                return resp;
            })
        );
    }

    move(position) {
        const cmd = `${this._host}/axis-cgi/com/ptz.cgi?move=${position}&camera=1`;
        this.getAxisCodec(cmd).subscribe(
            () => {
                this._loggerService.info('ptzaxis:move', position);
            },
            (err) => {
                this._loggerService.info('ptzaxis:move', JSON.stringify(err));
            }
        );
        if (position === FeccPosition.Home) {
            this.getHomeZoom();
        }
    }

    movePosition(position) {
        const cmd = `${this._host}/axis-cgi/com/ptz.cgi?center=${position[1]},${position[2]}&imagewidth=${position[3]}&imageheight=${position[4]}&camera=1`;
        this.getAxisCodec(cmd).subscribe(
            () => {
                this._loggerService.info('ptzaxis:move', position);
            },
            (err) => {
                this._loggerService.info(
                    'ptzaxis:movePosition',
                    JSON.stringify(err)
                );
            }
        );
    }
    zoom(zoom) {
        const cmd = `${this._host}/axis-cgi/com/ptz.cgi?zoom=${zoom}&camera=1`;
        this.getAxisCodec(cmd).subscribe(
            () => {
                this._loggerService.info('ptzaxis:move', zoom);
            },
            (err) => {
                this._loggerService.info(
                    'ptzaxis:movePosition',
                    JSON.stringify(err)
                );
            }
        );
    }

    panTiltZoom(ptzValues: any): void {
        let path;
        // the string we get from fleet right now is - Need to Revisit
        // home: ptz,null,null,null,null,home
        // click: ptz,1069,892,1834,1031
        // zoom: ptz,undefined,undefined,8420

        if (ptzValues.length === 6) {
            this.move(FeccPosition.Home);
        } else if (ptzValues.length === 5) {
            path =
                '/axis-cgi/com/ptz.cgi?center=' +
                ptzValues[1] +
                ',' +
                ptzValues[2] +
                '&imagewidth=' +
                ptzValues[3] +
                '&imageheight=' +
                ptzValues[4] +
                '&camera=1';
        } else if (ptzValues.length === 4) {
            path = '/axis-cgi/com/ptz.cgi?zoom=' + ptzValues[3] + '&camera=1';
        }

        this.getAxisCodec(`${this._host}/${path}`)
            .pipe(
                catchError((err) => {
                    this._loggerService.log('ptzaxis:err', err);
                    return of([]);
                })
            )
            .subscribe((response) => {
                this._loggerService.info(
                    'ptzaxis:panTiltZoom response',
                    JSON.stringify(response)
                );
                return response;
            });
    }

    isCameraConnected(): void {
        this._loggerService.info(
            'ptzaxis:isCameraConnected',
            'isCameraConnected:call'
        );
        this.getAxisCodec(
            `${this._host}/axis-cgi/param.cgi?action=list&group=root.Brand.ProdShortName`
        ).subscribe(
            (response: any) => {
                const [, cameraType] = response
                    ? response.trim().split('=')
                    : [null, null];
                this._cameraDetected = true;
                this._metricService.enqueue({
                    camera_connected: +this._cameraDetected,
                    codec_ver: 'axis_big',
                    camera_type: cameraType,
                });
            },
            (error) => {
                this._cameraDetected = false;
                this._metricService.enqueue({
                    camera_connected: +this._cameraDetected,
                    codec_ver: null,
                });
                this.logCameraStatus({
                    message: error.message,
                    stack: error.stack,
                });
                this._loggerService.log(
                    'ptzaxis:isCameraConnected:catch error',
                    JSON.stringify(error.message)
                );
            }
        );
    }

    logCameraStatus(status): void {
        if (this._cameraDetected !== this._previousState) {
            this._previousState = this._cameraDetected;
        }
        this._loggerService.info(
            'ptzaxis:logCameraStatus',
            'Camera Detected: ' +
                this._cameraDetected +
                ' status: ' +
                JSON.stringify(status)
        );
    }

    setCameraHomePosition() {
        this.getAxisCodec(
            `${this._host}/axis-cgi/com/ptzconfig.cgi?setserverpresetname=Apgar&home=yes`
        ).subscribe(
            (response) => {
                this._loggerService.info(
                    'ptzaxis:setCameraHomePosition',
                    'Successfully set Camera Home Position'
                );
                return response;
            },
            (err) => {
                this._loggerService.log(
                    'ptzaxis:setCameraHomePosition:err',
                    JSON.stringify(err)
                );
            }
        );
    }

    stopPollingAxisCameraStatus(): void {
        if (this._axisCameraPollingStatus) {
            this._axisCameraPollingStatus.unsubscribe();
            this._axisCameraPollingStatus = null;
        }
    }

    startPollingAxisCameraStatus(): void {
        if (!this._axisCameraPollingStatus) {
            //TODO Change interval
            this.getUsers();
            this.getCameraFirmware();
            this.getCameraInfo();
            this._axisCameraPollingStatus = timer(0, 60 * 1000).subscribe(
                () => {
                    this.pingCamera().finally(() => {
                        this._metricService.enqueue({
                            camera_connected: +this._cameraDetected,
                            codec_ver: 'axis_big',
                        });
                    });
                }
            );
        }
    }

    pingCamera() {
        const path = `${this._host}/axis-cgi/pingtest.cgi?ip=192.168.0.2`;
        return this.getAxisCodec(path)
            .toPromise()
            .then(() => {
                this._cameraDetected = true;
            })
            .catch((error) => {
                this._cameraDetected = false;
                this._loggerService.log(
                    'ptzaxis:pingCamera:err',
                    JSON.stringify(error)
                );
            });
    }

    restart() {
        this.getAxisCodec(this._host + '/axis-cgi/restart.cgi').subscribe(
            () => {
                this._loggerService.info(
                    'ptzaxis:restart()',
                    'camera restarted'
                );
            },
            (error) => {
                this._loggerService.log('ptzaxis:restart()', error);
            }
        );
    }

    // Please Provide example of response, than we can write unit test for this
    /* istanbul ignore next */
    getUsers() {
        this.getAxisCodec(
            this._host + '/axis-cgi/admin/pwdgrp.cgi?action=get',
            {
                responseType: 'json',
            }
        ).subscribe(
            (response: any) => {
                if (response) {
                    const result = response.split('\r\n');
                    result.forEach((element) => {
                        if (element.indexOf('users') >= 0) {
                            const [key, value] = element.split('=');
                            const metrics = {};
                            metrics[key] = value;
                            this._metricService.enqueue(metrics);
                        }
                    });
                }
            },
            (error) => {
                this._loggerService.log(
                    'ptzaxis:getUsers()',
                    JSON.stringify(error)
                );
            }
        );
    }

    getCameraInfo() {
        this.postAxisCodec(this._host + '/axis-cgi/basicdeviceinfo.cgi', {
            apiVersion: '1.0',
            method: 'getAllProperties',
        }).subscribe(
            (response: any) => {
                const type =
                    (response &&
                        response.propertyList &&
                        response.propertyList.ProdShortName) ||
                    null;
                if (!!type) {
                    this._metricService.enqueue({
                        camera_type: type,
                    });
                }
            },
            (error) => {
                this._loggerService.log(
                    'ptzaxis:getCameraInfo()',
                    JSON.stringify(error)
                );
            }
        );
    }

    getCameraFirmware() {
        this.postAxisCodec(this._host + '/axis-cgi/firmwaremanagement.cgi', {
            apiVersion: '1.0',
            method: 'status',
        }).subscribe(
            (response: any) => {
                const firmWareObject = response;
                const metrics = {
                    camera_firmware_version:
                        firmWareObject['activeFirmwareVersion'],
                    camera_firmware_part: firmWareObject['activeFirmwarePart'],
                };
                this._metricService.enqueue(metrics);
                this._loggerService.log(
                    'ptzaxis:getCameraFirmware()',
                    JSON.stringify(response)
                );
            },
            (error) => {
                this._loggerService.log(
                    'ptzaxis:getCameraFirmware()',
                    JSON.stringify(error)
                );
            }
        );
    }

    createTeleHealthUser() {
        this.getAxisCodec(
            '/axis-cgi/admin/pwdgrp.cgi?action=add&user=telehealth&pwd=telehealth&grp=users&sgrp=operator:viewer:ptz'
        ).subscribe(
            (response) => {
                if (response) {
                    //do nothing;
                }
            },
            (error) => {
                this._loggerService.log(
                    'ptzaxis:createTeleHealthUser()',
                    JSON.stringify(error)
                );
            }
        );
    }

    deleteTeleHealthUser() {
        this.getAxisCodec(
            '/axis-cgi/admin/pwdgrp.cgi?action=remove&user=telehealth'
        ).subscribe(
            () => {
                //do nothing;
            },
            (error) => {
                this._loggerService.log(
                    'ptzaxis:createTeleHealthUser()',
                    JSON.stringify(error)
                );
            }
        );
    }
    // Get all preset home positions. Find the home position which is named Apgar
    // and extract the Zoom value and send it to Apgar web
    getHomeZoom(): void {
        this.getAxisCodec(
            `${this._host}/axis-cgi/param.cgi?action=list&group=root.PTZ.Preset.P0.Position`
        )
            .pipe(
                catchError((err) => {
                    this._loggerService.log('getHomeZoom:err', err);
                    return of('');
                })
            )
            .subscribe((response: any) => {
                // when we received from CatchError -> return of([]), then we will have here TypeError: response.split is not a function
                // so we change from return of([]) -> return of(''); -> positions = null
                const positions = response ? response.split('\n') : null;
                let zoomLevel;
                each(positions, (position) => {
                    if (position.indexOf('Name=Apgar') > -1) {
                        const substring = position.replace(
                            'Name=Apgar',
                            'Data'
                        );
                        let homePosition = find(positions, (p) => {
                            return p.indexOf(substring) > -1;
                        });
                        homePosition = homePosition.split(':');
                        const zoom = find(homePosition, (p) => {
                            return p.indexOf('zoom=') > -1;
                        });
                        [, zoomLevel] = zoom ? zoom.split('=') : ['zoom', 0];
                    }
                });

                if (zoomLevel) {
                    // zoom from camera ranges from 1-9999. send Zoom to Apgar Web in percentage
                    this._metricService.enqueue({
                        zoom_level: zoomLevel / 100,
                    });
                    this._ipcService.notify('zoom_changed', {
                        zoom_level: zoomLevel / 100,
                    });
                }
            });
    }
}

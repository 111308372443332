import { distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Directive, HostBinding, OnInit } from '@angular/core';
import { selectDeviceOnScreenKeyboardOpened } from '@/shared/storage/selectors/device-selectors';

@Directive({
    selector: '[coreGlobalKeyboardPadding]',
})
export class GlobalKeyboardPaddingDirective implements OnInit {
    constructor(private _store: Store) {}
    @HostBinding('class.global-keyboard-padding') globalKeyboardPaddingActive =
        false;
    ngOnInit(): void {
        this._store
            .select(selectDeviceOnScreenKeyboardOpened)
            .pipe(distinctUntilChanged())
            .subscribe((isKeyboardOpen: boolean) => {
                this.globalKeyboardPaddingActive = isKeyboardOpen;
            });
    }
}

import * as fromSelectors from '@/shared/storage/selectors';
import { Injectable } from '@angular/core';
import {
    BUILT_IN_DEVICES,
    FECC_DEVICES,
    KIND_OF_MEDIA_DEVICE,
    PERIPHERALS,
    StethoscopeC500,
} from '@constants';
import { EndpointTypes } from '@enums';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IDevice } from '@shared/interfaces';
import { IpcService, SessionService, StorageService } from '@shared/services';
import { selectRipleyBuiltInDevices } from '@storage/selectors';
import { switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import * as mediaDevicesActions from './media-devices.actions';

export const AUDIO_DEVICES = [
    {
        displayName: 'USB Microphone',
        label: '08bb:2912',
    },
];

@Injectable()
export class MediaDevicesEffects {
    ripleyBuiltInDevices = [];

    constructor(
        private _action$: Actions,
        private _store: Store,
        private _storageService: StorageService,
        private _ipcService: IpcService,
        private _sessionService: SessionService
    ) {
        this._store
            .select(selectRipleyBuiltInDevices)
            .pipe(take(1))
            .subscribe((ripleyBuiltInDevices) => {
                this.ripleyBuiltInDevices = ripleyBuiltInDevices;
            });

        this._ipcService.subscribeOnHeadphoneDetect().subscribe((event) => {
            this._store.dispatch(
                mediaDevicesActions.updateHeadPhoneDetect({
                    isConnected: event.isConnected,
                })
            );
        });
        this._ipcService.subscribeOnHeadsetMicDetect().subscribe((event) => {
            this._store.dispatch(
                mediaDevicesActions.updateHeadSetMicDetect({
                    isConnected: event.isConnected,
                })
            );
        });
        this._ipcService.subscribeOnLineInDetect().subscribe((event) => {
            this._store.dispatch(
                mediaDevicesActions.updateStethLineDetect({
                    isConnected: event.isConnected,
                })
            );
        });
    }

    updateMediaDevices = createEffect(
        () =>
            this._action$.pipe(
                ofType(mediaDevicesActions.updateMediaDevices),
                switchMap(() => navigator.mediaDevices.enumerateDevices()),
                withLatestFrom(
                    this._store.select(fromSelectors.selectMediaDevices),
                    this._store.select(fromSelectors.fSelectEndpoint)
                ),
                tap(([newMediaDevices, mediaDevices, endpoint]) => {
                    this.deviceInit(newMediaDevices, mediaDevices, endpoint);
                })
            ),
        { dispatch: false }
    );

    updateHeadPhoneDetect = createEffect(() =>
        this._action$.pipe(
            ofType(mediaDevicesActions.updateHeadPhoneDetect),
            switchMap((event) => {
                return [
                    mediaDevicesActions.setHeadPhoneDetect({
                        isConnected: event.isConnected,
                    }),
                    mediaDevicesActions.updateMediaDevices(),
                ];
            })
        )
    );

    updateHeadSetMicDetect = createEffect(() =>
        this._action$.pipe(
            ofType(mediaDevicesActions.updateHeadSetMicDetect),
            switchMap((event) => {
                return [
                    mediaDevicesActions.setHeadSetMicDetect({
                        isConnected: event.isConnected,
                    }),
                    mediaDevicesActions.updateMediaDevices(),
                ];
            })
        )
    );

    updateStethLineDetect = createEffect(() =>
        this._action$.pipe(
            ofType(mediaDevicesActions.updateStethLineDetect),
            switchMap((event) => {
                return [
                    mediaDevicesActions.setStethLineDetect({
                        isConnected: event.isConnected,
                    }),
                    mediaDevicesActions.updateMediaDevices(),
                ];
            })
        )
    );

    deviceInit(newMediaDevices, mediaDevices, endpoint) {
        if (endpoint.endpoint_type_id === EndpointTypes.C500) {
            this.amwell500DeviceInit(newMediaDevices, mediaDevices);
        } else if (endpoint.endpoint_type_id === EndpointTypes.ApgarCodec) {
            this.amwell250DeviceInit(newMediaDevices);
        } else {
            this.amwellDeviceInit(newMediaDevices);
        }
    }

    /* istanbul ignore next */
    amwell250DeviceInit(mediaDevices) {
        const devices = mediaDevices
            .map(
                (d) =>
                    ({
                        deviceId: d.deviceId,
                        groupId: d.groupId,
                        kind: d.kind,
                        label: d.label,
                    } as IDevice)
            )
            .filter((device) => device.deviceId !== 'default');

        devices.forEach((device, index) => {
            devices[index].name = device.label;
            devices[index].isPeripheral = PERIPHERALS.some((peripheral) =>
                device.label.includes(peripheral.getLabel())
            );
            FECC_DEVICES.forEach((fecc_device) => {
                if (device.label.indexOf(fecc_device.label) >= 0) {
                    devices[index].isFeccEnabled = true;
                    devices[index].name = fecc_device.displayName;
                    devices[index].productId = fecc_device.productId;
                    devices[index].vendorId = fecc_device.vendorId;
                }
            });
            BUILT_IN_DEVICES.forEach((built_in_device) => {
                if (
                    device.label.indexOf(built_in_device.driverName) >= 0 &&
                    device.kind === built_in_device.kind
                ) {
                    devices[index].name = built_in_device.displayName;
                    devices[index].vendorId = built_in_device.vendorId;
                    devices[index].productId = built_in_device.productId;
                    devices[index].allowGain = built_in_device.allowGain;
                    devices[index].defaultPlay = built_in_device.defaultPlay;
                }
            });
            AUDIO_DEVICES.forEach((audio_device) => {
                if (device.label.indexOf(audio_device.label) >= 0) {
                    devices[index].name = audio_device.displayName;
                    devices[index].allowGain = false;
                    // devices[index].allowGain = audio_device.allowGain;
                }
            });
        });

        this._storageService.notifyAudioVideoChanges();

        this._store.dispatch(
            mediaDevicesActions.setMediaDevices({
                devices,
            })
        );

        console.log('...device', devices);
    }

    /* istanbul ignore next */
    amwell500DeviceInit(newMediaDevices, mediaDeviceStore) {
        let devices = newMediaDevices
            .map(
                (d) =>
                    ({
                        deviceId: d.deviceId,
                        groupId: d.groupId,
                        kind: d.kind,
                        label: d.label,
                    } as IDevice)
            )
            .filter((device) => device.deviceId !== 'default');
        if (mediaDeviceStore.stethLineDetect) {
            devices = devices.filter((d) => {
                return !(
                    d.label.toLowerCase().indexOf('pcm2900c audio codec') >=
                        0 && d.kind === KIND_OF_MEDIA_DEVICE.AUDIO_OUTPUT
                ); // Ignore all Line-In input/output
            });
        } else {
            devices = devices.filter((d) => {
                return !(
                    d.label.toLowerCase().indexOf('pcm2900c audio codec') >= 0
                ); // Ignore all Line-In input/output
            });
        }

        if (!mediaDeviceStore.headPhoneDetect) {
            devices = devices.filter((d) => {
                return !(
                    d.label
                        .toLowerCase()
                        .indexOf('pcm2912a audio codec analog stereo') >= 0
                );
            });
        }

        if (!mediaDeviceStore.headSetMicDetect) {
            devices = devices.filter((d) => {
                return !(
                    d.label
                        .toLowerCase()
                        .indexOf('pcm2912a audio codec mono') >= 0
                );
            });
        }

        devices.forEach((device, index) => {
            devices[index].name = device.label;
            devices[index].isPeripheral = PERIPHERALS.some((peripheral) =>
                device.label.includes(peripheral.getLabel())
            );
            this.ripleyBuiltInDevices.forEach((builtInDevice) => {
                if (
                    device.label
                        .toLowerCase()
                        .indexOf(
                            builtInDevice.driverName.toLocaleLowerCase()
                        ) >= 0 &&
                    device.kind === builtInDevice.kind
                ) {
                    devices[index].name = builtInDevice.displayName;
                    devices[index].vendorId = builtInDevice.vendorId;
                    devices[index].productId = builtInDevice.productId;
                    devices[index].allowGain = builtInDevice.allowGain;
                    devices[index].defaultPlay = builtInDevice.defaultPlay;
                }
            });
            FECC_DEVICES.forEach((fecc_device) => {
                if (device.label.indexOf(fecc_device.label) >= 0) {
                    devices[index].isFeccEnabled = true;
                    devices[index].name = fecc_device.displayName;
                    devices[index].productId = fecc_device.productId;
                    devices[index].vendorId = fecc_device.vendorId;
                }
            });
            BUILT_IN_DEVICES.forEach((built_in_device) => {
                if (
                    device.label.indexOf(built_in_device.driverName) >= 0 &&
                    device.kind === built_in_device.kind
                ) {
                    devices[index].name = built_in_device.displayName;
                    devices[index].vendorId = built_in_device.vendorId;
                    devices[index].productId = built_in_device.productId;
                    devices[index].allowGain = built_in_device.allowGain;
                    devices[index].defaultPlay = built_in_device.defaultPlay;
                }
            });
        });

        const steth = PERIPHERALS.find((f) => f.getLabel() === 'PCM2900');
        if (steth) {
            steth.helpSteps[0] = StethoscopeC500.step0;
            steth.helpSteps[6] = StethoscopeC500.step6;
        }

        this._storageService.notifyAudioVideoChanges();

        this._store.dispatch(
            mediaDevicesActions.setMediaDevices({
                devices,
            })
        );

        console.log('...PERIPHERAL', PERIPHERALS);
        PERIPHERALS.map((peripheral) =>
            console.log(
                '......peripheral label',
                peripheral.title,
                peripheral.getLabel()
            )
        );
        console.log('...device', devices);
    }

    /* istanbul ignore next */
    amwellDeviceInit(newMediaDevices) {
        const devices = newMediaDevices
            .map(
                (d) =>
                    ({
                        deviceId: d.deviceId,
                        groupId: d.groupId,
                        kind: d.kind,
                        label: d.label,
                    } as IDevice)
            )
            .filter((device) => device.deviceId !== 'default');
        this._store.dispatch(
            mediaDevicesActions.setMediaDevices({
                devices,
            })
        );
    }
}

import { IVideoCallRoom } from '@/shared/interfaces/api/video-call-input.interface';
import { setPtzFeccPositionPrivacy } from '@/shared/storage';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AUDIO_INPUT_CONSTRAINT, VIDEO_INPUT_CONSTRAINT } from '@constants';
import { FeccPosition } from '@enums';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DeviceService } from '../device';
import { IntmMediaDevicesService } from '../intm-media-devices';
import { LogService } from '../log';
import { MetricService } from '../metrics';
import { PTZAxisService } from '../ptz/ptz-axis.service';
import { SessionService } from '../session';
import { VideoService } from '../video';

declare const PexRTC: any;

@Injectable({ providedIn: 'root' })
export class IntmVideoService {
    public room: IVideoCallRoom = null;
    public pexRTCCall: any;
    public participantName: string;
    public stream: MediaStream;
    public imageShare: boolean;
    public selfViewShown: boolean;
    public zoomSent: string;
    public presentation$ = new Subject<{
        status: boolean;
        stream?: MediaStream;
    }>();
    private _subscriptions: Subscription[] = [];

    constructor(
        private _mediaDevicesService: IntmMediaDevicesService,
        private _sessionService: SessionService,
        private _videoService: VideoService,
        private _router: Router,
        private _logService: LogService,
        private _deviceService: DeviceService,
        private _metricService: MetricService,
        private _store: Store,
        private _ptzAxisService: PTZAxisService
    ) {}

    /* istanbul ignore next */
    async startObserverCall(room: IVideoCallRoom) {
        this.zoomSent = null;
        let audioConstraint;
        let videoConstraint;

        const videoInput = this._mediaDevicesService.defaultVideoInput();
        const audioInput = this._mediaDevicesService.defaultAudioInput();

        if (audioInput && audioInput.deviceId) {
            audioConstraint = { ...AUDIO_INPUT_CONSTRAINT };
            audioConstraint.mandatory.sourceId = audioInput.deviceId;
        }

        if (videoInput && videoInput.deviceId) {
            videoConstraint = { ...VIDEO_INPUT_CONSTRAINT };
            videoConstraint.deviceId = videoInput.deviceId;
        }

        const subscription = this._videoService
            .setHomePosition()
            .pipe(
                catchError((err) => {
                    this._logService.logError(err);
                    return Observable.throw(err);
                })
            )
            .subscribe();
        this._subscriptions.push(subscription);

        const stream = await navigator.mediaDevices
            .getUserMedia({
                audio: audioConstraint || false,
                video: videoConstraint || false,
            })
            .catch((err) => {
                this.disconnect();
                this._logService.logError(err);
            });

        this.setRoom(room);

        this.pexRTCCall = new PexRTC({});

        this.pexRTCCall.turn_server = this.room.ice.iceServers[0];
        this.pexRTCCall.default_stun = this.room.ice.stunServers[0].urls;

        this.pexRTCCall.user_media_stream = stream;

        this.participantName = `endpoint:${
            this._sessionService.getEndpoint().endpoint_id
        }`;

        this.pexRTCCall.makeCall(
            this.room.conference_address,
            this.room.room_id,
            this.participantName,
            null,
            null,
            false
        );

        this._metricService.sendMetrics({
            call_state: '1',
        });

        this.pexRTCCall.onError = (err) => {
            this._logService.logError(err);
            this.disconnect();
        };

        this.pexRTCCall.onSetup = () => {
            this.pexRTCCall.connect(this.room.pin);
        };

        this.pexRTCCall.onConnect = (videoStream) => {
            this.stream = videoStream;
        };

        this.pexRTCCall.onDisconnect = (reason) => {
            const log = {
                type: 'trace',
                module: 'PEXIP_ONDISCONNECT_WEB',
                details: reason,
            };
            // this.ObservableService.callEnd();
            this._deviceService.sendLogs(log);
            this.disconnect();
        };

        this.pexRTCCall.onPresentation = (setting, presenter) => {
            if (setting && presenter.indexOf('siteuser') > -1) {
                this.pexRTCCall.getPresentation();
            } else {
                this.imageShare = false;
                this.selfViewShown = true;
            }
        };

        this.pexRTCCall.onPresentationConnected = (
            presentationStream: MediaStream
        ) => {
            this.presentation$.next({
                status: true,
                stream: presentationStream,
            });
        };

        this.pexRTCCall.onPresentationDisconnected = () => {
            this.presentation$.next({
                status: false,
            });
        };

        const pexRtcCall = this.pexRTCCall;
        Object.assign(pexRtcCall, {
            authUserHasJoined: false,
            // Returns the number of users on the call with authorized login credentials.
            getNumberOfAuthUsers: () => {
                return pexRtcCall
                    .getRosterList()
                    .filter((user) => user.display_name.startsWith('siteuser:'))
                    .length;
            },
            // Called when any participant (including self) joins call.
            onParticipantCreate: () => {
                pexRtcCall.authUserHasJoined =
                    pexRtcCall.authUserHasJoined ||
                    pexRtcCall.getNumberOfAuthUsers() > 0;
            },
            // Called when any participant (NOT including self) leaves the call.
            // Disconnects everyone from the call if the the last autheticated user has left
            //      or if this is the only device left in the call.
            onParticipantDelete: () => {
                return pexRtcCall
                    .getApgarAutoHangupSetting()
                    .subscribe((autoHangup) => {
                        if (autoHangup.value) {
                            const users = pexRtcCall.getRosterList().length;
                            const authUsers = pexRtcCall.getNumberOfAuthUsers();

                            if (
                                (pexRtcCall.authUserHasJoined &&
                                    authUsers === 0) ||
                                users === 1
                            ) {
                                pexRtcCall.disconnectAll();
                            }
                        }
                    });
            },
            getApgarAutoHangupSetting: () =>
                this._videoService.getApgarAutoHangupSetting(),
        });
    }

    getVideoStream() {
        return this.stream;
    }

    sendMessage(message: string) {
        if (message.indexOf('ZOOM_CHANGED') > -1) {
            this.zoomSent = 'complete';
        }
        return this.pexRTCCall.sendChatMessage(message);
    }

    disconnect() {
        this._deviceService.disconnectDevice();
        this._store.dispatch(setPtzFeccPositionPrivacy());
        this._ptzAxisService.move(FeccPosition.Privacy);
        if (this.pexRTCCall) {
            this.pexRTCCall.clearLocalStream();
            if (this.pexRTCCall.user_media_stream) {
                const tracks =
                    this.pexRTCCall.user_media_stream.getTracks() as any[];
                tracks.forEach((t) => t.stop());
            }
        }
        this.removeRoom();
        this._metricService.sendMetrics({
            call_state: '0',
        });
        this._router.navigateByUrl('/intmhomescreen');
    }

    setRoom(room) {
        this.room = room;
    }

    getRoom() {
        return this.room;
    }

    removeRoom() {
        this.room = null;
    }
}

import { createSelector } from '@ngrx/store';

import { IEndpointSettings } from '@shared/interfaces';
// import { selectAuthData } from '../auth';

import { fSelectEndpoint } from './universal-selectors';

export const selectEndpoint = createSelector(
    fSelectEndpoint,
    (endpoint: IEndpointSettings) => endpoint
);

export const selectEndpointName = createSelector(
    fSelectEndpoint,
    (endpoint: IEndpointSettings): string | null => endpoint?.name
);

export const selectEndpointPhotoUrl = createSelector(
    fSelectEndpoint,
    (endpoint: IEndpointSettings): string | null => endpoint?.endpoint_photo_url
);

export const selectEndpointByKey = createSelector(
    fSelectEndpoint,
    (endpoint: IEndpointSettings, props: any) => {
        const { key } = props;
        return endpoint && props && key && endpoint[key] ? endpoint[key] : null;
    }
);

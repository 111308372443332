import { UpdateTypeEnum } from '@/shared/enums';
import { selectUpdateType } from '@/shared/storage/selectors';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { IIpcResponseHandler } from '../../interfaces';
import { IpcService } from './ipc.service';
import { MetricService } from '../metrics';
import { timer } from 'rxjs';
import { SUB_SYSTEM_TYPES } from '@/shared/constants/system-command';

@Injectable({ providedIn: 'root' })
export class UpdaterResponseService implements IIpcResponseHandler {
    constructor(
        private _ipcService: IpcService,
        private _store: Store,
        private _metricService: MetricService
    ) {}

    handleResponse(response) {
        const { updater: updaterResponse } = response;
        this.handleDownloadResponse(updaterResponse);
    }

    handleDownloadResponse(updaterResponse) {
        const { state, subsystem, percentage } = updaterResponse;
        switch (state) {
            case 'COMPLETE':
                this.sendUpdateStatus(subsystem, state);
                if (subsystem == SUB_SYSTEM_TYPES.APPLICATION) {
                    timer(10000).subscribe(() => {
                        this._ipcService.restartApp();
                    });
                } else {
                    this._store
                        .select(selectUpdateType)
                        .pipe(first())
                        .subscribe((updateType) => {
                            console.log('..download update is complete');
                            if (updateType === UpdateTypeEnum.advancedAuto) {
                                timer(10000).subscribe(() => {
                                    this._ipcService.restartDevice();
                                });
                            }
                        });
                }
                break;
            case 'DOWNLOADING':
                const status = 'Downloading - ' + percentage + '%';
                this.sendUpdateStatus(subsystem, status);
                break;
            default:
                this.sendUpdateStatus(subsystem, state);
                break;
        }
    }

    sendUpdateStatus(subsystem, status) {
        if (subsystem === 'APPLICATION') {
            this._metricService.enqueue({
                application_update_status: status,
            });
        } else {
            this._metricService.enqueue({ os_update_status: status });
        }
    }
}

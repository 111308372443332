// Subsystems type
export const SUB_SYSTEM_TYPES = {
    APPLICATION_MANAGER: 'APPLICATION_MANAGER',
    APPLICATION: 'APPLICATION',
    AUDIO_MANAGER: 'AUDIO_MANAGER',
    CRADLE_COMMS: 'CRADLE_COMMS',
    EMBRAVA_LIGHT: 'EMBRAVA_LIGHT',
    FILE_MANAGER: 'FILE_MANAGER',
    NETWORK_MANAGER: 'NETWORK_MANAGER',
    OPERATING_SYSTEM: 'OPERATING_SYSTEM',
    POWER_MANAGER: 'POWER_MANAGER',
    SPINE_MANAGER: 'SPINE_MANAGER',
    SYSTEM_INFO_MANAGER: 'SYSTEM_INFO_MANAGER',
    TV_CONTROL: 'TV_CONTROL',
    USB_MANAGER: 'USB_MANAGER',
    CAMERA_MANAGER: 'CAMERA_MANAGER',
} as const;

// System Commands -
export const SYSTEM_COMMANDS_TYPES = {
    ADD_DOMAIN_TO_DNS: 'ADD_DOMAIN_TO_DNS',
    ADD_SERVER_ARRAY_TO_DNS: 'ADD_SERVER_ARRAY_TO_DNS',
    ADD_SERVER_TO_DNS: 'ADD_SERVER_TO_DNS',
    AUDIO_CHANGE_CONTROL_ECHO_CANCEL: 'AUDIO_CHANGE_CONTROL_ECHO_CANCEL',
    AUDIO_CHANGE_CONTROL_VOLUME: 'AUDIO_CHANGE_CONTROL_VOLUME',
    AUDIO_CHANGE_CONTROL_MAX_VOLUME: 'AUDIO_CHANGE_CONTROL_MAX_VOLUME',
    AUDIO_CONNECTIONS_DATA: 'AUDIO_CONNECTIONS_DATA',
    AUDIO_GET_CONTROLS: 'AUDIO_GET_CONTROLS',
    AUDIO_MUTE_CONTROL: 'AUDIO_MUTE_CONTROL',
    AUDIO_VIDEO_CHANGE: 'AUDIO_VIDEO_CHANGE',
    CALL_END: 'CALL_END',
    CHANGE_AUDIO_GAIN: 'CHANGE_AUDIO_GAIN',
    CHANGE_MODE: 'CHANGE_MODE',
    CONFIG: 'CONFIG',
    SYSTEM: 'SYSTEM',
    ROLLBACK: 'ROLLBACK',
    CONNECT: 'CONNECT',
    CONNECT_WIFI: 'CONNECT_WIFI',
    CANCEL_CASE: 'CANCEL_CASE',
    DELETE_ALL_NETWORKS: 'DELETE_ALL_NETWORKS',
    DELETE_NETWORK: 'DELETE_NETWORK',
    DISPLAY: 'DISPLAY',
    DNS_UPDATE: 'DNS_UPDATE',
    EVENT: 'EVENT',
    FACTORY_DEFAULT: 'FACTORY_DEFAULT',
    GET_DNS_SERVERS: 'GET_DNS_SERVERS',
    GET_HOSTNAME: 'GET_HOSTNAME',
    GET_LIST: 'GET_LIST',
    GET_LOCKED_BAND: 'GET_LOCKED_BAND',
    GET_NETWORK_CONFIG: 'GET_NETWORK_CONFIG',
    GET_NETWORK_STATUS: 'GET_NETWORK_STATUS',
    GET_POWER_STATS: 'GET_POWER_STATS',
    GET_SERIAL_NUMBER: 'GET_SERIAL_NUMBER',
    GET_FW_VERSION: 'GET_FW_VERSION',
    GET_STATUS: 'GET_STATUS',
    GET_SYSTEM_INFO: 'GET_SYSTEM_INFO',
    HEADPHONES_DETECT: 'HEADPHONES_DETECT',
    HEADSET_MIC_DETECT: 'HEADSET_MIC_DETECT',
    HOTPLUG: 'HOTPLUG',
    LINE_IN_DETECT: 'LINE_IN_DETECT',
    MAKE_CALL: 'MAKE_CALL',
    MAKE_CALL_INTERACTIVE: 'MAKE_CALL_INTERACTIVE',
    MAKE_CALL_OBSERVER: 'MAKE_CALL_OBSERVER',
    NERVE: 'NERVE',
    NERVE_GET_DETECTED: 'NERVE_GET_DETECTED',
    PERSON_DETECTED: 'PERSON_DETECTED',
    POLL_AUDIO_JACK_STATUS: 'POLL_AUDIO_JACK_STATUS',
    POWER_BUTTON: 'POWER_BUTTON',
    POWER_STATUS: 'POWER_STATUS',
    REBOOT: 'REBOOT',
    REENUMERATE_DEVICE: 'REENUMERATE_DEVICE',
    RESOLVE_DOMAIN: 'RESOLVE_DOMAIN',
    RESOLVE_DEFAULT_DOMAIN: 'RESOLVE_DEFAULT_DOMAIN',
    RESTART: 'RESTART',
    RETURN_TO_PREVIOUS_SOURCE: 'RETURN_TO_PREVIOUS_SOURCE',
    RING_ANIMATION_LOW_BATTERY: 'RING_ANIMATION_LOW_BATTERY',
    RING_ANIMATION_NET_ERROR: 'RING_ANIMATION_NET_ERROR',
    RING_ANIMATION_REG_ERROR: 'RING_ANIMATION_REG_ERROR',
    RING_ANIMATION_STANDBY: 'RING_ANIMATION_STANDBY',
    RING_OFF: 'RING_OFF',
    RING_ON: 'RING_ON',
    RIPPLE_GET_AC_BATT_INFO: 'RIPPLE_GET_AC_BATT_INFO',
    SET_HOSTNAME: 'SET_HOSTNAME',
    SET_IP_CONFIG: 'SET_IP_CONFIG',
    SET_LOCKED_BAND: 'SET_LOCKED_BAND',
    SET_PRIMARY_SOURCE: 'SET_PRIMARY_SOURCE',
    SET_ROAMING_THRESHOLD: 'SET_ROAMING_THRESHOLD',
    GET_ROAMING_THRESHOLD: 'GET_ROAMING_THRESHOLD',
    SHUTDOWN: 'SHUTDOWN',
    STANDBY_STATE: 'STANDBY_STATE',
    SWITCH_TO_PRIMARY_SOURCE: 'SWITCH_TO_PRIMARY_SOURCE',
    TURN_OFF: 'TURN_OFF',
    TURN_ON: 'TURN_ON',
    TV_READY: 'TV_READY',
    UNLOCK_SCREEN: 'UNLOCK_SCREEN',
    UNLOCK_SETTINGS: 'UNLOCK_SETTINGS',
    UPDATE: 'UPDATE',
    WORKSURFACE_AUTO: 'WORKSURFACE_AUTO',
    WORKSURFACE_OFF: 'WORKSURFACE_OFF',
    WORKSURFACE_ON: 'WORKSURFACE_ON',
    READ_FILE: 'READ_FILE',
    WRITE_FILE: 'WRITE_FILE',
    REMOVE_FILE: 'REMOVE_FILE',
    LIST_FILES: 'LIST_FILES',
    GET_CAMERA_LIST: 'GET_CAMERA_LIST',
    GET_CAMERA_CAPABILITIES: 'GET_CAMERA_CAPABILITIES',
    GET_CAMERA_CURRENT_POSITION: 'GET_CAMERA_CURRENT_POSITION',
    GET_CAMERA_PARAM: 'GET_CAMERA_PARAM',
    SET_CAMERA_PARAM: 'SET_CAMERA_PARAM',
    CAMERA_PTZ: 'CAMERA_PTZ',
    AUDIO_SET_DEFAULT_SOURCE: 'AUDIO_SET_DEFAULT_SOURCE',
    AUDIO_SET_DEFAULT_SINK: 'AUDIO_SET_DEFAULT_SINK',
} as const;

export const IPC_RESPONSE = 'IPC_RESPONSE';
export const IPC_REQUEST = 'IPC_REQUEST';
export const DEVICE_STATUS = 'DEVICE_STATUS';
export const DEVICE_STATUS_ONLINE = 'Device is online';
export const DEVICE_STATUS_OFFLINE = 'Device is offline';

export const PTZ_COMMANDS = {
    ZOOM: 'zoom',
    HOME: 'home',
    LEFT: 'left',
    RIGHT: 'right',
    UP: 'up',
    DOWN: 'down',
    OPERATION: {
        RELATIVE_ZOOM: 'relativeZoom',
        PAN_TILT_COMMAND: 'panTiltCommand',
    },
} as const;

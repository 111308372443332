export const DEFINED_OPTIONAL_VALUE = 0;
export const DEFINED_TRACK_EVENT_NAME = 'trackEvent';
export const DEFINED_TRACKING_CODE = 1243;

export enum TrackEventDataKeys {
    // General Settings Events
    HeaderMenuViewSettings = 'HeaderMenuViewSettings',
    ViewLegalInformation = 'ViewLegalInformation',
    ViewAdvancedSettings = 'ViewAdvancedSettings',
    ViewRollbackDialog = 'ViewRollbackDialog',
    DialogErrorRollback = 'DialogErrorRollback',
    DialogOpenRollback = 'DialogOpenRollback',
    RequestFactoryDefaultAtSettings = 'RequestFactoryDefaultAtSettings',

    // First Time Setup
    InitFactoryDefault = 'InitFactoryDefault',
    CancelFactoryDefault = 'CancelFactoryDefault',
    InitRestartDevice = 'InitRestartDevice',
    CancelRestartDevice = 'CancelRestartDevice',
    InitTurnOffDevice = 'InitTurnOffDevice',
    CancelTurnOffDevice = 'CancelTurnOffDevice',

    // Case Management
    CreateCase = 'CreateCase',
    CancelCase = 'CancelCase',
    DiscardCase = 'DiscardCase',
    InitiateCancelCase = 'InitiateCancelCase',
}

export const FIRST_TIME_EVENT_DATA = new Map<string, string[]>([
    [
        TrackEventDataKeys.InitFactoryDefault,
        [
            'First_Time_Setup',
            'factoryDefault',
            'factoryDefault via FactoryDefaultDialog',
        ],
    ],
    [
        TrackEventDataKeys.CancelFactoryDefault,
        [
            'First_Time_Setup',
            'Cancel FactoryDefault',
            'Cancel via FactoryDefaultDialog',
        ],
    ],

    [
        TrackEventDataKeys.InitRestartDevice,
        [
            'First_Time_Setup',
            'Restart device',
            'Restart device via button Restart_Restart modal dialog',
        ],
    ],
    [
        TrackEventDataKeys.CancelRestartDevice,
        [
            'First_Time_Setup',
            'Cancel restart device',
            'Cancel restart device via button_Restart modal dialog',
        ],
    ],

    [
        TrackEventDataKeys.InitTurnOffDevice,
        [
            'First_Time_Setup',
            'Turn off device',
            'Turn off device via button Turn off_Turn off modal dialog',
        ],
    ],
    [
        TrackEventDataKeys.CancelTurnOffDevice,
        [
            'First_Time_Setup',
            'Cancel turn off device',
            'Cancel turn off device via button_Turn off modal dialog',
        ],
    ],
]);

export const GENEAL_SETTINGS_EVENT_DATA = new Map<string, string[]>([
    [
        TrackEventDataKeys.HeaderMenuViewSettings,
        [
            'Settings_General',
            'View Settings',
            'View Settings via header menu item Settings',
        ],
    ],
    [
        TrackEventDataKeys.RequestFactoryDefaultAtSettings,
        [
            'Settings_General',
            'Back to Factory default',
            'Back to Factory default via button Factory default_Settings modal dialog',
        ],
    ],
    [
        TrackEventDataKeys.ViewLegalInformation,
        [
            'Settings_General',
            'View Legal information',
            'View Legal information via icon Arrow_Settings modal dialog',
        ],
    ],
    [
        TrackEventDataKeys.ViewAdvancedSettings,
        [
            'Settings_General',
            'View Advanced settings',
            'View Advanced settings via icon Arrow_Settings modal dialog',
        ],
    ],
    [
        TrackEventDataKeys.ViewRollbackDialog,
        [
            'Settings_General',
            'View Rollback Dialog',
            'View Rollback Dialog via icon Arrow_Settings modal dialog',
        ],
    ],
    [
        TrackEventDataKeys.DialogErrorRollback,
        [
            'Settings_General',
            'Rollback error dialog',
            'Failed to apply new settings.',
        ],
    ],
    [
        TrackEventDataKeys.DialogOpenRollback,
        [
            'Settings_General',
            'Rollback Os/App Versions',
            'Rollback software via button Rollback software_Advanced settings modal dialog',
        ],
    ],
]);

export const CASE_MANAGEMENT_EVENT_DATA = new Map<string, string[]>([
    [
        TrackEventDataKeys.CreateCase,
        [
            'Case Management_Case Creation',
            'Create case',
            'Create case via button Next_Intake page',
        ],
    ],
    [
        TrackEventDataKeys.CancelCase,
        [
            'Case Management_Case Creation',
            'Cancel case',
            'Cancel case via button Cancel case_Cancel case modal dialog_Intake page',
        ],
    ],
    [
        TrackEventDataKeys.DiscardCase,
        [
            'Case Management_Case Creation',
            'Discard case cancelation',
            'Discard case cancelation via button Continue working_Cancel case modal dialog_Intake page',
        ],
    ],
    [
        TrackEventDataKeys.InitiateCancelCase,
        [
            'Case Management_Case Creation',
            'Initiate cancel case',
            'Initiate cancel case via button Cancel_Intake page',
        ],
    ],
]);

export const TRACK_EVENT_MAP_DATA = new Map<string, string[]>([
    ...FIRST_TIME_EVENT_DATA,
    ...GENEAL_SETTINGS_EVENT_DATA,
    ...CASE_MANAGEMENT_EVENT_DATA,
]);

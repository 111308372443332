import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { INetwork, INetworkListResponse } from '@shared/interfaces';
import { IpcService } from '@services/ipc';
import { StatisticCollector } from '@services/statistic-collector/statistic-collector.service';
import { ConfirmModalComponent } from '@core/components';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    first,
    take,
} from 'rxjs/operators';
import { Observable, of, Subject, Subscription, timer } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { LoggerService } from '@services/logger';
import {
    ENCRYPTION_OPTIONS,
    ENCRYPTION_TYPES,
} from '@constants/encryption-types';
import { SnackbarService } from '@aw-hospital/aw-components-lib/src/services/snackbar';
// eslint-disable-next-line import/no-extraneous-dependencies
import cloneDeep from 'lodash/cloneDeep';
// eslint-disable-next-line import/no-extraneous-dependencies
import isEqual from 'lodash/isEqual';
import { AddressProtocolType, NetworkTypes, StatusTypes } from '@/shared/enums';
import {
    BAND_CONFIGURATION,
    CONNECTION_ERRORS,
    defaultNetworkItem,
} from '@/shared/constants';
import { getAnotherNetworkForm, getWifiConfigForm } from './wifi.utils';
import { snackConfig } from '@/shared/constants/snack-bar';
import { getConnectionMethods } from '@/shared/constants/connection';

@Component({
    selector: 'app-wifi',
    templateUrl: './wifi.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WifiComponent implements OnInit, OnDestroy {
    public addressProtocolTypeStatic = AddressProtocolType.STATIC;
    public encryption = '';
    public hiddenSSID = '';
    public addDNS = false;
    public joinWifiStatus = '';
    public connectionMethods = getConnectionMethods;

    public defaultStaticIpSetting: any = {};
    public joinStaticIPStatus = false;
    public bandConfiguration = [];

    private _isDefaultDnsChanged = false;
    private _isOnlineSub: Subscription;
    private _staticIpSetting: any = {};

    /** all before newly added */
    private _subscriptions: Subscription[] = [];
    private _staticIpSettingForm: FormGroup;

    public selectedWifi: INetwork = defaultNetworkItem;
    public selectedWifi$: Subject<INetwork> = new Subject();
    public showNetworkText = false;
    public refreshInProgress = false;
    public wifiConfigForm: FormGroup = getWifiConfigForm;
    public anotherNetworkForm: FormGroup = getAnotherNetworkForm;
    public disableBand = false;
    public requireUsername = false;
    public requirePassword = false;
    public lockedBand = '';
    public encryptionTypes = ENCRYPTION_TYPES;
    public encryptionOptions = ENCRYPTION_OPTIONS;
    public isChanged: boolean;
    public isValidToJoinWifi: boolean;
    public isShowKeyboard = false;
    public showPassword = false;

    @Input() externalJoinStaticIPStatus: boolean;
    @Input() wifiAdresess: string;
    @Input() networkManager: INetworkListResponse;
    @Input() joinAnotherNetworkClicked: boolean;

    @Input() set wifi(value: INetwork) {
        if (value) {
            this.selectedWifi = value;
            this.selectedWifi$.next(value);
            this.resetForms(false, true);
        }
        this.refreshInProgress = false;
    }

    @Output() selectWifi: EventEmitter<INetwork> = new EventEmitter<INetwork>();

    constructor(
        public dialogRef: MatDialogRef<ConfirmModalComponent>,
        private _ipcService: IpcService,
        private _statisticCollector: StatisticCollector,
        private _matDialog: MatDialog,
        private _snackbarService: SnackbarService,
        private _loggerService: LoggerService,
        private _cdr: ChangeDetectorRef
    ) {
        this._snackbarService.maxVisible = 1;
    }

    ngOnInit(): void {
        this.refreshNetwork();
        this._subscriptions.push(
            this.selectedWifi$.pipe(first()).subscribe((network: INetwork) => {
                this.getNetworkConfig(network.ssid);
                this.getLockedBand(network.ssid);
                this.setAvailableBand(network);
            })
        );
        this.wifiConfigFormListener();
    }

    ngOnDestroy(): void {
        this._isOnlineSub?.unsubscribe();
        this._subscriptions.forEach(
            (subscription: Subscription) =>
                subscription && subscription.unsubscribe()
        );
    }

    private wifiConfigFormListener(): void {
        this._subscriptions.push(
            this.wifiConfigForm.valueChanges
                .pipe(
                    debounceTime(100),
                    distinctUntilChanged((x: FormGroup, y: FormGroup) =>
                        isEqual(y, x)
                    )
                )
                .subscribe(() => {
                    this.isValidToJoinWifi = this.validateToJoinWifi();
                    this._cdr.markForCheck();
                })
        );
    }

    private getNetworkConfig(networkSSID: string): void {
        this._ipcService
            .requestGetNetworkConfig(networkSSID)
            .pipe(first(), catchError(this.handleError))
            .subscribe((response) => {
                const { ipv4 = {} } = response || {};
                this.defaultStaticIpSetting = ipv4;
                const { address_protocol: addressProtocol } =
                    this.defaultStaticIpSetting;
                this.wifiConfigForm
                    .get('connectionMethod')
                    .patchValue(addressProtocol);
                this._cdr.markForCheck();
            });
    }

    private getLockedBand(networkSSID: string): void {
        this._ipcService
            .requestGetLockedBand(networkSSID)
            .pipe(first(), catchError(this.handleError))
            .subscribe((response) => {
                const { locked_band: lockedBand = 'BAND_AUTO' } =
                    response || {};
                this.lockedBand = lockedBand;
                this._cdr.detectChanges();
            });
    }

    public onChangeConnectionMethod(): void {
        this.joinStaticIPStatus =
            this.wifiConfigForm.controls['connectionMethod'].value ===
            AddressProtocolType.STATIC;

        this.isChanged = this.changeDetected();
        this.isValidToJoinWifi = this.validateToJoinWifi();
    }

    public refreshNetwork(): void {
        this.refreshInProgress = true;
        this._ipcService.requestNetworkList();

        this._statisticCollector.saveEvent(
            'No Network',
            'Refresh network list',
            'Refresh network list via button Refresh list'
        );
    }

    onItemClick(item: INetwork): void {
        this.selectedWifi = cloneDeep(item);
        this.joinAnotherNetworkClicked = false;

        !!this._staticIpSettingForm && this._staticIpSettingForm.reset();
        this.resetForms();
        this.getNetworkConfig(this.selectedWifi.ssid);
        this.getLockedBand(this.selectedWifi.ssid);
        this.setAvailableBand(this.selectedWifi);
        this.lockedBand = this.selectedWifi?.locked_band || 'BAND_AUTO';
        this.joinWifiStatus = '';
        this.showNetworkText =
            (this.selectedWifi.needs_username ||
                this.selectedWifi.needs_password) &&
            !this.selectedWifi.stored &&
            !this.selectedWifi.active;
        this.isChanged = false;
        this.selectWifi.emit(this.selectedWifi);
    }

    public setAvailableBand(selectedWifi: INetwork): void {
        // If available_band is not available from os or is 'BAND_AUTO', show all options.
        if (
            !selectedWifi ||
            !selectedWifi.available_band ||
            selectedWifi.available_band === 'BAND_AUTO'
        ) {
            this.bandConfiguration = BAND_CONFIGURATION;
        } else {
            this.bandConfiguration = [];
        }
    }

    public joinAnotherNetwork(): void {
        this.joinAnotherNetworkClicked = true;
        this.anotherNetworkForm.get('encryption').patchValue('None');
        this.anotherNetworkForm.get('hiddenSSID').patchValue('');
        this.selectedWifi = {} as INetwork;
        this.selectWifi.emit(this.selectedWifi);

        this._statisticCollector.saveEvent(
            'No Network',
            'Initiate join another network',
            'Initiate join another network via button Join another network'
        );
    }

    public onOpenForgotPopup(): void {
        this.dialogRef = this._matDialog.open(ConfirmModalComponent, {
            panelClass: ['aw-modal', 'modal-md'],
            data: {
                title: 'Forget Network',
                confirmText: 'Forget Network',
                body: `Are you sure you want to forget the network <b>${this.selectedWifi.ssid}</b>.
                    It will still appear in your list so you can add it again later if you choose to do so.`,
            },
        });
        this._subscriptions.push(
            this.dialogRef
                .afterClosed()
                .pipe(catchError(this.handleError))
                .subscribe((confirm: boolean) => {
                    if (confirm) {
                        this._ipcService
                            .requestDeleteNetwork(this.selectedWifi.ssid)
                            .pipe(take(1))
                            .pipe(catchError(this.handleError))
                            .subscribe((response) => {
                                const { success = '' } = response;
                                if (success) {
                                    this._snackbarService.show({
                                        description:
                                            'Network was successfully deleted!',
                                        style: 'success',
                                    });
                                    this._ipcService.requestNetworkList();
                                } else {
                                    this.joinWifiStatus = '';
                                    this._snackbarService.show({
                                        description:
                                            CONNECTION_ERRORS.UNKNOWN_ERROR,
                                        style: 'danger',
                                    });
                                }
                            });
                    }
                })
        );
    }

    public setWifiBand(lockedBand: string): void {
        this.lockedBand = lockedBand;
        if (this.selectedWifi.active) {
            this.disableBand = true;
            this.joinWifiStatus = 'Changing Wifi Band...';
            const input = {
                ssid: this.selectedWifi.ssid,
                lockedBand: this.lockedBand,
            };
            this._ipcService
                .requestSetLockedBand(input)
                .pipe(first())
                .subscribe((res) => {
                    this.disableBand = false;
                    const { error = '' } = res;
                    if (!error) {
                        timer(5000).subscribe(() => {
                            this.joinWifiStatus = 'Changed Wifi Band';
                        });
                    } else {
                        this.showSnackbar(
                            CONNECTION_ERRORS.SET_LOCKED_BAND_FAILED
                        );
                    }
                });
        }
    }

    public resetForms(
        resetConnectionMethod = true,
        isChangedNetwork = false
    ): void {
        const username = this.wifiConfigForm.controls.username.value;
        const password = this.wifiConfigForm.controls.password.value;

        this.wifiConfigForm.get('username').patchValue('');
        this.wifiConfigForm.get('password').patchValue('');

        resetConnectionMethod &&
            this.wifiConfigForm.get('connectionMethod').reset();

        if (!isChangedNetwork) {
            this.anotherNetworkForm.get('username').patchValue('');
            this.anotherNetworkForm.get('password').patchValue('');
        }

        if (this.selectedWifi) {
            const { stored, needs_username, needs_password } =
                this.selectedWifi;
            if (stored) {
                this.wifiConfigForm.controls['username'].disable();
                this.wifiConfigForm.controls['password'].disable();
            } else {
                if (needs_username) {
                    this.wifiConfigForm.controls['username'].enable();
                    isChangedNetwork &&
                        this.wifiConfigForm.controls['username'].patchValue(
                            username
                        );
                }
                if (needs_password) {
                    this.wifiConfigForm.controls['password'].enable();
                    isChangedNetwork &&
                        this.wifiConfigForm.controls['password'].patchValue(
                            password
                        );
                }
            }
        }
    }

    public validateForm(hidden: boolean): boolean {
        if (this.selectedWifi?.stored) {
            this.wifiConfigForm.controls['username'].disable();
            this.wifiConfigForm.controls['password'].disable();
        }

        if (hidden) {
            return (
                (this.requireUsername &&
                    this.anotherNetworkForm.value.username !== '') ||
                (this.requirePassword &&
                    this.anotherNetworkForm.value.password !== '')
            );
        } else {
            return this.selectedWifi.stored
                ? true
                : (!this.selectedWifi.needs_password
                      ? true
                      : this.wifiConfigForm.value.password !== '') &&
                      (!this.selectedWifi.needs_username
                          ? true
                          : this.wifiConfigForm.value.username !== '');
        }
    }

    public validateToJoinWifi(): boolean {
        let isValidToJoin =
            this.validateForm(false) && this.wifiConfigForm?.valid;
        if (
            this.wifiConfigForm?.value.connectionMethod ===
            AddressProtocolType.STATIC
        ) {
            isValidToJoin = isValidToJoin && this._staticIpSettingForm?.valid;
        }
        return isValidToJoin;
    }

    public onChangeEncryption(item): void {
        const encryption = this.encryptionTypes[item];
        this.requireUsername = encryption.requireUsername;
        this.requirePassword = encryption.requirePassword;
        this.resetForms();
        this.lockedBand = 'BAND_AUTO';

        this._statisticCollector.saveEvent(
            'No Network',
            'Choose encryption',
            `Choose encryption via dropdown menu item ${item.requireUsername}`
        );
    }

    public changeDetected(): boolean {
        return (
            this.staticIpConfigChangeDetected(this.selectedWifi.ssid || '') ||
            this._isDefaultDnsChanged
        );
    }

    public onStaticIpSettingChange($event: FormGroup) {
        const { address, gateway, prefix } = $event.value;
        this._staticIpSetting.address = address;
        this._staticIpSetting.gateway = gateway;
        this._staticIpSetting.prefix = prefix;
        this._staticIpSettingForm = $event;
        this.isChanged = this.changeDetected();
        this.isValidToJoinWifi = this.validateToJoinWifi();
    }

    public staticIpConfigChangeDetected(networkSSID: string): boolean {
        const {
            address = '',
            prefix = '',
            gateway = '',
            address_protocol = AddressProtocolType.DHCP,
        } = this.defaultStaticIpSetting;
        const {
            address: newAddress = '',
            prefix: newPrefix = '',
            gateway: newGateway = '',
        } = this._staticIpSetting || {};

        const connectionMethod =
            this.wifiConfigForm?.value.connectionMethod ||
            AddressProtocolType.DHCP;

        const isStaticIpConfigChangeDetected = !(
            address_protocol === connectionMethod &&
            address === newAddress &&
            prefix === newPrefix &&
            gateway === newGateway &&
            this.selectedWifi?.ssid === networkSSID
        );
        return isStaticIpConfigChangeDetected;
    }

    /** change */

    public isDnsChanged($event: boolean): void {
        this._isDefaultDnsChanged = $event;
        this.isChanged = this.changeDetected();
    }

    public joinNetworkManually(): void {
        this.joinWifiStatus = 'Connecting...';
        const input = {
            ssid: this.anotherNetworkForm.value.hiddenSSID,
            username: this.anotherNetworkForm.value.username,
            password: this.anotherNetworkForm.value.password,
            encryption: this.anotherNetworkForm.value.encryption,
            address_protocol: AddressProtocolType.DHCP,
            ipv4: {},
            locked_band: this.lockedBand,
            hidden: 'yes',
        };

        this.connectToWifi(input);

        this._statisticCollector.saveEvent(
            'Network',
            'Join Another network',
            'Join network via button Join'
        );
    }

    public joinWifiNetwork(): void {
        const ipConfigChange = this.staticIpConfigChangeDetected(
            this.selectedWifi.ssid
        );

        if (
            this.selectedWifi.stored &&
            this._isDefaultDnsChanged &&
            !ipConfigChange
        ) {
            this.addDNS = true;
            return;
        }

        if (this._isDefaultDnsChanged) {
            this.addDNS = true;
        }

        this.joinWifiStatus = 'Connecting...';
        const input = {
            ssid: this.selectedWifi.ssid,
            address_protocol: this.wifiConfigForm.value.connectionMethod,
            ipv4:
                this.wifiConfigForm.value.connectionMethod ===
                AddressProtocolType.STATIC
                    ? this._staticIpSetting
                    : {},
        };

        if (this.selectedWifi?.stored && ipConfigChange) {
            const setIpConfigReq = {
                ...input,
                connection_type: NetworkTypes.WIFI,
            };
            this.setWifiIpConfig(setIpConfigReq);
        } else {
            const connectRequest = {
                ...input,
                username: this.wifiConfigForm.value.username || '',
                password: this.wifiConfigForm.value.password || '',
                locked_band: this.lockedBand,
            };

            this.connectToWifi(connectRequest);
        }

        this._statisticCollector.saveEvent(
            'Network',
            'Join network',
            'Join network via button Join'
        );
    }

    connectToWifi(request): void {
        this._ipcService
            .requestConnectWiFi(request)
            .pipe(first(), catchError(this.handleError))
            .subscribe(
                (result) => !!result && this.connectToWifiOnDataReceived(result)
            );
    }

    setWifiIpConfig(request): void {
        this._ipcService
            .requestSetIpConfig(request)
            .pipe(first(), catchError(this.handleError))
            .subscribe(
                (result) => !!result && this.connectToWifiOnDataReceived(result)
            );
    }

    connectToWifiOnDataReceived(res: { state: string; error: string }): void {
        const { state, error } = res;
        if (state === StatusTypes.CONNECTED) {
            this.checkInternetStatus();
        } else {
            if (error === 'BAD PASSWORD') {
                this.showSnackbar(CONNECTION_ERRORS.BAD_WIFI_PASSWORD);
            } else {
                this.showSnackbar(CONNECTION_ERRORS.UNKNOWN_ERROR);
            }
            this.joinWifiStatus = '';
            this.selectedWifi.stored = false;
        }
    }

    checkInternetStatus(): void {
        if (this._isOnlineSub) {
            this._isOnlineSub.unsubscribe();
        }
        this._isOnlineSub = this._ipcService
            .isOnline({
                component: 'Wifi Block - Connection Settings Modal',
                message: 'CHECK_INTERNET_STATUS',
            })
            .pipe(catchError(this.handleError))
            .subscribe((data) => {
                console.log('isOnline: ', data);

                const { success: isInternetConnected } = data;
                if (isInternetConnected) {
                    this.joinWifiStatus = StatusTypes.CONNECTED;
                    this.selectedWifi.active = true;
                    this.selectedWifi.stored = true;
                    this.showNetworkText = false;
                    this._ipcService.requestNetworkList();
                    timer(5000).subscribe(() => {
                        this.joinWifiStatus = '';
                        this.hiddenSSID = '';
                        this.encryption = '';
                        this.lockedBand = 'BAND_AUTO';
                        this.joinAnotherNetworkClicked = false;
                        this.resetForms(false);
                        this.isChanged = false;
                    });
                } else {
                    this.joinWifiStatus = '';
                    this.showSnackbar(CONNECTION_ERRORS.CONNECTION_FAILED);
                }
            });
    }

    handleError(err): Observable<any> {
        this._loggerService.error('WifiComponent', err);
        return of({});
    }

    showSnackbar(msg: string): void {
        this._loggerService.error('wifiComponent', msg);
        this._snackbarService.closeAll();
        this._snackbarService.show(snackConfig(msg));
    }

    togglePassword(): void {
        this.showPassword = !this.showPassword;
    }
}

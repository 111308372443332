import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class EnvironmentConfigService {
    private _settings;

    constructor(private _http: HttpClient) {}

    public init() {
        return this._http
            ?.get('/assets/config/app-config.json')
            .toPromise()
            .then((data) => {
                this._settings = data;
            });
    }

    getSettings() {
        return this._settings || {};
    }
}

<div class="row m-b0 px2 pt3-5 pb2 m-0">
    <div class="col s12 m6 l4 form m-b0">
        <h5 class="section-title">Video Input</h5>
        <div
            class="state success-text m-t2 m-b3 flex flex-row items-center"
            *ngIf="cameraMessage"
            [ngClass]="{
                'success-text': cameraMessage.icon === 'check_circle',
                'danger-text': cameraMessage.icon === 'error_circle'
            }"
        >
            <i class="material-icons m-r1" automation="cameraIcon">
                {{ cameraMessage.icon }}
            </i>
            <p class="m-b0 camera-text-width" automation="cameraText">{{ cameraMessage.text }}</p>
        </div>
        <div class="input-field m-t2">
            <aw-button
                automationValue="cameraOptionsButton"
                *ngIf="!cameraOptions.length"
                styleClasses="btn waves-effect waves-light"
                (onClick)="onStartTesting('camera')"
            >
                Start Testing
            </aw-button>
            <aw-select
                automationValue="cameraOptionsSelect"
                *ngIf="cameraOptions.length"
                [ngModel]="cameraModel"
                (onChange)="onChangeCamera($event)"
                label="Video Input"
                [options]="cameraOptions"
            >
            </aw-select>
        </div>
        <div class="cam-preview gray-light center-align" *ngIf="cameraOptions.length && !cameraId">
            <i class="material-icons gray-mid-text">videocam</i>
        </div>
        <video
            automation="video"
            id="preview"
            autoplay
            *ngIf="cameraOptions.length && cameraId"
            style="max-width: 100%; max-height: 100%; border-radius: 3px"
        ></video>
    </div>

    <div class="col s12 m6 l4 form m-b3">
        <h5 class="section-title">Audio Input</h5>
        <div
            class="state success-text m-t2 m-b3 flex flex-row items-center"
            *ngIf="microphoneMessage"
            [ngClass]="{
                'success-text': microphoneMessage.icon === 'check_circle',
                'danger-text': microphoneMessage.icon === 'error_circle'
            }"
        >
            <i class="material-icons m-r2" automation="microphoneIcon">
                {{ microphoneMessage.icon }}
            </i>
            <p class="m-b0" automation="microphoneText">
                {{ microphoneMessage.text }}
            </p>
        </div>
        <div class="input-field m-t2 m-b3">
            <aw-button
                automationValue="microphoneOptionsButton"
                *ngIf="!microphoneOptions.length"
                styleClasses="btn waves-effect waves-light"
                (onClick)="onStartTesting('microphone')"
            >
                Start Testing
            </aw-button>
            <aw-select
                automationValue="microphoneOptionsSelect"
                *ngIf="microphoneOptions.length"
                [ngModel]="microphoneModel"
                (onChange)="onChangeMicrophone($event)"
                label="Audio Input"
                [options]="microphoneOptions"
            >
            </aw-select>
        </div>
        <aw-sound-meter automationValue="soundMeter" class="wizard-equalizer" [deviceId]="microphoneId">
        </aw-sound-meter>
    </div>

    <div class="col s12 m6 l4 form m-b3">
        <h5 class="section-title">Audio Output</h5>
        <div
            class="state success-text m-t2 m-b3 flex flex-row items-center"
            *ngIf="speakerMessage"
            [ngClass]="{
                'success-text': speakerMessage.icon === 'check_circle',
                'danger-text': speakerMessage.icon === 'error_circle',
                'warning-text': speakerMessage.icon === 'warning'
            }"
        >
            <i class="material-icons m-r2" automation="speakerIcon">
                {{ speakerMessage.icon }}
            </i>
            <p class="m-b0" automation="speakerText">
                {{ speakerMessage.text }}
            </p>
        </div>
        <div class="input-field m-t2 m-b4">
            <aw-button
                automationValue="speakerOptionsButton"
                *ngIf="!speakerOptions.length && !isSpeakerChecked"
                styleClasses="btn waves-effect waves-light"
                (onClick)="onStartTesting('speaker')"
            >
                Start Testing
            </aw-button>
            <aw-select
                automationValue="speakerOptionsSelect"
                *ngIf="speakerOptions.length"
                [ngModel]="speakerModel"
                (onChange)="onChangeSpeaker($event)"
                label="Audio Output"
                [options]="speakerOptions"
            >
            </aw-select>
        </div>
        <p *ngIf="speakerOptions.length || isSpeakerChecked">Adjust the default Volume</p>
        <div class="slider-volume-custom">
            <aw-slider
                automationValue="speakerOptionsSlider"
                *ngIf="speakerOptions.length || isSpeakerChecked"
                [start]="initialSpeakerVolumeLevel"
                [iconLeft]="'volume_down'"
                [iconRight]="'volume_up'"
                (onChange)="onChangeSpeakerVolume($event)"
                (onUpdate)="onUpdateSpeakerVolume($event)"
            >
            </aw-slider>
        </div>
        <p class="m-t4" *ngIf="speakerOptions.length || isSpeakerChecked">Play test sound</p>
        <div class="speaker-holder">
            <div class="progress-box">
                <audio
                    #audioElement
                    preload="metadata"
                    automation="speakerCheckAudioTest"
                    class="audio-test"
                    autostart="false"
                ></audio>
                <div class="player-buttons">
                    <span (click)="playing ? handleStop() : handlePlay()" automation="playPauseIconButton">
                        <i *ngIf="!playing" class="material-icons">play_circle_outline</i>
                        <i *ngIf="playing" class="material-icons">pause_circle_outline</i>
                    </span>
                </div>
                <div class="slider-holder">
                    <aw-slider
                        automationValue="progressSlider"
                        styleClasses="m-0"
                        [start]="0"
                        [min]="0"
                        [max]="duration"
                        [ngModel]="speakerPosition"
                        (onChange)="onChangeAudioTime($event)"
                    >
                    </aw-slider>
                    <div class="time-box flex justify-between">
                        <span id="current-time" class="time" automation="currentTime">0:00</span>
                        <span id="duration" class="time" automation="duration">0:00</span>
                    </div>
                </div>
            </div>
        </div>
        <p class="m-t4" *ngIf="speakerOptions.length || isSpeakerChecked">Adjust the ringtone Volume</p>
        <div class="slider-volume-custom">
            <aw-slider
                automationValue="speakerVolumeSlider"
                *ngIf="speakerOptions.length || isSpeakerChecked"
                [start]="initialRingtoneVolumeLevel"
                [iconLeft]="'volume_down'"
                [iconRight]="'volume_up'"
                (onChange)="onChangeRingtoneVolume($event)"
            >
            </aw-slider>
        </div>
    </div>
</div>

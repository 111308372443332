import { TEST_DEVICE_INFO_DATA } from './../../constants/tests/device-data';
import { IDeviceInfo } from '@/shared/interfaces';
import { Observable, of } from 'rxjs';
export class DeviceServiceMock {
    sendLogs() {}
    requestDeviceInfo(): Observable<IDeviceInfo> {
        return of(TEST_DEVICE_INFO_DATA);
    }
    restart() {}
    factoryDefault() {}
    restartAndReboot() {}
    turnOff() {}
    rollback() {}
}

import { Component, HostListener, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LostNetworkModalComponent } from '@core/components/lost-network-modal/lost-network-modal.component';
import { Store } from '@ngrx/store';
import { Observable, Subscription, timer } from 'rxjs';
import { IpcService, StorageService } from './shared/services';

import { MetricService } from '@services/metrics';
import {
    selectEndpoint,
    selectIsOpenSettingModal,
    selectMediaDevicesSeparateList,
    selectShowKeyboard,
} from '@storage/selectors';
import { withLatestFrom } from 'rxjs/operators';
import { DeviceState } from './shared/enums';
import {
    IActiveModal,
    onScreenKeyboardChanged,
    openModal,
} from './shared/storage';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnDestroy {
    public showKeyboard$ = this._store.select(selectShowKeyboard);

    private _subscriptions: Subscription[] = [];
    private _redirectToLostConSub: Subscription;
    private _endpointUpdated: Subscription;
    private _dialogRef: MatDialogRef<LostNetworkModalComponent>;
    showKeyboard = false;
    constructor(
        private _ipcService: IpcService,
        private _metricService: MetricService,
        private _router: Router,
        private _store: Store,
        private _storageService: StorageService
    ) {
        (
            this._storageService.subscribeOnAuthIsInternetConnected(
                false
            ) as Observable<unknown>
        ).subscribe((isInternetConnectedFlag) => {
            isInternetConnectedFlag &&
                this._ipcService.requestChangeLedRingColor(DeviceState.ONLINE);

            if (isInternetConnectedFlag && this._dialogRef) {
                if (this._redirectToLostConSub) {
                    this._redirectToLostConSub.unsubscribe();
                }
                this._dialogRef.close();
                return;
            }

            if (!isInternetConnectedFlag) {
                const modal = {
                    componentName: LostNetworkModalComponent.dialogName,
                    config: {
                        panelClass: ['aw-modal', 'modal-lg'],
                    },
                    getDialogRef: (dialogRef: MatDialogRef<any>) => {
                        this._dialogRef = dialogRef;
                    },
                } as IActiveModal;
                this._store.dispatch(openModal({ modal }));

                this._redirectToLostConSub = timer(10000)
                    .pipe(
                        withLatestFrom(
                            this._store.select(selectIsOpenSettingModal)
                        )
                    )
                    .subscribe(([, isOpenSettingModal]) => {
                        this._dialogRef?.close();
                        this._ipcService.requestChangeLedRingColor(
                            'NETWORK_ERROR'
                        );
                        if (!isOpenSettingModal) {
                            this._router.navigateByUrl('/lost-connection');
                        }
                    });
            }
        });

        this._endpointUpdated = this._store
            .select(selectEndpoint)
            .subscribe((val) => {
                if (val) {
                    this._storageService.updateMediaDevices();
                    navigator.mediaDevices.ondevicechange = () => {
                        this._storageService.updateMediaDevices();
                    };
                    if (this._endpointUpdated) {
                        this._endpointUpdated.unsubscribe();
                    }
                }
            });

        const devicesSubscription = this._store
            .select(selectMediaDevicesSeparateList)
            .subscribe((current_device_station) => {
                this._metricService.sendMetrics(current_device_station);
            });

        this._subscriptions.push(devicesSubscription);

        const keyboardSubscription = this.showKeyboard$.subscribe(
            (showKeyboard) => {
                this.showKeyboard = showKeyboard;
                if (!showKeyboard) {
                    this.handlerKeyboardOpened(false);
                }
            }
        );

        this._subscriptions.push(keyboardSubscription);
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((subscription: Subscription) => {
            subscription?.unsubscribe();
        });
        this._endpointUpdated.unsubscribe();
    }

    @HostListener('body:focusin', ['$event.target']) lifeHackKeyboardHandler(
        targetElement: HTMLElement
    ) {
        if (!this.showKeyboard || !targetElement.classList.length) {
            return;
        }

        let isKeyboardClass = false;
        const keyboardClassList = [
            'js-keyboard-input',
            'js-non-capitalize',
            'js-keyboard-input-number',
        ];

        targetElement.classList.forEach((className: string) => {
            if (keyboardClassList.includes(className)) {
                isKeyboardClass = true;
            }
        });

        if (isKeyboardClass) {
            timer(100).subscribe(() => {
                targetElement.parentElement.scrollIntoView({ block: 'center' });
            });
            this.handlerKeyboardOpened(true);
        }
    }

    handlerKeyboardOpened(isOpened: boolean) {
        this._store.dispatch(
            onScreenKeyboardChanged({ onScreenKeyboardOpened: isOpened })
        );
    }

    handlerEnterPressed() {
        const sendBtns = Array.from(
            document.querySelectorAll('.aw-send-button')
        ).filter((i) => {
            return (i as HTMLElement).offsetParent;
        });
        sendBtns.length ? (sendBtns[0] as HTMLElement)?.click() : null;
    }
}

<div class="flex flex-column gradient-diagonal-blue h100">
    <div class="container fullwidth flex flex-column flex-1">
        <div class="flex flex-column flex-regular justify-center items-center text-center white m-y3-5 px15 py15">
            <span *ngIf="errorIcon" class="icon {{ errorIcon }} lg-icon layered m-x-auto graphics-icon-big">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
            </span>
            <div>
                <h1 class="fw-normal" *ngIf="errorMessage">{{ errorMessage }}</h1>
                <p *ngIf="errorSubText">{{ errorSubText }}</p>
                <div class="flex justify-center">
                    <aw-button
                        automation="restart"
                        styleClasses="btn btn-default waves-effect waves-light btn-large m-r1"
                        (onClick)="restart()"
                    >
                        Restart
                    </aw-button>
                    <aw-button
                        automation="network-settings"
                        styleClasses="btn btn-default waves-effect waves-light btn-large m-r1"
                        (onClick)="networkSettings()"
                    >
                        Network Settings
                    </aw-button>
                    <aw-button
                        automation="factory-default"
                        styleClasses="btn btn-default waves-effect waves-light btn-large m-r1"
                        (onClick)="factoryDefault()"
                    >
                        Factory Default
                    </aw-button>
                    <aw-button
                        automation="rollback-software"
                        styleClasses="btn btn-default waves-effect waves-light btn-large m-r1"
                        (onClick)="requestRollback()"
                    >
                        Revert Software
                    </aw-button>

                    <aw-button
                        automation="goToDashboard"
                        *ngIf="!isFleetApiOffline"
                        styleClasses="btn btn-primary waves-effect waves-light btn-large m-r1"
                        (onClick)="goToDashboard()"
                        [disabled]="isFleetApiOffline"
                    >
                        Return To Dashboard
                    </aw-button>
                    <aw-button
                        automation="turnoff"
                        styleClasses="btn btn-default waves-effect waves-light btn-large"
                        (onClick)="turnOff()"
                    >
                        Turn Off
                    </aw-button>
                </div>
            </div>
        </div>
    </div>
</div>

import { RollbackOsVersionLimitation } from '@/shared/enums/rollback.enum';
import { IDeviceInfo } from '@/shared/interfaces/device/device-info.interface';
import { compare as humanReadableCompare } from 'compare-versions';

export function isAllowRollbackOs({ rollbackOsVersion }: IDeviceInfo): boolean {
    if (!rollbackOsVersion) {
        return false;
    }
    return humanReadableCompare(
        rollbackOsVersion,
        RollbackOsVersionLimitation.Os,
        '>='
    );
}

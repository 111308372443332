import { IPeripherals } from '@shared/interfaces';
import { getMockLabel } from './mock-devices';
import {
    Dermatoscope,
    horusScope2,
    Otoscope,
    Stethoscope,
    ExamCamera,
    RnkStethoscope,
} from './peripherals-steps';

export const PERIPHERALS: IPeripherals[] = [
    {
        productName: 'uvc',
        title: 'Firefly Otoscope',
        productId: 24635,
        vendorId: 8653,
        image: '/assets/images/peripherals-devices/avizia_firefly_otoscope.webp',
        canTakeSnapshots: true,
        getLabel(): string {
            return (
                getMockLabel('FIREFLY_OTOSCOPE') ||
                `Digital Microscope (21cd:603b)`
            );
        },
        helpSteps: Otoscope,
    },
    {
        productName: 'uvc',
        title: 'Firefly Dermatoscope',
        productId: 28731,
        vendorId: 8653,
        image: 'assets/images/peripherals-devices/avizia_firefly_dermatoscope.webp',
        canTakeSnapshots: true,
        getLabel(): string {
            return (
                getMockLabel('FIREFLY_DERMATOSCOPE') ||
                `Digital Microscope (21cd:703b)`
            );
        },
        helpSteps: Dermatoscope,
    },
    {
        productName: 'uvc',
        title: 'Firefly Exam Camera',
        productId: 2050,
        vendorId: 8653,
        image: 'assets/images/peripherals-devices/avizia_firefly_exam_camera.webp',
        canTakeSnapshots: true,
        getLabel() {
            return (
                getMockLabel('FIREFLY_EXAM_CAMERA') ||
                `${this.vendorId.toString(16)}:${this.productId
                    .toString(16)
                    .padStart(4, '0')}`
            );
        },
        helpSteps: ExamCamera,
    },
    {
        productName: 'Camera',
        title: 'Horus scope',
        productId: 258,
        vendorId: 7531,
        getLabel(): string {
            return (
                getMockLabel('HORUSCOPE') ||
                `${this.vendorId.toString(16)}:${this.productId
                    .toString(16)
                    .padStart(4, '0')}`
            );
        },
        helpSteps: horusScope2,
        image: 'assets/images/peripherals-devices/avizia_horus_scope_series_2.webp',
        canTakeSnapshots: true,
    },
    {
        productName: 'Stethoscope',
        title: 'Stethoscope',
        productId: 3468,
        vendorId: 32,
        getLabel(): string {
            // return '0d8c:0020';
            // Temp fix - enumeratedevice does not show pidvid for this device.
            return getMockLabel('RI_SONIC_STETHOSCOPE') || 'ATR2USB';
        },
        helpSteps: Stethoscope,
        image: 'assets/images/peripherals-devices/avizia_stethoscope_device.webp',
        recording: true,
    },
    {
        productName: 'Stethoscope',
        title: 'Stethoscope',
        productId: 3468,
        vendorId: 32,
        getLabel() {
            return getMockLabel('RI_SONIC_STETHOSCOPE') || '08bb:29c0';
        },
        helpSteps: Stethoscope,
        image: 'assets/images/peripherals-devices/avizia_stethoscope_device.webp',
        recording: true,
    },
    {
        productName: 'Stethoscope',
        title: 'Stethoscope',
        productId: 10688,
        vendorId: 2235,
        getLabel(): string {
            return getMockLabel('RI_SONIC_STETHOSCOPE') || 'PCM2900';
        },
        helpSteps: Stethoscope,
        image: 'assets/images/peripherals-devices/avizia_stethoscope_device.webp',
        recording: true,
    },
    {
        productName: 'RNK Steth',
        title: 'Stethoscope',
        vendorId: 3468,
        productId: 308,
        getLabel() {
            return getMockLabel('RI_SONIC_STETHOSCOPE') || 'PCP-USB';
        },
        helpSteps: RnkStethoscope,
        image: 'assets/images/peripherals-devices/rnk-steth.webp',
        recording: true,
    },
    {
        productName: 'RNK Steth',
        title: 'Stethoscope',
        vendorId: 3468,
        productId: 308,
        getLabel() {
            return (
                getMockLabel('RI_SONIC_STETHOSCOPE') || 'USB PnP Audio Device'
            );
        },
        helpSteps: RnkStethoscope,
        image: 'assets/images/peripherals-devices/rnk-steth.webp',
        recording: true,
    },
    {
        productName: 'Camera',
        title: 'RCS 100 EXAM CAMERA',
        vendorId: 3725,
        productId: 8459,
        getLabel() {
            return (
                getMockLabel('RCS100') || 'DriverCoding UVC Camera (0e8d:210b)'
            );
        },
        recording: true,
    },
];

<div class="gradient-diagonal-blue h100 overflow-x-hidden overflow-auto">
    <div class="header-content-light flex pt3 justify-between items-center">
        <div class="m-l3">
            <!-- <aw-button *ngIf="showSettingsLink" button-style-class="btn-flat waves-effect waves-light white-text btn-large btn-back"
                ng-click="$ctrl.gotoConnectionMethod()">
                <p>{{ showSettingsLink }}</p>
                <i class="material-icons">keyboard_arrow_left</i>
                Network Settings
            </aw-button> -->
        </div>
        <div class="flex items-center m-r3">
            <img class="header-logo" src="../../../../assets/images/AmwellWhiteLogo.svg" />
            <!-- <aw-button styleClasses="icon-link white-text m-l3">
                <i class="material-icons fz-h1">more_vert</i>
            </aw-button> -->
        </div>
    </div>
    <div
        class="page-content-light flex justify-center items-center flex-column pb35"
        [@fadeAnimation]="outlet.isActivated ? outlet.activatedRoute : ''"
    >
        <router-outlet #outlet="outlet"></router-outlet>
    </div>
</div>

import { IProviderTypes } from '@/shared/interfaces/service-line/service-line-detail.interface';
import { IServiceLine } from '@/shared/interfaces/service-line/service-line.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { SessionService } from '../session/session.service';
import { Observable } from 'rxjs';
import { StorageService } from '../storage';

@Injectable({
    providedIn: 'root',
})
export class ServiceLineService {
    private _serviceLine: IServiceLine;

    constructor(
        private _http: HttpClient,
        private _sessionService: SessionService,
        private _storageService: StorageService
    ) {}

    set serviceLine(serviceLine: IServiceLine) {
        this._serviceLine = serviceLine;
    }

    get serviceLine(): IServiceLine {
        return this._serviceLine;
    }

    getName(): string {
        return this.serviceLine ? this.serviceLine.name : '';
    }

    getTheme(): string {
        return this.serviceLine ? this.serviceLine.theme : '';
    }

    getServiceLineDetail(serviceLineId: number | string): Observable<any> {
        return this._http.get(`v10/service_line/${serviceLineId}`).pipe(
            tap((result: IServiceLine) => {
                this._storageService.updateServiceLine({
                    forms: result.forms,
                    practice: result.practice,
                    providerTypes: result.providerTypes,
                });
            })
        );
    }

    getNextRoute(serviceLine: IServiceLine): string {
        if (
            serviceLine.is_ocg_select_provider &&
            serviceLine.is_ocg_assign_to_first
        ) {
            return 'provider_type';
        } else if (serviceLine.is_ocg_select_provider) {
            return 'search_provider';
        } else if (serviceLine.is_ocg_assign_to_first) {
            return 'first_available';
        }
    }

    get practiceSourceId(): string {
        const {
            practice: { practice_source_id = '' },
        } = this.serviceLine;
        return practice_source_id;
    }

    get providerTypes(): IProviderTypes[] {
        return this.serviceLine.providerTypes;
    }

    getProviderTypes() {
        if (!this.serviceLine.providerTypes) {
            return [];
        }

        return this.serviceLine.providerTypes.map(
            ({ provider_type_enum }) => provider_type_enum
        );
    }

    getProviderTypesOriginalData() {
        return this.serviceLine.providerTypes;
    }

    getSourceId(caseId): string {
        const envName =
            (this._sessionService.deviceInfo &&
                this._sessionService.deviceInfo.environment) ||
            'dev';
        return (
            caseId +
            '|' +
            this._sessionService.getEnterprise().enterprise_id +
            '|' +
            envName
        );
    }

    getGenderOptions(allowBlank = false): void {
        this._http
            .get('v10/misc/gender_options')
            .subscribe((genderSettings) => {
                const staticGenders = [];
                const additionalGenders = [];
                const unknownGenders = [];

                if (allowBlank) {
                    // Blank option should be available in some drop down menus.
                    staticGenders.push({
                        value: '',
                        view: '',
                    });
                }

                Object.keys(genderSettings).forEach((key) => {
                    const current = genderSettings[key];
                    const append = (list) => {
                        list.push({
                            value: key,
                            view: current[0],
                        });
                    };

                    if (current[0] === 'Unknown') {
                        append(unknownGenders);
                    } else if (current.length === 1) {
                        append(staticGenders);
                    } else if (current[1]) {
                        append(additionalGenders);
                    }
                });

                const compare = (a, b) => {
                    return a.view.localeCompare(b.view, 'en', {
                        numeric: true,
                    });
                };

                // Sorted order should be [Female, Male, etc1, etc2..., Unknown]
                const genderList = staticGenders
                    .sort(compare)
                    .concat(additionalGenders.sort(compare))
                    .concat(unknownGenders);

                this._storageService.setGenderOptions(genderList);
            });
    }
}

import { Injectable } from '@angular/core';
import { SessionService } from '../session';
import { IIpcResponseHandler } from '../../interfaces';
import { StorageService } from '../storage';
import { MetricService } from '../metrics';
import { LoggerService } from '../logger';
import { IpcObserverService } from './ipc-observer.service';
import {
    SUB_SYSTEM_TYPES,
    SYSTEM_COMMANDS_TYPES,
} from '@/shared/constants/system-command';

@Injectable({ providedIn: 'root' })
export class SpineResponseService implements IIpcResponseHandler {
    constructor(
        private _storageService: StorageService,
        private _sessionService: SessionService,
        private _metricService: MetricService,
        private _loggerService: LoggerService,
        private _observerService: IpcObserverService
    ) {}
    handleResponse(response): void {
        const instance = this._sessionService.deviceInstance;
        const _isResponseSuccess = !!response?.spine_comms?.success;
        const _responseData = response?.spine_comms?.response_data;
        const _responseSuccessAndDataExists =
            _isResponseSuccess && !!_responseData;

        switch (response.spine_comms.subsystem) {
            case SUB_SYSTEM_TYPES.SPINE_MANAGER:
                switch (response.spine_comms.module) {
                    case SYSTEM_COMMANDS_TYPES.EVENT:
                        switch (response.spine_comms.event) {
                            case SYSTEM_COMMANDS_TYPES.HEADPHONES_DETECT:
                            case SYSTEM_COMMANDS_TYPES.LINE_IN_DETECT:
                            case SYSTEM_COMMANDS_TYPES.HEADSET_MIC_DETECT:
                                this._observerService.notifyChanges({
                                    command: response.spine_comms.event,
                                    data: {
                                        isConnected:
                                            response.spine_comms.event_data ===
                                            'true',
                                    },
                                });
                                break;
                            case SYSTEM_COMMANDS_TYPES.PERSON_DETECTED:
                                if (
                                    instance &&
                                    instance.standbyState &&
                                    this._sessionService.getConfig()
                                        .proximity_wake
                                ) {
                                    this._observerService.notifyChanges({
                                        command:
                                            SYSTEM_COMMANDS_TYPES.STANDBY_STATE,
                                        data: { state: false },
                                    });
                                }
                                break;
                            case SYSTEM_COMMANDS_TYPES.POWER_BUTTON:
                                if (
                                    response.spine_comms.event_data ===
                                        'SHORT_PRESS' &&
                                    !instance.onCall
                                ) {
                                    this._observerService.notifyChanges({
                                        command:
                                            SYSTEM_COMMANDS_TYPES.STANDBY_STATE,
                                        data: { state: !instance.standbyState },
                                    });
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                    case SYSTEM_COMMANDS_TYPES.NERVE:
                        if (response.spine_comms.success) {
                            const responseData =
                                response.spine_comms.response_data;
                            switch (response.spine_comms.command) {
                                case SYSTEM_COMMANDS_TYPES.NERVE_GET_DETECTED:
                                    if (
                                        responseData &&
                                        responseData.nerve_devices_list &&
                                        responseData.nerve_devices_list.includes(
                                            'RIPPLE'
                                        )
                                    ) {
                                        this._observerService.notifyChanges({
                                            command:
                                                SYSTEM_COMMANDS_TYPES.NERVE_GET_DETECTED,
                                        });
                                    } else {
                                        const powerStatus = {
                                            batteryConnected: false,
                                            acPowerConnected: true,
                                        };

                                        this._storageService.updateBatteryStatus(
                                            powerStatus
                                        );
                                        const batteryMetric = {
                                            ac_power_connected: Number(
                                                powerStatus.acPowerConnected
                                            ),
                                            battery_connected: Number(
                                                powerStatus.batteryConnected
                                            ),
                                        };
                                        this._metricService.enqueue(
                                            batteryMetric
                                        );
                                        this._loggerService.log(
                                            'BATTERY_METRIC',
                                            JSON.stringify(batteryMetric)
                                        );
                                    }
                                    break;
                                case SYSTEM_COMMANDS_TYPES.RIPPLE_GET_AC_BATT_INFO:
                                    if (responseData) {
                                        this._storageService.updateBatteryStatus(
                                            this._sessionService.deviceInstance.handleBatteryResponse(
                                                responseData
                                            )
                                        );
                                    }
                                    break;
                                default:
                                    break;
                            }
                        } else {
                            this._loggerService.error(
                                'ERROR_SPINE_NERVE_RESPONSE',
                                JSON.stringify(response)
                            );
                        }
                        break;
                    case SYSTEM_COMMANDS_TYPES.CONFIG:
                        if (!_responseSuccessAndDataExists) {
                            return;
                        }

                        // https://amwell.atlassian.net/browse/NOVW-228
                        // kept previous field name: serial_number
                        // and also add new field from ticket: device_serial_number
                        const serialNumber =
                            _responseData?.serial_number ||
                            _responseData?.device_serial_number;
                        this._sessionService.deviceSerialNumber = serialNumber;
                        this._metricService.enqueue({
                            device_serial_number: serialNumber,
                        });
                        break;
                    case SYSTEM_COMMANDS_TYPES.SYSTEM:
                        if (!_responseSuccessAndDataExists) {
                            return;
                        }
                        const firmware_version = _responseData?.fw_version;
                        this._sessionService.firmwareVersion = firmware_version;
                        this._metricService.enqueue({ firmware_version });
                        break;
                }
        }
    }
}

import {
    Directive,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
    selector: '[awDebounceClick]',
})
export class DebounceClickDirective implements OnInit, OnDestroy {
    @Input()
    debounceTime = 500;

    @Output()
    debounceClick = new EventEmitter();

    private _clicks = new Subject();
    private _subscription: Subscription;

    ngOnInit() {
        this._subscription = this._clicks
            .pipe(debounceTime(this.debounceTime))
            .subscribe((e) => {
                this.debounceClick.emit(e);
            });
    }

    ngOnDestroy() {
        this._subscription && this._subscription.unsubscribe();
    }

    @HostListener('click', ['$event'])
    clickEvent(event) {
        event.preventDefault();
        event.stopPropagation();
        this._clicks.next(event);
    }
}

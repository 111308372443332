import { SYSTEM_COMMANDS_TYPES } from '@/shared/constants/system-command';
import { Injectable } from '@angular/core';
import { IIpcResponseHandler } from '../../interfaces';
import { IpcObserverService } from './ipc-observer.service';

@Injectable({ providedIn: 'root' })
export class FileResponseService implements IIpcResponseHandler {
    constructor(private _observerService: IpcObserverService) {}
    handleResponse(response): any {
        const { file: fileResponse } = response;
        const { command } = fileResponse;
        switch (command) {
            case SYSTEM_COMMANDS_TYPES.READ_FILE:
                this._observerService.notifyChanges({
                    command: SYSTEM_COMMANDS_TYPES.READ_FILE,
                    data: response,
                });
                break;
            case SYSTEM_COMMANDS_TYPES.WRITE_FILE:
                this._observerService.notifyChanges({
                    command: SYSTEM_COMMANDS_TYPES.WRITE_FILE,
                    data: response,
                });
                break;
            case SYSTEM_COMMANDS_TYPES.REMOVE_FILE:
                this._observerService.notifyChanges({
                    command: SYSTEM_COMMANDS_TYPES.REMOVE_FILE,
                    data: response,
                });
                break;
            default:
                break;
        }
    }
}

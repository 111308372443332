import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { MetricService } from '../metrics';
import { IIpcResponseHandler } from '@/shared/interfaces/ipc';
import {
    IRollbackUpdateResponse,
    IRollbackResponseService,
} from '@/shared/interfaces/rollback.interface';
import {
    RollbackStateEnums,
    RollbackSubSystemEnum,
    SecondsEnum,
} from '@/shared/enums';
import { DeviceService } from '../device';

@Injectable({ providedIn: 'root' })
export class RollbackResponseService implements IIpcResponseHandler {
    private _rollbackUpdateState$: Subject<IRollbackUpdateResponse> =
        new Subject();
    private _responseData: IRollbackUpdateResponse;
    constructor(
        private _metricService: MetricService,
        private _deviceService: DeviceService
    ) {}

    public handleResponse(response: IRollbackResponseService) {
        const { updater: updaterResponse } = response;
        this.handleRollbackResponse(updaterResponse);
    }

    public handleRollbackResponse(responseData: IRollbackUpdateResponse): void {
        this._responseData = responseData;
        this.reportRollbackStateToMetrics();
        switch (this._responseData.state) {
            case RollbackStateEnums.COMPLETE:
                this.setRollbackUpdateState();
                this.restartAndRebootDevice();
                break;
            case RollbackStateEnums.ERROR:
                this.setRollbackUpdateState();
                break;
        }
    }

    public reportRollbackStateToMetrics(): void {
        if (
            this._responseData.subsystem === RollbackSubSystemEnum.APPLICATION
        ) {
            this._metricService.enqueue({
                app_rollback_state: this._responseData.state,
            });
        } else {
            this._metricService.enqueue({
                os_rollback_state: this._responseData.state,
            });
        }
    }

    public setRollbackUpdateState(): void {
        this._rollbackUpdateState$.next(this._responseData);
    }

    public restartAndRebootDevice(): void {
        setTimeout(
            () => this._deviceService.restartAndReboot(),
            SecondsEnum.Five
        );
    }

    public observeRollbackUpdateState(): Observable<IRollbackUpdateResponse> {
        return this._rollbackUpdateState$.asObservable();
    }
}

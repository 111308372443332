import { rejoinConvergeCall } from '@/shared/storage/layout/layout.actions';
import { selectIsBatteryConnected } from '@/shared/storage/selectors/battery.selectors';
import { selectEndpointName } from '@/shared/storage/selectors/endpoint.selectors';
import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, timer, Observable } from 'rxjs';

@Component({
    selector: 'core-empty-dashboard',
    templateUrl: './empty-dashboard.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyDashboardLayout implements OnInit, OnDestroy {
    public endpointName$: Observable<string | null> =
        this._store.select(selectEndpointName);
    public hasBattery$: Observable<boolean> = this._store.select(
        selectIsBatteryConnected
    );
    private _subscriptions: Subscription[] = [];

    constructor(private _store: Store) {}

    ngOnInit() {
        this._subscriptions.push(
            timer(5000).subscribe(() =>
                this._store.dispatch(rejoinConvergeCall())
            )
        );
    }
    ngOnDestroy() {
        this._subscriptions.forEach((sub) => sub && sub.unsubscribe());
    }
}

type DevicesMockId =
    | 'RI_SONIC_STETHOSCOPE'
    | 'THINKLABS_STETHOSCOPE'
    | 'HORUSCOPE'
    | 'FIREFLY_EXAM_CAMERA'
    | 'FIREFLY_DERMATOSCOPE'
    | 'FIREFLY_OTOSCOPE'
    | 'RCS100';

const getDevicesMockLabels = () => {
    const mockData = JSON.parse(localStorage.getItem('devices-mock-data'));
    if (!mockData) return [];
    const mockLabels = Object.values(mockData)
        .map((x: { label: string }) => x.label)
        .filter((x) => x != null);
    const labels = Array.from(new Set(mockLabels));
    return labels;
};

const getMockLabel = (deviceMockId: DevicesMockId): string => {
    const mockData = JSON.parse(localStorage.getItem('devices-mock-data'));
    if (!mockData || !mockData[deviceMockId]) return null;
    return mockData[deviceMockId].label;
};

export { getDevicesMockLabels, getMockLabel };

<div class="modal-content-wrapper">
    <div class="modal-heading p0">
        <div class="clearfix page-heading-simple gradient-horizontal-blue">
            <h2 class="m-0 white-text">Settings</h2>
            <aw-button
                (onClick)="closeAnalytic()"
                automationValue="closeModalIcon"
                aria-label="Dismiss"
                styleClasses="mat-dialog-close icon-link white-text"
                [mat-dialog-close]="'Cross'"
            >
                <i class="material-icons">close</i>
            </aw-button>
        </div>
        <aw-tabs
            automationValue="settingsTab"
            styleClasses="fullwidth tabs-transparent gradient-horizontal-blue tabs settings-tabs"
            [activeTab]="activeTab"
            (activeTabChange)="activeTabChange($event)"
        >
            <aw-tab
                automationValue="tab{{ tab.key }}"
                *ngFor="let tab of settingsTabs | keyvalue: originalOrder"
                elementId="{{ tab.value }}"
                styleClasses="col s6 white-text"
            >
                {{ tab.key }}
            </aw-tab>
        </aw-tabs>
    </div>
    <div class="modal-content p0">
        <div class="modal-content-alt">
            <ng-container>
                <div *ngIf="activeTab === 'general'" automation="generalTab" class="w100">
                    <app-general-tab></app-general-tab>
                </div>
                <div *ngIf="activeTab === 'av'" automation="audioVideoTab" class="w100">
                    <app-audio-video-tab></app-audio-video-tab>
                </div>
                <div *ngIf="activeTab === 'connectivity'" automation="connectivityTab" class="w100">
                    <app-connectivity-tab></app-connectivity-tab>
                </div>
            </ng-container>
        </div>
    </div>
</div>

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    IAuthenticationResponse,
    IEndpointConfiguration,
} from '@shared/interfaces';
import { Observable } from 'rxjs';
import { LOCALHOST_URL } from '@shared/constants';
import { IServiceLineResponse } from '@interfaces/service-line';
import { tap } from 'rxjs/operators';
import { StorageService } from '../storage/storage.service';

@Injectable({ providedIn: 'root' })
export class EndPointService {
    constructor(
        private _http: HttpClient,
        private _storageService: StorageService
    ) {}

    public saveConfiguration(
        configData: IEndpointConfiguration
    ): Observable<any> {
        return this._http.post('v10/settings', configData);
    }

    public getConfiguration(): Observable<IEndpointConfiguration> {
        return this._http.get<IEndpointConfiguration>('v10/endpoint/config');
    }

    public authenticate(): Observable<IAuthenticationResponse> {
        return this._http.get<IAuthenticationResponse>('v10/authenticate');
    }

    public createProvider(provider): Observable<any> {
        return this._http
            .post('v10/create_provider', {
                user_id: provider.sourceId,
                fullName: provider.fullName,
                firstName: provider.firstName,
                lastName: provider.lastName,
            })
            .pipe(
                tap((response: any) =>
                    this._storageService.setProviderId(response.user_id)
                )
            );
    }

    public getServiceLines(): Observable<IServiceLineResponse[]> {
        return this._http.get<IServiceLineResponse[]>('v10/service_lines');
    }

    getMutualAuthTokenFromV10Server(): Observable<any> {
        return this._http.post('v10/amwell/get_mutual_auth_token', {});
    }

    public getTokens(data): Observable<any> {
        return this._http.post<any>('converge/token', data);
    }

    isOnline(): Observable<any> {
        return this._http.get<any>(`${LOCALHOST_URL}/isOnline`);
    }

    isOnlineWithTimeOut(): Observable<any> {
        return this._http.get<any>(`${LOCALHOST_URL}/isOnlineWithTimeOut`);
    }
}

import { IServiceLine } from '@/shared/interfaces';
import { createAction, props } from '@ngrx/store';

const CREATE_CASE = 'CREATE_CASE';

const CONFIRM_CANCEL_CASE = 'CONFIRM_CANCEL_CASE';
const CANCEL_CASE = 'CANCEL_CASE';
const DISCARD_CANCEL_CASE = 'DISCARD_CANCEL_CASE';

const CONFIRM_LEAVE_CASE_OPEN = 'CONFIRM_LEAVE_CASE_OPEN';
const LEAVE_CASE_OPEN = 'LEAVE_CASE_OPEN';

export const createCase = createAction(
    CREATE_CASE,
    props<{ intakeData: IServiceLine }>()
);

export const confirmCancelCase = createAction(CONFIRM_CANCEL_CASE);
export const cancelCase = createAction(CANCEL_CASE);
export const discardCancelCase = createAction(DISCARD_CANCEL_CASE);

export const confirmLeaveCaseOpen = createAction(CONFIRM_LEAVE_CASE_OPEN);
export const leaveCaseOpen = createAction(LEAVE_CASE_OPEN);

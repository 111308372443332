export const ERROR_MESSAGES = {
    HTTP_ERROR_NO_INTERNET: 'device is not connected to internet',
    ADD_HOSTNAME_FAILED: 'Error editing hostname',
    CUSTOM_DATA_FAIL_SUB_TEXT:
        'Try to restart the system or contact American Well Support',
    STANDARD_FAIL_SUB_TEXT:
        'Try to restart the system or contact your local IT administrator for help.',
    TIMEOUT_FAIL_SUB_TEXT:
        'Have you got a QR code ready? Open it on a device and try scanning again.',
    SYSTEM_ERROR: 'A system error occurred',
    AUTH_ERROR: 'A system error occurred',
    AUTH_ERROR_MESSAGE: 'Unable to Authenticate App',
    WEBSOCKET_ERROR: 'A system error occurred',
    WEBSOCKET_ERROR_MESSAGE: 'Websocket is disconnected',
    MEDIA_ERROR: 'Media Devices not found',
    INPUT_EMPTY_ERROR_MESSAGE: `Can't be empty`,
    INPUT_ERROR_CLASS: 'has-error',
    INPUT_WARNING_CLASS: 'val-warning',
    INPUT_SUCCESS_CLASS: 'val-success',
    AMWELL_SERVICE_NOT_REACHABLE: 'Amwell Service not reachable',
    DNS_ERROR_SUB_TEXT: 'Check your network settings',
    BAD_WIFI_PASSWORD: 'Bad wifi password. Please try again.',
    CONNECTION_FAILED:
        'Connection Failed - Please ensure the network information is accurate and then retry.',
    VIDEO_CALL_ERROR: 'Video Call Failed',
    VIDEO_CALL_ERROR_MESSAGE:
        'Can not connect to video call right now, please try again later',
    VIDEO_CALL_ROOM_ERROR: 'Room not available',
    TIMEOUT_ERROR: 'Gateway Time-out',
    UNKNOWN_ERROR: 'Unknown Error',
    FLEET_NOT_REACHABLE: 'Fleet Service is not reachable',
};

export const CONNECTION_ERRORS = {
    BAD_WIFI_PASSWORD: 'Bad wifi password. Please try again.',
    UNKNOWN_ERROR: 'Unable to connect. Please try again.',
    CONNECTION_FAILED:
        'Connection Failed - Please ensure the network information is accurate and then retry.',
    CONFIG_ALREADY_EXISTS_ERROR:
        'Configuration already exists. No connection was made at this time.',
    SET_HOSTNAME_FAILED: 'Unable to set Host Name',
    SET_THRESHOLD_FAILED: 'Unable to set Threshold Aggression',
    SET_LOCKED_BAND_FAILED: 'Unable to set wifi band',
};

export const horusScope2 = [
    `
    <div class="hslide-top items-start">
        <img class="" src="/assets/images/peripherals-devices/avizia_device_lens.webp" alt="" />
    </div>
    <div class="hslide-bottom">
        <h3>Connect the appropriate lens for your use case.</h3>
        <span class="hslide-step">1 of 6</span>
    </div>
    `,
    `
    <div class="hslide-top items-start">
        <img class="" src="/assets/images/peripherals-devices/avizia_device_power.webp" alt="" />
    </div>
    <div class="hslide-bottom">
        <h3>
            Ensure the device is powered on by pressing and holding <br />
            on the button on the top left of the device.
        </h3>
        <span class="hslide-step">2 of 6 </span>
    </div>
    `,
    `<div class="hslide-top">
        <i class="slide-icon rotate180 material-icons">forward</i>
    </div>
    <div class="hslide-bottom">
        <h3>Click share to start sharing the video.</h3>
        <span class="hslide-step">3 of 6</span>
    </div>
    `,
    `
    <div class="hslide-top items-start">
        <img class="" src="/assets/images/peripherals-devices/avizia_device_focus_2.webp" alt="" />
    </div>
    <div class="hslide-bottom">
        <h3>Focus the image by half-pressing on the ok button.</h3>
        <span class="hslide-step">4 of 6 </span>
    </div>
    `,
    `
    <div class="hslide-top items-start">
        <img class="" src="/assets/images/peripherals-devices/avizia_device_focus_2.webp" alt="" />
    </div>
    <div class="hslide-bottom">
        <h3>Click and hold on the ok button to freeze the image.</h3>
        <span class="hslide-step">5 of 6</span>
    </div>
    `,
    `
    <div class="hslide-top">
        <i class="slide-icon rotate180 material-icons">forward</i>
    </div>
    <div class="hslide-bottom">
        <h3>
            Click the snapshot button on the interface <br />
            to upload a screenshot to this case.
        </h3>
        <span class="hslide-step">6 of 6</span>
    </div>
    `,
];

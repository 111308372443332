<div
    class="h100 overflow-x-hidden overflow-auto"
    [ngClass]="{ 'global-keyboard-padding': (isKeyboardOpened$ | async) }"
>
    <div class="header-content-main flex justify-between items-center white py1">
        <div>
            <endpoint-photo></endpoint-photo>
        </div>
        <div class="flex items-center m-r3">
            <battery-manager></battery-manager>
            <network-indicator></network-indicator>
            <menu class="m-y0"></menu>
        </div>
    </div>
    <div class="page-content-main rel flex flex-column gray-background pb35">
        <div class="title-block gradient-diagonal-blue w100 absolute"></div>
        <router-outlet></router-outlet>
    </div>
</div>

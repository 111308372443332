import { AmwellCart250 } from '@/shared/device/amwell-cart-250';
import { AmwellCart500 } from '@/shared/device/amwell-cart-500';
import { AmwellTv } from '@/shared/device/amwell-tv';
import { EndpointTypes } from '@/shared/enums';
import {
    IAuthenticationResponse,
    IDeviceInfo,
    IEndpointConfiguration,
    IEndpointSettings,
    IEnterpriseSettings,
} from '@/shared/interfaces';
import {
    TEST_CONFIGURATION,
    TEST_DEVICE_DATA,
    TEST_DEVICE_INFO_DATA,
} from '@constants/tests';
export class SessionServiceMock {
    standbyState: boolean;
    private _deviceSerialNumber: string = null;
    private _firmwareVersion: string = null;
    _config: IEndpointConfiguration = TEST_CONFIGURATION;
    _data: IAuthenticationResponse = TEST_DEVICE_DATA;
    private _deviceInstance: AmwellTv | AmwellCart250 | AmwellCart500 = {
        standbyState: false,
        setWorksurfaceLights: () => null,
    } as unknown as AmwellCart250;

    getEndpoint(): IEndpointSettings {
        return this._data.endpoint;
    }
    set(data) {
        this._data = data;
    }
    get endpointType(): EndpointTypes {
        return this._data.endpoint.endpoint_type_id;
    }
    get isTVKit(): boolean {
        return true;
    }
    get isScreenSaverEnabled(): boolean {
        return true;
    }
    get isAutoAnswer(): boolean {
        return this._config ? this._config.auto_answer || false : false;
    }
    get environment(): string {
        return 'dev';
    }
    get accessToken(): string {
        return 'ONE_TOKEN';
    }
    public get fleetUrl(): string {
        return this._config.fleet_url;
    }
    get deviceId(): string {
        return this._data?.endpoint?.endpoint_device_id;
    }
    get enableFleetTimeout(): boolean {
        return this._data.endpoint.enable_fleet_timeout;
    }
    set deviceInstance(value: AmwellTv | AmwellCart250 | AmwellCart500) {
        this._deviceInstance = value;
    }
    get deviceInstance(): AmwellTv | AmwellCart250 | AmwellCart500 {
        return this._deviceInstance;
    }
    set accessToken(token: string) {}
    get allowCaseCreation(): boolean {
        return !this._config.disable_case_creation;
    }

    public get deviceInfo(): IDeviceInfo {
        return TEST_DEVICE_INFO_DATA;
    }

    public set deviceSerialNumber(serial: string) {
        this._deviceSerialNumber = serial;
    }

    public get deviceSerialNumber(): string {
        return this._deviceSerialNumber;
    }

    public set firmwareVersion(fwVersion: string) {
        this._firmwareVersion = fwVersion;
    }

    public get firmwareVersion(): string {
        return this._firmwareVersion;
    }

    getConfig(): IEndpointConfiguration {
        return this._config;
    }
    updateConfig(key: string, value: string | number | boolean): void {}

    getEnterprise(): IEnterpriseSettings {
        return this._data && this._data?.enterprise;
    }
    public get enterprise(): IEnterpriseSettings {
        return this._data && this._data?.enterprise;
    }
}

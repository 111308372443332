import { DEFAULT_DIALOGS_PANEL_CLASS } from '@/core/components/dialogs/dialogs-static.data';
import { DeviceService, SessionService } from '@shared/services';
import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

import { RollbackProgressDialogComponent } from '../rollback-progress-dialog/rollback-progress-dialog.component';
import { RollbackErrorDialogComponent } from '../rollback-error-dialog/rollback-error-dialog.component';
import { RollbackResponseService } from '@/shared/services';
import { updateSettingModalStatus } from '@/shared/storage';
import {
    RollbackStateEnums,
    RollbackSubSystemEnum,
    SecondsEnum,
} from '@/shared/enums';
import {
    IDeviceInfo,
    IRollBackModalDialogForm,
    IRollbackUpdateResponse,
} from '@/shared/interfaces';

import { StatisticCollector } from '@/shared/services/statistic-collector/statistic-collector.service';
import { TrackEventDataKeys } from '@/shared/services/statistic-collector/static-collector-defined-data';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { isAllowRollbackOs } from './rollback-dialog.util';

@Component({
    selector: 'app-rollback-dialog',
    styleUrls: ['./rollback-dialog.component.scss'],
    template: `
        <div class="sized-modal">
            <div class="modal-heading no-border">
                <h3 class="sized-title m-b0">Revert Software</h3>
            </div>
            <div class="modal-content" [formGroup]="form">
                <p class="sized-txt gray-mid-text">
                    Doing this will revert your device software to the back-up
                    version of the OS and/or Application based on your
                    selections below. This process can be used in the event that
                    you experience technical difficulties in an update.
                </p>
                <div class="rollback-grid-table">
                    <div class="rollback-grid-line">
                        <p>&nbsp;</p>
                        <p>Current Version</p>
                        <p>Back-Up Version</p>
                        <p>Revert?</p>
                    </div>
                    <div class="rollback-grid-line">
                        <p>App</p>
                        <p>{{ deviceInfo?.appVersion }}</p>
                        <p>{{ deviceInfo?.rollbackAppVersion }}</p>
                        <aw-checkbox
                            automationValue="checkboxApp"
                            formControlName="checkboxApp"
                            >&nbsp;</aw-checkbox
                        >
                    </div>
                    <ng-container *ngIf="allowRollbackOs">
                        <div class="rollback-grid-line">
                            <p>OS</p>
                            <p>{{ deviceInfo?.osVersion }}</p>
                            <p>{{ deviceInfo?.rollbackOsVersion }}</p>
                            <aw-checkbox
                                automationValue="checkboxOS"
                                formControlName="checkboxOS"
                                >&nbsp;</aw-checkbox
                            >
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="modal-footer flex justify-end pb2">
                <aw-button
                    (onClick)="closeModal()"
                    styleClasses="btn m-r2 btn-simple btn-large waves-effect waves-light"
                    >Cancel
                </aw-button>
                <aw-button
                    automationValue="revertSoftwareButton"
                    [disabled]="!(checkBoxChanged$ | async)"
                    (onClick)="rollbackSoftware()"
                    styleClasses="waves-effect waves-light btn btn-danger modal-trigger btn-large"
                >
                    Revert Software
                </aw-button>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RollbackDialogComponent implements OnInit, OnDestroy {
    public form: FormGroup;
    public deviceInfo: IDeviceInfo;
    public allowRollbackOs: boolean;

    public checkBoxChanged$ = new BehaviorSubject(false);

    private _rollbackAppStatus = null;
    private _rollbackOSStatus = null;
    private _showRollbackProgressDialogFlag = false;

    private _rollbackUpdateStateRefSub: Subscription;
    private _subs: Subscription[] = [];

    get isRollbackStatusComplete(): boolean {
        return (
            this._rollbackAppStatus === RollbackStateEnums.COMPLETE ||
            this._rollbackOSStatus === RollbackStateEnums.COMPLETE
        );
    }

    get isRollbackStatusError(): boolean {
        return (
            this._rollbackAppStatus === RollbackStateEnums.ERROR ||
            this._rollbackOSStatus === RollbackStateEnums.ERROR
        );
    }

    constructor(
        private _store: Store,
        private _formBuilder: FormBuilder,
        private _matDialog: MatDialog,
        private _matDialogRef: MatDialogRef<RollbackDialogComponent>,
        private _statisticCollectorService: StatisticCollector,
        private _deviceService: DeviceService,
        private _rollbackResponseService: RollbackResponseService,
        private _sessionService: SessionService
    ) {
        this.deviceInfo = this._sessionService.deviceInfo;
        this.allowRollbackOs = isAllowRollbackOs(this.deviceInfo);
    }

    ngOnInit() {
        this.initFormGroup();
        this.runSubscriptions();
    }

    private initFormGroup(): void {
        this.form = this._formBuilder.group({
            checkboxApp: new FormControl(false),
        });

        this.allowRollbackOs &&
            this.form.addControl('checkboxOS', new FormControl(false));
    }

    runSubscriptions(): void {
        this._rollbackUpdateStateRefSub = this._rollbackResponseService
            .observeRollbackUpdateState()
            .subscribe((values) => this.rollbackDataReceived(values));

        this._subs.push(
            this.form.valueChanges
                .pipe(debounceTime(100))
                .subscribe(
                    ({ checkboxApp, checkboxOS }: IRollBackModalDialogForm) => {
                        this.checkBoxChanged$.next(
                            !!checkboxApp || !!checkboxOS
                        );
                    }
                )
        );
    }

    rollbackDataReceived({ state, subsystem }: IRollbackUpdateResponse): void {
        const { COMPLETE, ERROR } = RollbackStateEnums;
        switch (state) {
            case COMPLETE:
                this.setUpRollbackStatus(subsystem, COMPLETE);
                this.handleRollbackComplete();
                break;
            case ERROR:
                this.setUpRollbackStatus(subsystem, ERROR);
                this.showRollbackErrorDialog();
                break;
        }
    }

    setUpRollbackStatus(
        subsystem: RollbackSubSystemEnum,
        status: RollbackStateEnums
    ): void {
        const { APPLICATION } = RollbackSubSystemEnum;
        subsystem === APPLICATION
            ? (this._rollbackAppStatus = status)
            : (this._rollbackOSStatus = status);
    }

    handleRollbackComplete(): void {
        if (this.isRollbackStatusComplete) {
            this._subs.push(
                timer(SecondsEnum.Four).subscribe(() => {
                    this._store.dispatch(
                        updateSettingModalStatus({ isOpenSettingModal: true })
                    );
                    this._matDialog.closeAll();
                })
            );
        }
    }

    rollbackSoftware(): void {
        const rollbackOsVersion: boolean = this.form.value?.checkboxOS;
        const rollbackAppVersion: boolean = this.form.value?.checkboxApp;
        this._deviceService.rollback(rollbackOsVersion, rollbackAppVersion);
        this._subs.push(
            timer(SecondsEnum.Two).subscribe(() => {
                this.closeModal();
                this.showRollbackProgressDialog();
            })
        );
    }

    closeModal(): void {
        this._matDialogRef.close();
    }

    showRollbackErrorDialog(): void {
        this._statisticCollectorService.trackEvent(
            TrackEventDataKeys.DialogErrorRollback
        );
        this._matDialog.closeAll();
        this._matDialog.open(
            RollbackErrorDialogComponent,
            DEFAULT_DIALOGS_PANEL_CLASS
        );
    }

    showRollbackProgressDialog() {
        if (
            !this._showRollbackProgressDialogFlag &&
            !this.isRollbackStatusError
        ) {
            this._showRollbackProgressDialogFlag = true;
            this._matDialog.closeAll();
            this._matDialog
                .open(
                    RollbackProgressDialogComponent,
                    DEFAULT_DIALOGS_PANEL_CLASS
                )
                .afterClosed()
                .subscribe(() =>
                    this._rollbackUpdateStateRefSub?.unsubscribe()
                );
        }
    }

    ngOnDestroy(): void {
        this._subs.forEach((s) => s?.unsubscribe());
    }
}

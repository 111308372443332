export const MESSAGES = {
    success: {
        text: 'Working',
        icon: 'check_circle',
    },
    error: {
        text: 'Please allow your permissions to video input.',
        icon: 'error_circle',
    },
    looselyConnectedMinrrayCameraError: {
        text: 'Your external PTZ camera isn’t connected properly. Please unplug it and then firmly plug it back in.',
        icon: 'error_circle',
    },
    cameraError: {
        text: 'No video input detected, check your camera is plugged in and turned on, or try restarting your device.',
        icon: 'error_circle',
    },
    microphoneError: {
        text: 'No audio input detected, check your microphone is plugged in and turned on, or try restarting your device.',
        icon: 'error_circle',
    },
    speakerError: {
        text: 'No audio output detected, check your speakers is plugged in and turned on, or try restarting your device.',
        icon: 'error_circle',
    },
    speakerWarning: {
        text: 'No audio output detected. Please make sure you hear the sound after press play button.',
        icon: 'warning',
    },
};

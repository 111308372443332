import {
    AudioVideoAllowedDevices,
    SettingsTabs,
    SettingsTabsMini,
} from '@shared/enums';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { StatisticCollector } from '@services/statistic-collector/statistic-collector.service';
import { SessionService } from '@/shared/services';
import { Store } from '@ngrx/store';
import { updateSettingModalStatus } from '@/shared/storage/layout/layout.actions';

@Component({
    selector: 'app-settings-modal',
    templateUrl: './settings-modal.component.html',
})
export class SettingsModalComponent implements OnInit, OnDestroy {
    public static dialogName = 'SettingsModalComponent';
    public activeTab = 'general';
    public endpointType: string;
    public settingsTabs: { [key: string]: string } = SettingsTabs;

    constructor(
        private _sessionService: SessionService,
        private _statisticCollector: StatisticCollector,
        private _store: Store
    ) {
        this.endpointType = this._sessionService.getEndpoint().endpoint_type_id;
    }

    ngOnInit() {
        if (
            !Object.values(AudioVideoAllowedDevices).includes(
                this.endpointType as AudioVideoAllowedDevices
            )
        ) {
            this.settingsTabs = SettingsTabsMini;
        }
    }

    activeTabChange(id: string): void {
        this.activeTab = id;

        if (id === 'general') {
            this._statisticCollector.saveEvent(
                'Settings_General',
                'Select General settings',
                'Select General settings via tab General_Settings modal dialog'
            );
            return;
        }

        if (id === 'av') {
            this._statisticCollector.saveEvent(
                'Settings_Audio/Video',
                'Select Audio/Video settings',
                'Select Audio/Video settings via tab Audio/Video_Settings modal dialog'
            );
            return;
        }

        if (id === 'connectivity') {
            this._statisticCollector.saveEvent(
                'Settings_Connectivity',
                'Select Connectivity settings',
                'Select Connectivity settings via tab Connectivity_Settings modal dialog'
            );
        }
    }

    originalOrder(): number {
        return 0;
    }

    closeAnalytic(): void {
        this._statisticCollector.saveEvent(
            'Settings_General',
            'Close settings',
            'Close settings via icon Cross_Settings modal dialog'
        );
    }

    ngOnDestroy() {
        this._store.dispatch(
            updateSettingModalStatus({ isOpenSettingModal: false })
        );
    }
}

import { Component, OnInit } from '@angular/core';

export const WAKE_UP_TIME = 5000;

@Component({
    selector: 'wake-up-screen',
    templateUrl: './wake-up-screen.component.html',
})
export class WakeUpScreenComponent implements OnInit {
    public isActive = false;
    public fontsLoading = true;

    ngOnInit(): void {
        this.show();
        (document as any).fonts.onloadingdone = () => {
            this.fontsLoading = false;
        };
    }

    show(): void {
        this.isActive = true;
        this.hide();
    }

    hide(): void {
        setTimeout(() => {
            this.isActive = false;
        }, WAKE_UP_TIME);
    }
}

export enum RollBackStatusEnums {
    STARTED = 'STARTED',
    COMPLETE = 'COMPLETE',
    ERROR = 'ERROR',
}

export enum RollBackCommandEnums {
    ROLLBACK = 'ROLLBACK',
}

export enum RollbackStateEnums {
    CHECKING = 'CHECKING',
    DOWNLOADING = 'DOWNLOADING',
    VALIDATING = 'VALIDATING',
    INSTALLING = 'INSTALLING',
    COMPLETE = 'COMPLETE',
    ERROR = 'ERROR',
}

export enum RollbackSubSystemEnum {
    APPLICATION = 'APPLICATION',
    OPERATING_SYSTEM = 'OPERATING_SYSTEM',
}

export enum RollbackOsVersionLimitation {
    Os = '2.3.0',
}

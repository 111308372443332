<footer class="sticky pt1 pb1">
    <div class="container fullwidth flex items-center justify-between">
        <div class="flex items-center">
            <aw-button
                styleClasses="btn btn-large btn-simple waves-effect waves-light left danger-text"
                automationValue="cancel"
                (onClick)="cancel()"
                [disabled]="cancelDisabled"
            >
                Cancel
            </aw-button>
            <span class="module-title">
                <span class="icon icon-{{ serviceLineTypeId }}-black" automation="serviceLineTypeId"></span>
                <span class="gray-mid-text" automation="serviceLineName">{{ serviceLineName }}</span>
            </span>
        </div>
        <div class="flex items-center">
            <ng-container *ngIf="!externalControlsRef; else externalControls">
                <aw-button
                    styleClasses="btn btn-large btn-simple waves-effect waves-light right pl2"
                    (onClick)="previous()"
                    [disabled]="previousDisabled"
                    automationValue="previous"
                >
                    <i class="material-icons">chevron_left</i>
                    Previous
                </aw-button>
                <aw-button
                    *ngIf="!isNextProgressButton"
                    styleClasses="btn btn-large waves-effect waves-light right m-l2 pr2"
                    [disabled]="nextDisabled"
                    automationValue="next"
                    awDebounceClick
                    (debounceClick)="next()"
                >
                    Next
                    <i class="material-icons m-0">chevron_right</i>
                </aw-button>
                <aw-button
                    *ngIf="isNextProgressButton"
                    progressive="true"
                    styleClasses="right m-l2 btn-large btn-primary"
                    (onClick)="next()"
                    automationValue="nextProgress"
                    [disabled]="nextDisabled"
                >
                    Next
                    <i class="material-icons m-0">chevron_right</i>
                </aw-button>
            </ng-container>
        </div>
    </div>
</footer>

<ng-template #externalControls>
    <ng-content></ng-content>
</ng-template>

// import {
//     INTMTVKIT_AUDIO_INPUT,
//     INTMTVKIT_AUDIO_OUTPUT,
// } from '@/shared/constants';
// import { IDevice } from '@/shared/interfaces';
import { Injectable } from '@angular/core';
import { MediaDevicesService } from '../media-devices';

@Injectable({ providedIn: 'root' })
export class IntmMediaDevicesService extends MediaDevicesService {
    //TODO - remove after Testing-
    // defaultVideoInput(): IDevice | { deviceId: number } {
    //     let isExists: IDevice;
    //     const userSelectedVideoInput = this._sessionService.videoInput;
    //     const videoInputs = this.getCameraInput();
    //     if (videoInputs && videoInputs.length > 0) {
    //         if (userSelectedVideoInput) {
    //             isExists = videoInputs.find(
    //                 (device) =>
    //                     device.label.indexOf(userSelectedVideoInput) >= 0
    //             );
    //         }
    //         return isExists || videoInputs[0];
    //     }
    //     this._logService.logError('Cannot find default video input');
    // }
    // defaultAudioInput(): IDevice {
    //     const audioInputs = this.audioInput();
    //     const userSelectedAudioInput = this._sessionService.audioInput;
    //     let isExists: IDevice;
    //     if (audioInputs && audioInputs.length > 0) {
    //         if (userSelectedAudioInput) {
    //             isExists = audioInputs.find((device) => {
    //                 return device.label.indexOf(userSelectedAudioInput) >= 0;
    //             });
    //         }
    //         if (!isExists) {
    //             isExists = audioInputs.find(
    //                 (device) =>
    //                     device.label.indexOf(INTMTVKIT_AUDIO_INPUT.label) >=
    //                         0 ||
    //                     device.label.indexOf(INTMTVKIT_AUDIO_INPUT.name) >= 0
    //             );
    //         }
    //         return isExists ? isExists : audioInputs[0];
    //     }
    //     this._logService.logError('Cannot find default audio input');
    // }
    // defaultAudioOutput(): IDevice {
    //     const audioOutputs = this.audioOutput();
    //     const userSelectedAudioOutput = this._sessionService.audioOutput;
    //     let isExists: IDevice;
    //     if (audioOutputs && audioOutputs.length > 0) {
    //         if (userSelectedAudioOutput) {
    //             isExists = audioOutputs.find((device) =>
    //                 device.label.indexOf(userSelectedAudioOutput)
    //             );
    //         }
    //         if (!isExists) {
    //             isExists = audioOutputs.find(
    //                 (device) =>
    //                     device.label.indexOf(INTMTVKIT_AUDIO_OUTPUT.label) >=
    //                         0 ||
    //                     device.label.indexOf(INTMTVKIT_AUDIO_OUTPUT.name) >= 0
    //             );
    //         }
    //         return isExists ? isExists : audioOutputs[0];
    //     }
    //     this._logService.logError('Cannot find default audio output');
    // }
}

export enum NetworkConnectionType {
    Ethernet = 'ETHERNET',
    Wifi = 'WIFI',
}

export enum NetworkConnectionState {
    Connected = 'CONNECTED',
}

export enum NetworkConnectionName {
    NetPlanEth0 = 'netplan-eth0',
    NetPlanEth1 = 'netplan-eth1',
}

export enum NetworkStorageKey {
    WifiHwAddress = 'wifiHwAddress',
    EthHwAddress = 'ethHwAddress',
}

export enum WifiIndicatorSize {
    Normal = 'normal-size',
    Default = '',
}

import { Injectable } from '@angular/core';
import { SessionService } from '../session/session.service';
import { IIpcResponseHandler } from '../../interfaces';
import { IpcObserverService } from './ipc-observer.service';
import { StorageService } from '../storage/storage.service';
import { SYSTEM_COMMANDS_TYPES } from '@/shared/constants/system-command';

@Injectable({ providedIn: 'root' })
export class SystemInfoResponseService implements IIpcResponseHandler {
    constructor(
        private observableService: IpcObserverService,
        private _sessionService: SessionService,
        private _storageService: StorageService
    ) {}
    handleResponse(response): void {
        const { system_info: systemInfoResponse } = response;
        const { command } = systemInfoResponse;
        switch (command) {
            case SYSTEM_COMMANDS_TYPES.GET_SYSTEM_INFO:
                this.setDeviceInfo(systemInfoResponse.system_info);
                this.observableService.notifyChanges({
                    command: SYSTEM_COMMANDS_TYPES.GET_SYSTEM_INFO,
                    data: response,
                });
                break;
            default:
                break;
        }
    }

    setDeviceInfo(systemInfoResponse): void {
        const [deviceId, secretKey, enterpriseCode, environment] =
            systemInfoResponse.qrcode.split('|');
        let env = environment.split(':')[1];
        env = env.includes('dynamic-') ? 'devbento' : env;
        this._storageService.updateDeviceInfo({
            osVersion: systemInfoResponse.os_version,
            appVersion: systemInfoResponse.codec_version,
            rollbackAppVersion: systemInfoResponse.rollback_codec_version,
            rollbackOsVersion: systemInfoResponse.rollback_os_version,
            deviceId,
            environment: env,
            enterpriseCode,
            secretKey,
            endpointSerialNumber: systemInfoResponse.device_id,
            deviceType: systemInfoResponse.device_type,
        });
    }
}

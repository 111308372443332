import { Injectable } from '@angular/core';
import { SessionService } from '..';
import {
    DEFINED_OPTIONAL_VALUE,
    DEFINED_TRACKING_CODE,
    DEFINED_TRACK_EVENT_NAME,
    TrackEventDataKeys,
    TRACK_EVENT_MAP_DATA,
} from './static-collector-defined-data';

declare global {
    interface Window {
        _paq: any;
    }
}

@Injectable({ providedIn: 'root' })
export class StatisticCollector {
    private offlineEvents = [];

    constructor(private sessionService: SessionService) {
        this.trackPageView();
    }

    public saveEvent(
        category: string,
        action: string,
        optionalName: string,
        optionalValue = 0
    ) {
        window._paq.push([
            'trackEvent',
            category,
            action,
            optionalName,
            optionalValue,
        ]);
    }

    public trackEvent(trackEventDataKey: TrackEventDataKeys): void {
        if (!TRACK_EVENT_MAP_DATA.has(trackEventDataKey)) {
            return;
        }

        window._paq.push([
            DEFINED_TRACK_EVENT_NAME,
            ...TRACK_EVENT_MAP_DATA.get(trackEventDataKey),
            DEFINED_OPTIONAL_VALUE,
        ]);
    }

    public saveOfflineEvent(
        category: string,
        action: string,
        optionalName: string,
        optionalValue = 0
    ) {
        this.offlineEvents.push([
            'trackEvent',
            category,
            action,
            optionalName,
            optionalValue,
        ]);
    }

    public executeOfflineEvents() {
        this.offlineEvents.forEach((item) => {
            window._paq.push([...item]);
        });
        this.offlineEvents = [];
    }

    /**
     * Send tracking request to Matomo
     */
    public trackPageView() {
        const _paq = (window._paq = []);
        const u = '//analytics.avizia.com/';
        _paq.push(['setTrackerUrl', u + 'matomo.php']);
        const d = document,
            g = d.createElement('script'),
            s = d.getElementsByTagName('script')[0];
        g.type = 'text/javascript';
        g.async = true;
        g.defer = true;
        g.src = u + 'matomo.js';
        s.parentNode.insertBefore(g, s);
        const userId = this.generateUserId();
        const setSiteId =
            this.sessionService.trackingCode || DEFINED_TRACKING_CODE;
        let currentUrl = location.href;
        setSiteId && _paq.push(['setSiteId', setSiteId]);
        userId && _paq.push(['setUserId', userId]);
        _paq.push(['setReferrerUrl', currentUrl]);
        currentUrl = '' + window.location.hash.substr(1);
        _paq.push(['setCustomUrl', currentUrl]);
        _paq.push(['setDocumentTitle', document.domain + '/' + document.title]);
        // remove all previously assigned custom variables, requires Piwik 3.0.2
        _paq.push(['deleteCustomVariables', 'page']);
        _paq.push(['setPagePerformanceTiming', 0]);
        _paq.push(['trackPageView']);
        // make Piwik aware of newly added content
        const content = document.getElementById('appBody');
        _paq.push(['MediaAnalytics::scanForMedia', content]);
        _paq.push(['FormAnalytics::scanForForms', content]);
        _paq.push(['trackContentImpressionsWithinNode', content]);
        _paq.push(['enableLinkTracking']);
    }

    private generateUserId(): string | null {
        try {
            const endpoint = this.sessionService.getEndpoint();
            const facility_name = endpoint?.facility_name || '';
            const name = endpoint?.name || '';
            let hardware;
            switch (endpoint?.endpoint_type_id) {
                case 'APGARCODEC':
                    hardware = 'American Well 250';
                    break;
                case 'INTMTVKIT':
                    hardware = 'American Well TV Kit';
                    break;
                default:
                    hardware = '';
                    break;
            }
            const generatedUserId = `${name} - ${facility_name} - ${hardware}`;
            return generatedUserId || null;
        } catch (err: any) {
            // eslint-disable-next-line no-console
            console.log(err);
        }
        return null;
    }
}

import { IVideoCall, IVideoCallSharingDevice } from '@/shared/interfaces';
import { Action, createReducer, on } from '@ngrx/store';
import * as VideoActions from './video-info.actions';

const videoInfoInitialState: IVideoCall = {
    isSharing: false,
};

const defaultVideoCallSharingDevice: IVideoCallSharingDevice = {
    deviceId: '',
    title: '',
    hasVideo: false,
    hasAudio: false,
    isStethoscope: false,
    isRNKStethoscope: false,
};

const reducer = createReducer(
    videoInfoInitialState,
    on(VideoActions.clearVideoCallInfo, () => ({
        ...videoInfoInitialState,
    })),
    on(VideoActions.startSharing, (state, payload) => ({
        ...state,
        isSharing: true,
        sharingDevice: payload.sharingDevice,
    })),
    on(VideoActions.endSharing, (state, payload) => ({
        ...state,
        isSharing: false,
        sharingDevice: payload.overWriteShareDevice
            ? defaultVideoCallSharingDevice
            : state.sharingDevice,
    })),
    on(VideoActions.startRecording, (state) => ({
        ...state,
        isRecording: true,
    })),
    on(VideoActions.endRecording, (state) => ({
        ...state,
        isRecording: false,
    })),
    on(VideoActions.TakeSnapshot, (state) => ({
        ...state,
        takeSnapshot: true,
    })),
    on(VideoActions.EndShapshot, (state) => ({
        ...state,
        takeSnapshot: false,
    })),
    on(VideoActions.allowSnapshot, (state, payload) => ({
        ...state,
        allowSnapshot: payload.allowSnapshot,
    })),
    on(VideoActions.allowStethRecording, (state, payload) => ({
        ...state,
        allowStethRecording: payload.allowStethRecording,
    }))
);

export function VideoInfoReducer(
    state: IVideoCall | undefined,
    action: Action
): IVideoCall {
    return reducer(state, action);
}

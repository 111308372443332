import { SharedModule } from '@/shared/shared.module';
import { NgModule } from '@angular/core';
import { ErrorGenericRoutingModule } from './error-generic.routing';
import { ErrorGenericPageComponent } from './pages/error-generic-page/error-generic.component';

@NgModule({
    declarations: [ErrorGenericPageComponent],
    imports: [ErrorGenericRoutingModule, SharedModule],
})
export class ErrorGenericModule {}

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { INetworkDevice, INetworkStatuses } from '@shared/interfaces';
import { AddressProtocolType, NetworkTypes, StatusTypes } from '@/shared/enums';
import { CONNECTION_ERRORS } from '@/shared/constants';
import { IpcService } from '@/shared/services';
import { SnackbarService } from '@aw-hospital/aw-components-lib/src/services/snackbar';
import { catchError, first } from 'rxjs/operators';
import { snackConfig } from '@/shared/constants/snack-bar';
import { getWiredConfigForm } from './wired.utils';
import { getConnectionMethods } from '@/shared/constants/connection';
import { Observable, of } from 'rxjs';
import { LoggerService } from '@/shared/services/logger';

@Component({
    selector: 'app-wired',
    templateUrl: './wired.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WiredComponent implements OnInit {
    public addDNS = false;
    public joinWifiStatus = '';
    public wiredConfigForm: FormGroup = getWiredConfigForm;
    public ethernetConnected = true;
    public joinEthernetStatus = '';
    public connectionMethods: any = getConnectionMethods;
    public addressProtocolTypeStatic = AddressProtocolType.STATIC;
    public joinStaticIPStatus = false;

    private defaultStaticIpSetting: any = {};
    private _staticIpSetting: any = {};
    private _isDefaultDnsChanged = false;
    private _staticIpSettingForm: FormGroup;

    public isValidToJoin = false;
    @Input() public ethernetStatuses: INetworkStatuses;
    @Input() public ethernetStatus: INetworkDevice;

    constructor(
        private _ipcService: IpcService,
        private _snackbarService: SnackbarService,
        private _loggerService: LoggerService,
        private _cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.getNetworkConfig();
    }

    public joinViaStaticIP(): void {
        this.joinEthernetStatus = '';
        this.joinStaticIPStatus = true;
    }

    public leaveJoinViaStaticIP(): void {
        this.joinStaticIPStatus = false;
    }

    private validateToJoinEthernet(): void {
        if (
            this.wiredConfigForm &&
            this.wiredConfigForm.value.connectionMethod ===
                AddressProtocolType.STATIC
        ) {
            this.isValidToJoin =
                this.wiredConfigForm.valid &&
                this._staticIpSettingForm &&
                this._staticIpSettingForm.valid;
        } else {
            this.isValidToJoin =
                this.wiredConfigForm && this.wiredConfigForm.valid;
        }

        this._cdr.detectChanges();
    }

    public onChangeConnectionMethod(): void {
        if (
            this.wiredConfigForm.controls['connectionMethod'].value ===
            AddressProtocolType.STATIC
        ) {
            this.joinStaticIPStatus = true;
        }
        this.validateToJoinEthernet();
    }

    public onStaticIpSettingChange(formGroup: FormGroup): void {
        const {
            value: { address, gateway, prefix },
        } = formGroup;
        this._staticIpSetting.address = address;
        this._staticIpSetting.gateway = gateway;
        this._staticIpSetting.prefix = prefix;
        this._staticIpSettingForm = formGroup;
        this.validateToJoinEthernet();
    }

    /*** all newlt added changes */

    private getNetworkConfig(): void {
        this._ipcService
            .requestGetNetworkConfig('')
            .pipe(first(), catchError(this.handleError))
            .subscribe((response) => {
                const { ipv4 = {} } = response || {};
                this.defaultStaticIpSetting = ipv4;
                const { address_protocol: addressProtocol } =
                    this.defaultStaticIpSetting;
                this.wiredConfigForm
                    .get('connectionMethod')
                    .patchValue(addressProtocol);

                this._cdr.markForCheck();
            });
    }

    public isDnsChanged(isDefaultDnsChanged: boolean) {
        this._isDefaultDnsChanged = isDefaultDnsChanged;
        this.validateToJoinEthernet();
    }

    public staticIpConfigChangeDetected(): boolean {
        const {
            address = '',
            prefix = '',
            gateway = '',
            address_protocol = AddressProtocolType.DHCP,
        } = this.defaultStaticIpSetting;
        const {
            address: newAddress = '',
            prefix: newPrefix = '',
            gateway: newGateway = '',
        } = this._staticIpSetting || {};
        const connectionMethod = this.wiredConfigForm.value.connectionMethod;
        return !(
            address_protocol === connectionMethod &&
            address === newAddress &&
            prefix === newPrefix &&
            gateway === newGateway
        );
    }

    public joinEthernetNetwork(): void {
        if (this._isDefaultDnsChanged && !this.staticIpConfigChangeDetected()) {
            this.addDNS = true;
            return;
        } else if (!this.staticIpConfigChangeDetected()) {
            // We close previous snackbar, and avoid multiple snackbar with the same content
            this._snackbarService.closeAll();
            // config already exists
            this._snackbarService.show(
                snackConfig(CONNECTION_ERRORS.CONFIG_ALREADY_EXISTS_ERROR)
            );
        } else {
            this.joinEthernetStatus = 'Connecting...';
            this.setEthernetIpConfig();
            this.addDNS = true;
        }
    }

    private setEthernetIpConfig(): void {
        const { address, gateway, prefix } = this._staticIpSetting;
        const input = {
            connection_type: NetworkTypes.ETHERNET,
            address_protocol: this.wiredConfigForm.value.connectionMethod,
            ipv4:
                this.wiredConfigForm.value.connectionMethod ===
                AddressProtocolType.STATIC
                    ? { address, gateway, prefix }
                    : {},
        };

        this._ipcService
            .requestSetIpConfig(input)
            .pipe(first())
            .pipe(catchError(this.handleError))
            .subscribe((result) => {
                if (result) {
                    this.connectToEthernetOnDataReceived(result);
                }
            });
    }

    private connectToEthernetOnDataReceived(res): void {
        const { state } = res;
        if (state === StatusTypes.CONNECTED) {
            this._ipcService
                .isOnline({
                    component: 'Wired Block - Connection Settings Modal',
                    message: 'CONNECT_TO_ETHERNET',
                })
                .pipe(first())
                .pipe(catchError(this.handleError))
                .subscribe((data) => {
                    const { success: isInternetConnected } = data;
                    if (isInternetConnected) {
                        this.ethernetConnected = true;
                        this.joinEthernetStatus = 'CONNECTED';
                    } else {
                        this.joinEthernetStatus = '';
                        this._snackbarService.show(
                            snackConfig(CONNECTION_ERRORS.UNKNOWN_ERROR)
                        );
                    }
                });
            // this.leaveJoinViaStaticIP(); // TODO: Wait QA test to uncomment
        } else {
            this.joinEthernetStatus = '';
            this._snackbarService.show(
                snackConfig(CONNECTION_ERRORS.UNKNOWN_ERROR)
            );
        }
    }

    handleError(err): Observable<any> {
        this._loggerService.error('WiredComponent', err);
        return of({});
    }
}

import { Store } from '@ngrx/store';
import { Component } from '@angular/core';
import { selectDeviceOnScreenKeyboardOpened } from '@/shared/storage/selectors/device-selectors';

@Component({
    selector: 'full-layout',
    templateUrl: './full.component.html',
})
export class FullLayoutComponent {
    public isKeyboardOpened$ = this._store.select(
        selectDeviceOnScreenKeyboardOpened
    );

    constructor(private _store: Store) {}
}

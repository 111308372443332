import { LEAVE_CASE_OPEN_QUEUE } from '@/shared/constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfirmModalComponent } from '@core/components';
import { Observable } from 'rxjs';
import { IActiveModal } from './../../storage/layout/layout.reducer';

@Injectable({
    providedIn: 'root',
})
export class CaseService {
    constructor(private _http: HttpClient) {}

    createCase(rawIntakeData): Observable<any> {
        return this._http.post(
            'v10/case',
            this.rawToV10IntakeData(rawIntakeData)
        );
    }

    rawToV10IntakeData(rawIntakeData) {
        const v10IntakeData = {
            patientLastName: null,
            patientFirstName: null,
            referringNotes: null,
            sex: null,
            mrn: null,
            callback: null,
        };

        Object.keys(rawIntakeData).forEach((k) => {
            const value = rawIntakeData[k];
            switch (k) {
                case 'lastName':
                    v10IntakeData.patientLastName = value;
                    break;
                case 'firstName':
                    v10IntakeData.patientFirstName = value;
                    break;
                case 'notes':
                    v10IntakeData.referringNotes = value;
                    break;
                case 'gender':
                    v10IntakeData.sex = value;
                    break;
                case 'mrnId':
                    v10IntakeData.mrn = value;
                    break;
                default:
                    v10IntakeData[k] = value;
                    break;
            }
        });

        // The ocg form calls the callback phone field 'phone', so copy that into 'callback' for saving to sites.
        if (rawIntakeData.phone && !v10IntakeData.callback) {
            v10IntakeData.callback = rawIntakeData.phone;
        }

        return v10IntakeData;
    }

    assignCaseWithProvider(
        caseId: any,
        providerId: any,
        workflowId: any
    ): Observable<any> {
        return this._http.post('v10/assign_case', {
            case_id: caseId,
            assigned_to: providerId,
            workflow_id: workflowId,
        });
    }

    cancelCase(
        caseId: string | number,
        workflowId: string | number
    ): Observable<any> {
        return this._http.post('v10/cancel_case', {
            case_id: caseId,
            workflow_id: workflowId,
        });
    }

    getConfirmCancelCaseModal(serviceLineName: string) {
        return {
            componentName: ConfirmModalComponent.dialogName,
            config: {
                data: {
                    icon: 'assignment',
                    title: 'Cancel Case',
                    body: `<p>Are you sure you would like to cancel this new ${serviceLineName} case?</p>`,
                    confirmText: 'Cancel Case',
                    cancelText: 'Continue Working',
                    primaryBtnStyle: 'danger',
                    showCloseIcon: true,
                },
                panelClass: ['aw-modal', 'modal-lg'],
            },
            setProp(
                key: keyof IActiveModal,
                value: IActiveModal[keyof IActiveModal]
            ) {
                this[key] = value;

                return this;
            },
        };
    }

    getConfirmLeaveCaseOpenModal() {
        return {
            componentName: ConfirmModalComponent.dialogName,
            config: {
                data: {
                    title: 'Leave Case Open',
                    body: `<p>Are you sure you want to leave this case open and
                        create a new case? To see cases already created, you can navigate to the Clinical Portal.</p>`,
                    confirmText: 'Leave Case Open',
                    primaryBtnStyle: 'danger',
                    showCloseIcon: true,
                },
                panelClass: ['aw-modal', 'modal-md', 'leave-case-open'],
            },
            setProp(
                key: keyof IActiveModal,
                value: IActiveModal[keyof IActiveModal]
            ) {
                this[key] = value;

                return this;
            },
        };
    }

    getLeaveCaseOpenSuccessSnackbar() {
        return {
            queue: LEAVE_CASE_OPEN_QUEUE,
            style: 'success',
            description: `You successfully created a case and the notifications have been routed
                to the appropriate providers. Please navigate to the Clinical Portal if you’d like to review.`,
        };
    }
}

import { NgModule } from '@angular/core';
import { SharedModule } from '@/shared/shared.module';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { CoreRoutingModule } from './core.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
    ApiUrlInterceptor,
    ErrorInterceptor,
    JwtInterceptor,
    InternetInterceptor,
} from './interceptors';

import {
    NetworkIndicatorComponent,
    EndpointPhotoComponent,
    LowBatteryModalComponent,
    MenuComponent,
    ConfirmModalComponent,
    WakeUpScreenComponent,
    RollbackDialogComponent,
    RollbackErrorDialogComponent,
    RollbackProgressDialogComponent,
    TurnOffDialogComponent,
    RestartDialogComponent,
    FactoryDefaultDialogComponent,
} from './components';

import {
    FullLayoutComponent,
    EmptyLayoutComponent,
    LightLayoutComponent,
    TVKitLayoutComponent,
    EmptyDashboardLayout,
} from './layouts';

import { SettingsModalComponent } from '@/settings/settings-modal/settings-modal.component';
import { SettingsModule } from '@/settings/settings.module';
import { SettingsPinModalComponent } from '@/settings/settings-pin-modal/settings-pin-modal.component';
import { LostNetworkModalComponent } from './components/lost-network-modal/lost-network-modal.component';
import { CommonModule } from '@angular/common';
import { ErrorGenericModule } from '@/error-generic';

@NgModule({
    declarations: [
        FullLayoutComponent,
        EmptyLayoutComponent,
        LightLayoutComponent,
        TVKitLayoutComponent,
        EmptyDashboardLayout,
        NetworkIndicatorComponent,
        EndpointPhotoComponent,
        LowBatteryModalComponent,
        MenuComponent,
        ConfirmModalComponent,
        WakeUpScreenComponent,
        SettingsModalComponent,
        SettingsPinModalComponent,
        LostNetworkModalComponent,
        RollbackDialogComponent,
        RollbackErrorDialogComponent,
        RollbackProgressDialogComponent,
        // Dialogs
        TurnOffDialogComponent,
        FactoryDefaultDialogComponent,
        RestartDialogComponent,
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        SharedModule,
        CommonModule,
        RouterModule,
        CoreRoutingModule,
        SettingsModule,
        ErrorGenericModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiUrlInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InternetInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
    ],
    exports: [SharedModule, MatDialogModule, WakeUpScreenComponent],
})
export class CoreModule {}

import { DeviceBase } from './device-base';
import {
    AUDIO_DEVICE_TYPE,
    FECC_DEVICES,
    FECC_DEVICE_NAMES,
    FILE_PATH_OS,
} from '@constants';
import { IBatteryStatus } from '../interfaces';

// eslint-disable-next-line import/no-extraneous-dependencies
import meanBy from 'lodash/meanBy';
import { first } from 'rxjs/operators';
import { selectVideoInputsOrder } from '../storage/selectors';

const C500_AUDIO_CONTROL_MAX_VOLUME = 65536;

export class AmwellCart500 extends DeviceBase {
    private _connectedVendorId: '';
    private _connectedProductId: '';
    private _done: any;
    private _isMinrrayFileCreated = true;
    private _isFileExists = false;
    private _MinrrayFileContent = 'FileCreated=true';
    private _listOfPreviousUsbDevice = [];
    private _is4xCameraAvailable = true;

    init(): void {
        if (this._done) {
            return;
        } // Onboot can be called more than once,  check already executed
        this._done = true;
        super.init();
        this.setWorksurfaceLights();
        this._ipcService.requestSystemInfoForCart500();
        this._ipcService.setAudioControlMaxVolume(
            C500_AUDIO_CONTROL_MAX_VOLUME,
            AUDIO_DEVICE_TYPE.SINK
        );

        // check file is availabble or not
        this._ipcService
            .requestReadFile(FILE_PATH_OS.MINRRAY_CAMERA)
            .pipe(first())
            .subscribe((fileData) => {
                if (!fileData.file?.contents) {
                    this._ipcService
                        .requestWriteFile(
                            FILE_PATH_OS.MINRRAY_CAMERA,
                            this._MinrrayFileContent
                        )
                        .pipe(first())
                        .subscribe(() => {
                            this._isFileExists = true;
                        });
                } else {
                    this._isFileExists = true;
                    this._isMinrrayFileCreated = false;
                    this._MinrrayFileContent = fileData.file?.contents;
                    this.setMinrrayCameraConnected();
                }
            });
    }

    setWorksurfaceLights(): void {
        if (this._sessionService.getConfig().worksurface_lights) {
            if (
                this._sessionService.getConfig().worksurface_lights_selected ===
                'Automatic'
            ) {
                this._ipcService.setLEDWorkSurfaceAuto();
            } else if (
                this._sessionService.getConfig().worksurface_lights_selected ===
                'Always On'
            ) {
                this._ipcService.setLEDWorkSurfaceOn();
            }
        } else {
            this._ipcService.setLEDWorkSurfaceOff();
        }
    }

    onConfigUpdate(): void {
        // Config Changed
        // ignore if cart in in standby state
        if (!this._standbyState) {
            this.setWorksurfaceLights();
        }
        this.setCodecVersion();
    }

    setCodecVersion(): void {
        if (!this._usbDevices || this._usbDevices.length <= 0) {
            return;
        }
        let eptz = FECC_DEVICES.filter(
            (d) => d.name === FECC_DEVICE_NAMES.EPTZ_CAMERA
        )[0];

        this._is4xCameraAvailable = this._usbDevices.find((device) => {
            return (
                eptz.productId === device.pid &&
                eptz.vendorId === device.vid &&
                eptz.v4lName.indexOf(device.product) > -1
            );
        });

        eptz = !!this._is4xCameraAvailable ? eptz : null;

        const usrSelVideoInput = this._sessionService.getConfig()?.VideoInput;
        let device = this._usbDevices.find(
            (d) =>
                usrSelVideoInput &&
                d.product &&
                usrSelVideoInput.indexOf(d.product) > -1
        );

        !device &&
            selectVideoInputsOrder.find(
                (name) =>
                    (device = this._usbDevices.find(
                        (d) => d?.product?.indexOf(name) >= 0
                    ))
            );

        const feccCamera =
            !!device &&
            FECC_DEVICES.find(
                (d) =>
                    d.productId === device.pid &&
                    d.vendorId === device.vid &&
                    d.v4lName.indexOf(device.product) > -1
            );

        eptz = !!feccCamera ? feccCamera : eptz;

        const ptzDevice = {
            camera_connected: +!!this._is4xCameraAvailable,
            codec_ver: eptz?.codecVersion,
        };

        !!eptz && this._ptzService.changeCamera(eptz);
        this._metricService.enqueue(ptzDevice);
        this._loggerService.info('setCodecVersion', JSON.stringify(ptzDevice));
        this.setMinrrayCameraConnected();
    }

    // if endpoint registerd and very first time starting but minrray camera is not attached then send connected = 1
    // if endpoint registerd and very first time starting but minrray camera is  attached then send connected = 1
    // if device rebboot and  minrray camera is not attached then send connected = 0
    // if device rebboot and  minrray camera is  attached then send connected = 1
    // if minrray camera is plugged in then send connected = 1
    // if minrray camera is unplugged  then send connected = 0
    // on factory default have to delete stored file

    setMinrrayCameraConnected() {
        if (!this._isFileExists) {
            return;
        }
        if (!this._usbDevices || this._usbDevices.length === 0) {
            return;
        }

        if (!this._is4xCameraAvailable) {
            return;
        }

        const minrrayCamera = FECC_DEVICES.filter(
            (d) => d.name === FECC_DEVICE_NAMES.MINRRAY_CAMERA
        );

        const ptzMinrrayDevice = {
            camera_connected: +false,
        };
        // is any minrray camera attached to device
        for (const device of this._usbDevices) {
            const feccVideoInput = minrrayCamera.find(
                (d) =>
                    d.productId === device.pid &&
                    d.vendorId === device.vid &&
                    d.v4lName.indexOf(device.product) > -1
            );
            if (feccVideoInput) {
                ptzMinrrayDevice.camera_connected = +true;
                break;
            }
        }

        const fileContent = 'MinrrayConnected=true';
        if (ptzMinrrayDevice.camera_connected) {
            // check minrray camera attached and selected for video call
            this._metricService.enqueue(ptzMinrrayDevice);
            this._loggerService.info(
                'setCodecVersion',
                JSON.stringify(ptzMinrrayDevice)
            );
            // if first time connected minrray then store in file

            if (this._MinrrayFileContent !== fileContent) {
                this._ipcService
                    .requestWriteFile(
                        FILE_PATH_OS.MINRRAY_CAMERA,
                        'MinrrayConnected=true'
                    )
                    .pipe(first())
                    .subscribe(() => {
                        this._MinrrayFileContent = fileContent;
                    });
            }
        } else if (
            !this._isMinrrayFileCreated &&
            fileContent === this._MinrrayFileContent
        ) {
            this._metricService.enqueue(ptzMinrrayDevice);
            this._loggerService.info(
                'setCodecVersion',
                JSON.stringify(ptzMinrrayDevice)
            );
        }

        !!this._listOfPreviousUsbDevice &&
            this.setMinrrayCameraDisconnected(minrrayCamera);
    }
    setMinrrayCameraDisconnected(minrrayCamera) {
        // To check if any device get deattached
        if (this._listOfPreviousUsbDevice.length === 0) {
            this._listOfPreviousUsbDevice = [...this._usbDevices];
        } else if (
            this._usbDevices.length !== this._listOfPreviousUsbDevice.length
        ) {
            const minrrayFromPreviousDeviceList =
                this._listOfPreviousUsbDevice.filter((device) => {
                    return minrrayCamera.find(
                        (d) =>
                            d.productId === device.pid &&
                            d.vendorId === device.vid &&
                            d.v4lName.indexOf(device.product) > -1
                    );
                });

            const listOfDisconnectedDivice =
                minrrayFromPreviousDeviceList.filter((device) => {
                    return !this._usbDevices.find(
                        (d) =>
                            d.pid === device.pid &&
                            d.pid === device.pid &&
                            d.product === device.product
                    );
                });

            listOfDisconnectedDivice.forEach(() => {
                const ptzMinrrayDisDevice = { camera_connected: 0 };
                this._metricService.enqueue(ptzMinrrayDisDevice);
                this._loggerService.info(
                    'setCodecVersion',
                    JSON.stringify(ptzMinrrayDisDevice)
                );
            });
            this._listOfPreviousUsbDevice = this._usbDevices;
        }
    }

    getDefaultDevice(device, deviceType): any {
        let defaultDevice = null;
        if (typeof device === 'string') {
            defaultDevice = this.audioDevices.find(
                (a) => a.device_type === deviceType && a.description === device
            );
        } else {
            defaultDevice = this.audioDevices.find(
                (d) =>
                    d.vid === device.vendorId &&
                    d.pid === device.productId &&
                    d.device_type === deviceType
            );
        }

        return defaultDevice;
    }

    setMicGain(input): void {
        // This condition for RemoteAVSettings from Clinical Module.
        // We are taking the previously set Vendor & ProductId since we dont get it through fleet
        /*********/
        if (!input.vendorId) {
            input.vendorId = this._connectedVendorId;
        }
        if (!input.productId) {
            input.productId = this._connectedProductId;
        }
        /*********/

        const defaultDevice = this.getDefaultDevice(
            input,
            AUDIO_DEVICE_TYPE.SOURCE
        );
        if (defaultDevice) {
            defaultDevice.volume =
                input.volume >= 0
                    ? input.volume
                    : this._sessionService.getConfig().AudioInputDefaultGain !==
                      undefined
                    ? this._sessionService.getConfig().AudioInputDefaultGain
                    : 50;
            super.setMicGain(defaultDevice);
        }

        this._connectedProductId = input.productId;
        this._connectedVendorId = input.vendorId;
    }

    getAudioControls() {
        return super.getAudioControls();
    }

    requestAudioControls() {
        return super.requestAudioControls();
    }

    setPeripheralGain(peripheralGainData): void {
        super.setPeripheralGain(peripheralGainData);
    }

    setSpeakerGain(input): void {
        // This condition for RemoteAVSettings from Clinical Module.
        // We are taking the previously set Vendor & ProductId since we dont get it through fleet
        /*********/
        if (!input.vendorId) {
            input.vendorId = this._connectedVendorId;
        }
        if (!input.productId) {
            input.productId = this._connectedProductId;
        }
        /*********/
        const defaultDevice = this.getDefaultDevice(
            input,
            AUDIO_DEVICE_TYPE.SINK
        );
        if (defaultDevice) {
            defaultDevice.volume = input.volume;
            super.setSpeakerGain(defaultDevice);
        }

        this._connectedProductId = input.productId;
        this._connectedVendorId = input.vendorId;
    }

    setAudioDefaultSink(device) {
        const defaultDevice = this.getDefaultDevice(
            device,
            AUDIO_DEVICE_TYPE.SINK
        );
        if (defaultDevice) {
            const { ctrl_name: ctrlName } = defaultDevice;
            this._ipcService.setAudioDefaultSink(ctrlName);
        }
    }

    setAudioDefaultSource(device) {
        const defaultDevice = this.getDefaultDevice(
            device,
            AUDIO_DEVICE_TYPE.SOURCE
        );
        if (defaultDevice) {
            const { ctrl_name: ctrlName } = defaultDevice;
            this._ipcService.setAudioDefaultSource(ctrlName);
        }
    }

    handleBatteryResponse(response): IBatteryStatus {
        const powerStatus: IBatteryStatus = {};
        if (response.batteries && response.batteries.length > 0) {
            // take average of all batteries and round off
            powerStatus.level = Math.round(
                meanBy(response.batteries, (battery: any) => {
                    if (battery.name) {
                        return isNaN(parseInt(battery.percent_charge, 0))
                            ? 0
                            : parseInt(battery.percent_charge, 0);
                    }
                })
            );
            // round off battery 97% and above to 100%
            if (powerStatus.level > 95) {
                powerStatus.level = 100;
            }
            powerStatus.batteryConnected = true;
        } else {
            powerStatus.batteryConnected = false;
        }

        powerStatus.acPowerConnected =
            response.ac_connected === 'true' ? true : false;

        const batteryMetric = {
            ac_power_connected: Number(powerStatus.acPowerConnected),
            battery_connected: Number(powerStatus.batteryConnected),
            battery: powerStatus.level,
        };

        this._metricService.enqueue(batteryMetric);
        this._loggerService.log(
            'BATTERY_METRIC',
            JSON.stringify(batteryMetric)
        );
        return powerStatus;
    }

    changeCodecVersion(device): void {
        const eptz = FECC_DEVICES.find((d) => d.name === 'EPTZ Camera');
        const ptzDevice = {
            camera_connected: +true,
            codec_ver: eptz.codecVersion,
        };

        // converting hexadecimal and compare
        const feccCamera = FECC_DEVICES.find(
            (d) =>
                parseInt(d.productId, 16) === parseInt(device.productId, 16) &&
                parseInt(d.vendorId, 16) === parseInt(device.vendorId, 16) &&
                device.label.toLowerCase().indexOf(d.v4lName.toLowerCase()) > -1
        );
        if (feccCamera) {
            ptzDevice.codec_ver = feccCamera.codecVersion;
            this._ptzService.changeCamera(feccCamera);
        }
        this._metricService.enqueue(ptzDevice);
    }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-rollback-error-dialog',
    template: `
        <div class="sized-modal">
            <div
                class="modal-content flex justify-center items-center flex-column"
            >
                <span
                    class="icon icon-manually-fail-black lg-icon layered m-b2"
                >
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                </span>
                <h3 class="sized-title text-center">
                    Failed to apply new settings.
                </h3>
                <p class="sized-txt text-center gray-mid-text">
                    We experienced an issue while updating your device. Please
                    try again or contact your local IT Admin.
                </p>
            </div>
            <div class="modal-footer flex justify-end pb2">
                <aw-button
                    styleClasses="btn m-r2 btn-simple btn-large waves-effect waves-light"
                    (onClick)="closeModal()"
                    >Cancel
                </aw-button>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RollbackErrorDialogComponent {
    constructor(
        private matDialogRef: MatDialogRef<RollbackErrorDialogComponent>
    ) {}

    closeModal(): void {
        this.matDialogRef.close();
    }
}

import { Injectable, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import {
    catchError,
    concatMap,
    first,
    map,
    mergeMap,
    switchMap,
    tap,
} from 'rxjs/operators';
// import * as AuthActions from '../../storage/auth';
import { SessionService } from '../session';
import { EndPointService } from '../endpoint';
import { IAuthenticationResponse } from '@/shared/interfaces';
import { StorageService } from '../storage';
import { FleetHandlerService } from '../fleet';
import { LoggerService } from '../logger/logger.service';
import { MetricService } from '../metrics/metrics.service';
import { IpcService } from '@services/ipc';
import { interval, Observable, of, Subscription, timer } from 'rxjs';
import { DeviceFactory } from '@/shared/device/device-factory';
import { AwsdkService } from '../awsdk-service';
import { UpdaterHandlerService } from '../updater/update-handler.service';
import { FleetLoggerService } from '../fleet-logger';
import { authFailure } from '@/shared/storage/auth/auth.actions';
import { Router } from '@angular/router';
import { ERROR_MESSAGES } from '@/shared/constants';
import { ActivityMonitorService } from '../activity-monitor/activity-monitor.service';
import { selectIsScreenSaverEnabled } from '@/shared/storage/selectors';
import { navigateToMain } from '@shared/storage/layout/layout.actions';
import { ServiceLineService } from '..';

@Injectable({ providedIn: 'root' })
export class AppInitService implements OnDestroy {
    tokenInterval: Subscription;
    websocketErrorStatusSub: Subscription;
    websocketConnectionRetry = 0;
    lockScreenSub: Subscription;
    isLockScreenEnabled: boolean;
    private _url = '';
    private _retryAuth = 0;
    constructor(
        private _store: Store<{ auth: IAuthenticationResponse }>,
        private _router: Router,
        private _location: Location,
        private _sessionService: SessionService,
        private _endpointService: EndPointService,
        private _storageService: StorageService,
        private _fleetHandlerService: FleetHandlerService,
        private _loggerService: LoggerService,
        private _metricService: MetricService,
        private _ipcService: IpcService,
        private _deviceFactory: DeviceFactory,
        private AwsdkService: AwsdkService,
        private _updateHandlerService: UpdaterHandlerService,
        private _fleetLoggerService: FleetLoggerService,
        private _activityMonitorService: ActivityMonitorService,
        private _serviceLineService: ServiceLineService
    ) {
        this._setupLockScreenState();
    }

    private _setupLockScreenState() {
        this.lockScreenSub = this._store
            .select(selectIsScreenSaverEnabled)
            .subscribe((val) => {
                this.isLockScreenEnabled = val;
            });
    }

    initializeServices(): void {
        this._fleetHandlerService.init();
        this._metricService.init();
        this._loggerService.init();
        this._sessionService.deviceInstance = this._deviceFactory.getDevice();
        this._sessionService.deviceInstance.init();
        this._updateHandlerService.init();
        this._fleetLoggerService.init();
        this._activityMonitorService.init();
    }

    ngOnDestroy(): void {
        if (this.tokenInterval) {
            this.tokenInterval.unsubscribe();
        }
        if (this.websocketErrorStatusSub) {
            this.websocketErrorStatusSub.unsubscribe();
        }
        if (this.lockScreenSub) {
            this.lockScreenSub.unsubscribe();
        }
    }

    bootStrapApp() {
        this._storageService.subscribeOnAuthIsWebsocketConnected(
            (websocketStatus) => {
                if (websocketStatus) {
                    if (this.websocketErrorStatusSub) {
                        this.websocketErrorStatusSub.unsubscribe();
                    }
                    (
                        this._storageService.subscribeOnAuthIsAuthenticated(
                            false
                        ) as Observable<any>
                    ).subscribe((isAuthenticated: boolean) => {
                        this.authenticateAndRedirect(isAuthenticated);
                    });
                } else {
                    this.websocketErrorStatusSub = timer(15000).subscribe(
                        () => {
                            this._router.navigateByUrl('/error-generic', {
                                state: {
                                    errorType: ERROR_MESSAGES.WEBSOCKET_ERROR,
                                    errorStack:
                                        ERROR_MESSAGES.WEBSOCKET_ERROR_MESSAGE,
                                },
                            });
                        }
                    );
                }
            }
        );
    }

    authenticateAndRedirect(isAuthenticated: boolean): void {
        this._url = this._location.path();
        if (isAuthenticated) {
            if (this.isLockScreenEnabled) {
                this._router.navigateByUrl('/lock/home');
            } else {
                this._store.dispatch(navigateToMain());
            }
        } else {
            if (this._retryAuth > 3) {
                this._router.navigateByUrl('/error-generic', {
                    state: {
                        error: ERROR_MESSAGES.AUTH_ERROR,
                        errorStack: ERROR_MESSAGES.AUTH_ERROR_MESSAGE,
                    },
                });
            } else {
                this._retryAuth++;
                this._storageService.authenticate();
            }
        }
    }

    processAuthenticationRequest() {
        const authResponse$ = this._endpointService.authenticate().pipe(
            first(),
            tap((authResponse) => this._sessionService.set(authResponse)),
            switchMap((authResponse: IAuthenticationResponse) => {
                return (
                    this._storageService.subscribeOnConfigChange(false) as any
                ).pipe(
                    map(() => {
                        return [authResponse];
                    }),
                    first(),
                    tap(() => {
                        this.AwsdkService.init();
                        this._serviceLineService.getGenderOptions();
                    })
                );
            }),
            catchError((error) => {
                this._loggerService.error(
                    'endpointService.authenticate',
                    error
                );
                this._store.dispatch(authFailure());
                return of({});
            })
        );

        if (this.tokenInterval) {
            this.tokenInterval.unsubscribe();
        }

        // refresh token every 90 minutes
        this.tokenInterval = interval(1000 * 60 * 90)
            .pipe(switchMap(() => this.getTokens()))
            .subscribe((token) => {
                // eslint-disable-next-line no-console
                console.log(token);
            });

        const systemInfo$ = this._ipcService.requestSystemInfo().pipe(
            first(),
            mergeMap(() => this.getTokens()),
            concatMap(() => authResponse$)
        );

        return systemInfo$;
    }

    getTokens() {
        return this._endpointService
            .getTokens({
                endpointSerialNumber:
                    this._sessionService.deviceInfo.endpointSerialNumber,
                endpointDeviceId: this._sessionService.deviceInfo.deviceId,
                secretKey: this._sessionService.deviceInfo.secretKey,
                enterpriseCode: this._sessionService.deviceInfo.enterpriseCode,
            })
            .pipe(
                first(),
                tap((tokenData) => {
                    this._sessionService.accessToken = tokenData.accessToken;
                }),
                catchError((error) => {
                    this._loggerService.error(
                        'appInitService.getTokens',
                        error
                    );
                    this._store.dispatch(authFailure());
                    return of({});
                })
            );
    }
}

import { createSelector } from '@ngrx/store';

import {
    IDevice,
    IEndpointConfiguration,
    IMediaDevices,
} from '@shared/interfaces';

import {
    fSelectMediaDevicesAudioInput,
    fSelectMediaDevicesAudioOutput,
    fSelectMediaDevicesPeripheralInput,
    fSelectMediaDevicesVideoInput,
    selectConfigs,
    selectMediaDevices,
} from './universal-selectors';

import { FECC_DEVICE_V4LNAME, PERIPHERALS } from '@shared/constants';
import { EndpointTypes } from '@/shared/enums/endpoint-types.enum';
import { getDevicesMockLabels } from '../../constants/mock-devices';
const DEVICE_ORDER_LABELS = {
    Jabra_SPEAK_410: 'Jabra SPEAK 410',
    PCM2704C: 'PCM2704C',
    Built_In: 'Built-in',
    PCM2912A: 'PCM2912A',
    Phnx_MT202: 'Phnx MT202',
    X2u_Adapter: 'X2u Adapter',
    XMOS_VocalFusion: 'XMOS VocalFusion', // 500 built in spk and mic
};

/* istanbul ignore next */
const defaultAudioOutput = (
    mediaDevices: IMediaDevices,
    config: IEndpointConfiguration
): IDevice => {
    const audioOutputs = fSelectMediaDevicesAudioOutput(mediaDevices);
    if (!audioOutputs.length) {
        return;
    }
    const audioOutputsOrder = [
        DEVICE_ORDER_LABELS.Jabra_SPEAK_410,
        DEVICE_ORDER_LABELS.PCM2704C,
    ];

    if (config.endpoint_type_id === EndpointTypes.C500) {
        // 500 device
        audioOutputsOrder.unshift(DEVICE_ORDER_LABELS.Built_In);
        audioOutputsOrder.unshift(DEVICE_ORDER_LABELS.XMOS_VocalFusion);
    } else {
        audioOutputsOrder.push(DEVICE_ORDER_LABELS.Built_In);
    }
    audioOutputsOrder.unshift(DEVICE_ORDER_LABELS.Phnx_MT202); // NYP, UVM need to default Phnx first

    const userSelectedAudioOutput =
        config && config.DefaultAudioOutput === false && config.AudioOutput;
    !!userSelectedAudioOutput && audioOutputsOrder.unshift(config.AudioOutput);

    audioOutputs.forEach((speaker) => audioOutputsOrder.push(speaker.label));
    for (const label of audioOutputsOrder) {
        const item = audioOutputs.find((input) => {
            return input.label.trim().indexOf(label.trim()) >= 0;
        });

        if (item) {
            return item;
        }
    }
};
/* istanbul ignore next */
const defaultAudioInput = (
    mediaDevices: IMediaDevices,
    config: IEndpointConfiguration
): IDevice => {
    const audioInputs = fSelectMediaDevicesAudioInput(mediaDevices);
    if (!audioInputs.length) {
        return;
    }
    const audioInputsOrder = [
        DEVICE_ORDER_LABELS.PCM2912A,
        DEVICE_ORDER_LABELS.X2u_Adapter,
        DEVICE_ORDER_LABELS.Jabra_SPEAK_410,
        DEVICE_ORDER_LABELS.PCM2704C,
    ];
    if (config.endpoint_type_id === EndpointTypes.C500) {
        audioInputsOrder.unshift(DEVICE_ORDER_LABELS.Built_In);
        audioInputsOrder.unshift(DEVICE_ORDER_LABELS.XMOS_VocalFusion);
    } else {
        audioInputsOrder.push(DEVICE_ORDER_LABELS.Built_In);
    }
    audioInputsOrder.unshift(DEVICE_ORDER_LABELS.Phnx_MT202); // NYP, UVM need to default Phnx first

    const userSelectedAudioInput =
        config && config.DefaultAudioInput === false && config.AudioInput;
    !!userSelectedAudioInput && audioInputsOrder.unshift(config.AudioInput);

    audioInputs.forEach((mic) => audioInputsOrder.push(mic.label));

    for (const label of audioInputsOrder) {
        const item = audioInputs.find((input) => {
            return input.label.indexOf(label) >= 0;
        });

        if (item) {
            return item;
        }
    }
};
/* istanbul ignore next */
const defaultVideoInput = (
    mediaDevices: IMediaDevices,
    config: IEndpointConfiguration
): IDevice => {
    const videoInputs = fSelectMediaDevicesVideoInput(mediaDevices);

    if (videoInputs && videoInputs.length > 0) {
        let camera = videoInputs.find((vi) => vi.isFeccEnabled);
        const userSelectedVideoInput =
            config && config.DefaultVideoInput === false && config.VideoInput;

        if (userSelectedVideoInput) {
            camera = videoInputs.find(
                (device) => device.label.indexOf(userSelectedVideoInput) >= 0
            );
        } else {
            for (const name of selectVideoInputsOrder) {
                const item = videoInputs.find((device) => {
                    return device.label.indexOf(name) >= 0;
                });
                if (item) {
                    return item;
                }
            }
        }

        return camera || videoInputs[0];
    }
    return {
        deviceId: null,
    } as IDevice;
};

export const selectVideoInputsOrder = [
    FECC_DEVICE_V4LNAME.AXIS_IP_CAMERA,
    FECC_DEVICE_V4LNAME.MINRRAY_CAMERA_20X,
    FECC_DEVICE_V4LNAME.MINRRAY_CAMERA_10X,
    FECC_DEVICE_V4LNAME.BOLIN_201,
    FECC_DEVICE_V4LNAME.BOLIN_202,
    FECC_DEVICE_V4LNAME.SONY_CAMERA,
    FECC_DEVICE_V4LNAME.EPTZ_CAMERA,
];

export const selectSelectedAudioOutput = createSelector(
    selectMediaDevices,
    selectConfigs,
    (mediaDevices: IMediaDevices, config: IEndpointConfiguration): IDevice => {
        return (
            fSelectMediaDevicesAudioOutput(mediaDevices).find(
                (val) =>
                    val.label === config.AudioOutput &&
                    config.DefaultAudioOutput === false
            ) || defaultAudioOutput(mediaDevices, config)
        );
    }
);

const devicesLabels = [
    'PCP-USB Mono',
    'PCM2900C Audio CODEC Analog Stereo',
    'USB PnP Audio Device Mono',
    'USB PnP Audio Device (0d8c:0134)',
    'UVC Camera (1d6b:0102)',
    'Exam Camera (21cd:0802)',
    'Digital Microscope (21cd:703b)',
    'Digital Microscope (21cd:603b)',
    'DriverCoding UVC Camera (0e8d:210b)',
    ...getDevicesMockLabels(),
];
export const selectAvailableDevices = createSelector(
    selectMediaDevices,
    selectConfigs,
    (mediaDevices: IMediaDevices): IDevice[] => {
        return fSelectMediaDevicesPeripheralInput(mediaDevices).filter((val) =>
            devicesLabels.includes(val.label)
        );
    }
);

export const selectSelectedAudioInput = createSelector(
    selectMediaDevices,
    selectConfigs,
    (mediaDevices: IMediaDevices, config: IEndpointConfiguration): IDevice => {
        return (
            fSelectMediaDevicesAudioInput(mediaDevices).find(
                (val) =>
                    val.label === config.AudioInput &&
                    config.DefaultAudioInput === false
            ) || defaultAudioInput(mediaDevices, config)
        );
    }
);

export const selectSelectedVideoInput = createSelector(
    selectMediaDevices,
    selectConfigs,
    (mediaDevices: IMediaDevices, config: IEndpointConfiguration) => {
        return (
            fSelectMediaDevicesVideoInput(mediaDevices).find(
                (val) =>
                    val.label === config.VideoInput &&
                    config.DefaultVideoInput === false
            ) || defaultVideoInput(mediaDevices, config)
        );
    }
);

export const selectAllConfigs = createSelector(
    selectConfigs,
    (state: IEndpointConfiguration) => state
);

export const selectDefaultDevices = createSelector(
    selectMediaDevices,
    selectConfigs,
    (mediaDevices: IMediaDevices, config: IEndpointConfiguration) => ({
        audio: defaultAudioInput(mediaDevices, config),
        video: defaultVideoInput(mediaDevices, config),
    })
);

export const selectAllDevices = createSelector(
    selectMediaDevices,
    selectConfigs,
    (mediaDevices: IMediaDevices, config: IEndpointConfiguration) => {
        return {
            deviceSource: fSelectMediaDevicesPeripheralInput(mediaDevices)
                .map((item) =>
                    PERIPHERALS.filter((PERIPHERAL) => {
                        return item.label.indexOf(PERIPHERAL.getLabel()) >= 0;
                    })
                )
                .reduce((a, b) => a.concat(b), []),
            cameraSource: fSelectMediaDevicesVideoInput(mediaDevices),
            microphoneSource: fSelectMediaDevicesAudioInput(mediaDevices),
            speakerSource: fSelectMediaDevicesAudioOutput(mediaDevices),

            selectedAudioOutput:
                fSelectMediaDevicesAudioOutput(mediaDevices).find(
                    (val) =>
                        val.label === config.AudioOutput &&
                        config.DefaultAudioOutput === false
                ) || defaultAudioOutput(mediaDevices, config),

            selectedAudioInput:
                fSelectMediaDevicesAudioInput(mediaDevices).find(
                    (val) =>
                        val.label === config.AudioInput &&
                        config.DefaultAudioInput === false
                ) || defaultAudioInput(mediaDevices, config),

            selectedVideoInput:
                fSelectMediaDevicesVideoInput(mediaDevices).find(
                    (val) =>
                        val.label === config.VideoInput &&
                        config.DefaultVideoInput === false
                ) || defaultVideoInput(mediaDevices, config),
        };
    }
);

import { VALIDATORS } from '@/shared/constants';
import { AddressProtocolType } from '@/shared/enums';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export const getWifiConfigForm: FormGroup = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
    connectionMethod: new FormControl(AddressProtocolType.DHCP),
    dns1: new FormControl('', Validators.pattern(VALIDATORS.IP_VALIDATOR)),
    dns2: new FormControl('', Validators.pattern(VALIDATORS.IP_VALIDATOR)),
    dns3: new FormControl('', Validators.pattern(VALIDATORS.IP_VALIDATOR)),
});

export const getAnotherNetworkForm: FormGroup = new FormGroup({
    hiddenSSID: new FormControl('', Validators.required),
    encryption: new FormControl('None'),
    username: new FormControl(''),
    password: new FormControl(''),
});

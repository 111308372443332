<div class="pin-code flex flex-row items-center justify-center m-t3 m-b4 rel" (click)="setFocus()" tabindex="0">
    <div automation="pin0" class="circle m-r2" [ngClass]="{ active: pin.length >= 1 }"></div>
    <div automation="pin1" class="circle m-r2" [ngClass]="{ active: pin.length >= 2 }"></div>
    <div automation="pin2" class="circle m-r2" [ngClass]="{ active: pin.length >= 3 }"></div>
    <div automation="pin3" class="circle m-r2" [ngClass]="{ active: pin.length === 4 }"></div>

    <div style="width: 0px">
        <input
            automation="pinInput"
            type="text"
            #keyboardInput
            [(ngModel)]="pinInput"
            (ngModelChange)="pinInputChanged()"
            (focus)="onFocus.emit(true)"
            (blur)="onBlur.emit(false)"
            id="pinInput"
            name="pinInput"
            maxlength="4"
            class="js-keyboard-input js-keyboard-input-number"
        />
    </div>
    <a class="delete-height" (click)="deleteChar()" automation="deleteCharButton">
        <i class="material-icons-outlined active pointer backspace">backspace</i>
    </a>
    <p automation="errorPin" class="text-center error-dang-text" *ngIf="showError">
        PIN incorrect. Please try again or contact your local IT Admin.
    </p>
</div>

/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    IActiveModal,
    IActiveToast,
    ILayoutState,
    IModalState,
    IToastState,
} from '@/shared/storage';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
const modalConfig = {} as unknown as MatDialogConfig;
export const TEST_ACTIVE_TOAST: IActiveToast = {
    options: {
        ago: 10,
        automationValue: 'automationValue',
        content: {
            title: 'title',
            description: 'description',
            photo: 'photo',
        },
        action: 'action',
        moreNotifications: false,
        isHighPriority: false,
    },
    toastKey: 'toastKey',
    actionCallback: (...args: any) => null,
    closeCallback: (...args: any) => null,
};
export const TEST_ACTIVE_MODAL: IActiveModal = {
    componentName: 'componentName',
    config: modalConfig,
    modalKey: 'modalKey',
    afterClosed: (...args: any) => null,
    afterOpened: (...args: any) => null,
    getDialogRef: (dialogRef: MatDialogRef<any>) => null,
    getModalId: (modalId: string) => null,
};

export const TEST_TOAST_DATA: IToastState = {
    activeToastStack: [TEST_ACTIVE_TOAST],
};

export const TEST_MODAL_DATA: IModalState = {
    activeModalStack: [TEST_ACTIVE_MODAL],
    isOpenSettingModal: false,
};
export const TEST_LAYOUT_DATA: ILayoutState = {
    modal: TEST_MODAL_DATA,
    appUpdate: undefined,
    toast: TEST_TOAST_DATA,
    loadingServiceLines: false,
};

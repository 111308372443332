<div class="row m-0 p0 h100 flex">
    <div class="col l4 form-indent pb3 gray-background flex flex-column items-center">
        <div class="m-t4 m-b4 flex items-center flex-column">
            <span [ngClass]="hardware.icon"></span>
            <h3 automation="endpointName">{{ endpoint?.name }}</h3>
        </div>
        <div class="row m-b1 flex w100 items-baseline">
            <h6 class="col l5 flex justify-end m-b0">Device Type</h6>
            <p class="col l7 m-b0 gray-mid-text foreground2-color" automation="deviceTitle">{{ hardware.title }}</p>
        </div>
        <div class="row m-b1 flex w100 items-baseline">
            <h6 class="col l5 flex justify-end m-b0">OS Version</h6>
            <p class="col l7 m-b0 gray-mid-text foreground2-color" automation="deviceOsVersion">
                {{ deviceInfo?.osVersion }}
            </p>
        </div>
        <div class="row m-b1 flex w100 items-baseline">
            <h6 class="col l5 flex justify-end m-b0">App Version</h6>
            <p class="col l7 m-b0 gray-mid-text foreground2-color" automation="deviceAppVersion">
                {{ deviceInfo?.appVersion }}
            </p>
        </div>
        <div class="row m-b1 flex w100 items-baseline">
            <h6 class="col l5 flex justify-end m-b0">Enterprise</h6>
            <p class="col l7 m-b0 gray-mid-text foreground2-color" automation="deviceName">{{ enterprise?.name }}</p>
        </div>
        <div class="row m-b1 flex w100 items-baseline">
            <h6 class="col l5 flex justify-end m-b0">Location</h6>
            <p class="col l7 m-b0 gray-mid-text foreground2-color" automation="deviceFacilityName">
                {{ endpoint?.facility_name }}
            </p>
        </div>
        <div class="row m-b1 flex w100 items-baseline" *ngIf="hardware?.deviceSerialNumber as deviceSerialNumber">
            <h6 class="col l5 flex justify-end no-wrap m-b0">Hardware S/N</h6>
            <p class="col l7 m-b0 gray-mid-text" automation="deviceSerialNumber">{{ deviceSerialNumber }}</p>
        </div>
        <div class="row m-b1 flex w100 items-baseline" *ngIf="hardware?.firmwareVersion as firmwareVersion">
            <h6 class="col l5 flex justify-end m-b0">Firmware</h6>
            <p class="col l7 m-b0 gray-mid-text" automation="firmwareVersion">{{ firmwareVersion }}</p>
        </div>
        <div class="flex items-end justify-center h100 logo-box m-t4">
            <img automation="logo" [src]="logo" width="300px" height="73px" />
        </div>
    </div>
    <div class="col l8 px2 py3 form-indent overflow-y">
        <div class="flex items-center justify-between m-b35">
            <div class="row w100 flex items-center m-b0">
                <div class="col l4">
                    <h6 class="m-b0">Auto Answer</h6>
                </div>
                <div class="col l8">
                    <p class="m-b0 gray-mid-text">Incoming calls will automatically start</p>
                </div>
            </div>
            <div class="switch m-l3">
                <aw-switch
                    automationValue="configAutoAnswer"
                    [ngModel]="config?.auto_answer"
                    (onChange)="updateConfigSettings('auto_answer', !config?.auto_answer)"
                ></aw-switch>
            </div>
        </div>
        <div *ngIf="endpoint?.endpoint_type_id === 'C500'">
            <div class="flex justify-between m-b35">
                <div class="row w100 flex m-b0">
                    <div class="col l4">
                        <h6 class="m-b0">Display Sleep</h6>
                    </div>
                    <div class="col l8">
                        <p class="m-b0 gray-mid-text">Set a timer to power off your display.</p>
                        <div *ngIf="config.enable_turn_off_display">
                            <aw-select
                                automationValue="configTurnOffDisplayTimeout"
                                [ngModel]="config.turn_off_display_timeout"
                                (onChange)="onChangeSleepTime($event)"
                                label="Display Sleep"
                                dropdownContainer=".modal-content-wrapper"
                                [options]="displaySleepOptions"
                            >
                            </aw-select>
                        </div>
                    </div>
                </div>
                <div class="switch m-l3">
                    <aw-switch
                        automationValue="configTurnOffDisplay"
                        [ngModel]="config.enable_turn_off_display"
                        (onChange)="updateConfigSettings('enable_turn_off_display', !config.enable_turn_off_display)"
                    >
                    </aw-switch>
                </div>
            </div>
        </div>
        <div class="flex items-center justify-between m-b35">
            <div class="row w100 flex items-center m-b0">
                <div class="col l4">
                    <h6 class="m-b0">On Screen Keyboard</h6>
                </div>
                <div class="col l8">
                    <p class="m-b0 gray-mid-text">Disable only if using USB keyboard</p>
                </div>
            </div>
            <div class="switch m-l3">
                <aw-switch
                    automationValue="configKeyboard"
                    [ngModel]="config?.onscreen_keyboard"
                    (onChange)="updateConfigSettings('onscreen_keyboard', !config?.onscreen_keyboard)"
                >
                </aw-switch>
            </div>
        </div>
        <div class="flex items-center justify-between m-b35">
            <div class="row w100 flex items-center m-b0">
                <div class="col l4">
                    <h6 class="m-b0">Camera Privacy Mode</h6>
                </div>
                <div class="col l8">
                    <p class="m-b0 gray-mid-text">Move the <u>external</u> camera to the side when not in a call</p>
                </div>
            </div>
            <div class="switch m-l3">
                <aw-switch
                    automationValue="configCameraPrivacyMode"
                    [ngModel]="config.camera_privacy_mode"
                    (onChange)="updateConfigSettings('camera_privacy_mode', !config.camera_privacy_mode)"
                >
                </aw-switch>
            </div>
        </div>
        <div class="flex items-center justify-between m-b35" *ngIf="endpoint?.endpoint_type_id === 'C500'">
            <div class="row w100 flex items-center m-b0">
                <div class="col l4">
                    <h6 class="m-b0">Proximity Wake</h6>
                </div>
                <div class="col l8">
                    <p class="m-b0 gray-mid-text">Automatically wake the device when a user is in front</p>
                </div>
            </div>
            <div class="switch m-l3">
                <aw-switch
                    automationValue="configProximityWake"
                    [ngModel]="config.proximity_wake"
                    (onChange)="updateConfigSettings('proximity_wake', !config.proximity_wake)"
                >
                </aw-switch>
            </div>
        </div>
        <div *ngIf="endpoint?.endpoint_type_id === 'C500'">
            <div class="flex justify-between m-b35">
                <div class="row w100 flex m-b0">
                    <div class="col l4">
                        <h6 class="m-b0">Auto Worksurface Lights</h6>
                    </div>
                    <div class="col l8">
                        <p class="m-b0 gray-mid-text">Illuminate the worksurface based on ambient intensity</p>
                        <div class="flex" *ngIf="config.worksurface_lights">
                            <aw-radio-button
                                automationValue="worksurface{{ item }}"
                                *ngFor="let item of worksurfaceLightsValues"
                                [ngModel]="config.worksurface_lights_selected"
                                [radioValue]="item"
                                name="worksurfaceLights"
                                (onChange)="updateConfigSettings('worksurface_lights_selected', item)"
                            >
                                {{ item }}
                            </aw-radio-button>
                        </div>
                    </div>
                </div>
                <div class="switch m-l3">
                    <aw-switch
                        automationValue="configWorksurfaceLights"
                        [ngModel]="config.worksurface_lights"
                        (onChange)="updateConfigSettings('worksurface_lights', !config.worksurface_lights)"
                    >
                    </aw-switch>
                </div>
            </div>
        </div>
        <aw-expansion (activeItemChange)="changeExpansion($event)" [expansionType]="'accordion'">
            <aw-expansion-item [elementId]="'advanced'">
                <ng-container aw-expansion-title> Advanced </ng-container>
                <ng-container aw-expansion-body>
                    <h5 class="m-b2 m-t2">Factory default</h5>
                    <h6 class="danger-text">
                        CAUTION: Using this option will wipe all configuration from the device and reset to its out of
                        the box state. You should only use this option if instructed by your local IT admin or an Amwell
                        support representative.
                    </h6>
                    <aw-button
                        automationValue="factoryDefaultButton"
                        styleClasses="waves-effect waves-light btn btn-danger p-50 m-t3 modal-trigger btn-large"
                        data-target="modal-reset"
                        name="factorydefault"
                        type="button"
                        (onClick)="showConfirmModal()"
                    >
                        Factory Default
                    </aw-button>
                </ng-container>
            </aw-expansion-item>
            <aw-expansion-item [elementId]="'legal-information'">
                <ng-container aw-expansion-title> Legal Information </ng-container>
                <ng-container aw-expansion-body>
                    <p class="gray-mid-text small">
                        <b>Indications</b><br />
                        This device enables secure and effective audio/video communication between multiple healthcare
                        providers and between providers and patients. The system transmits real-time audio and video
                        captured by a high-definition camera and integrated microphone. It also receives real-time audio
                        and video from a similar system in a remote location. Finally, the device supports transmission
                        of real-time audio and video from peripheral audio/visual medical devices, which may be
                        connected to standard audio and video inputs provided on the system. The device is intended to
                        facilitate remote provider/patient and provider/provider consultations. These consultations
                        should always be conducted with a licensed medical professional physically in the room with the
                        patient.
                        <br /><b>Contraindications</b><br />
                        This device is not intended to substitute for the in-person physical examination of a patient or
                        as a substitute for direct medical intervention. It is also not intended for real-time, active,
                        or online patient monitoring, nor is it intended to provide time sensitive data or alarms. This
                        device does not support transmission of numerical telemetric/serial data and is not intended for
                        use with non-audio/visual medical devices.
                    </p>
                </ng-container>
            </aw-expansion-item>
            <aw-expansion-item elementId="rollback">
                <ng-container aw-expansion-title> Revert Software </ng-container>
                <ng-container aw-expansion-body>
                    <p class="sized-txt">
                        <b>
                            If you have experienced technical difficulties, try resetting your device back to its
                            factory settings or a previous version of the application. If the problem persists, please
                            contact your Amwell representative.
                        </b>
                    </p>
                    <aw-button
                        automationValue="rollbackSoftwareButton"
                        styleClasses="waves-effect waves-light btn btn-danger p-50 m-t3 modal-trigger btn-large"
                        name="rollbackSoftware"
                        type="button"
                        (onClick)="openRollbackDialog()"
                    >
                        Revert Software
                    </aw-button>
                </ng-container>
            </aw-expansion-item>
        </aw-expansion>
    </div>
</div>

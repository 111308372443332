import {
    IAuthenticationResponse,
    IDeviceInfo,
    IEndpointConfiguration,
} from '@/shared/interfaces';
import { EndpointTypes } from '@shared/enums';

export const TEST_CONFIGURATION: IEndpointConfiguration = {
    update_type: 'simple',
    auto_answer: false,
    onscreen_keyboard: true,
    disable_peripheral_snapshots: false,
    peripheral_recordings: true,
    enable_error_logs: true,
    enable_info_logs: true,
    fecc_enabled: false,
    rect_enabled: false,
    AudioOutputDefaultVolume: 13,
    AudioInputDefaultGain: 50,
    RingtoneDefaultVolume: 20,
    disable_case_creation: false,
    enable_turn_off_display: true,
    turn_off_display_timeout: 10,
    enable_screensaver: false,
    enable_settings_pin: false,
    screensaver_timeout: 1,
    screensaver_pin: '1111',
    settings_pin: '1111',
    fleet_url: 'https://fleet-api-dev.avizia.com/',
    endpoint_type_id: EndpointTypes.ApgarCodec,
    turn_off_display: false,
    proximity_wake: false,
    camera_privacy_mode: true,
};

export const TEST_DEVICE_DATA: IAuthenticationResponse = {
    consumer: {
        created_at: '1610964256233',
        member_source_id: '3353343f-572f-46fb-9d81-8259ebd8b1be',
        password: '8520a31c72ad468ab8782239566fa390',
        updated_at: '1610964256233',
        username: '3353343f-572f-46fb-9d81-8259ebd8b1be',
    },
    endpoint: {
        activated_on: '1610964253574',
        configuration: JSON.stringify(TEST_CONFIGURATION),
        created_at: '1610962595531',
        created_by: null,
        description: '',
        direct_sip: null,
        endpoint_device_id: '8d14ce29-6e13-4b42-853d-4f5cbadcdd1b',
        endpoint_id: '3353343f-572f-46fb-9d81-8259ebd8b1be',
        endpoint_photo_url: null,
        endpoint_serial_number: 'C02Z9371JWDX',
        endpoint_type_id: EndpointTypes.ApgarCodec,
        enterprise_code: 'autointtest',
        face_enabled: null,
        facility_id: 'facility3',
        facility_name: 'Facility 3',
        fecc_enabled: false,
        is_activated: true,
        is_favorite: false,
        lookandfeel: null,
        name: 'Alex Test',
        phone_number: null,
        photo_id: null,
        protocol_handler: null,
        receive_alerts: false,
        rect_enabled: false,
        room_id: null,
        room_name: null,
        settings: null,
        sip_address: null,
        updated_at: '1610964253574',
        updated_by: null,
        vidyo_address: null,
        enable_fleet_timeout: false,
        fleet_url: 'https://fleet-api-dev.avizia.com/',
    },
    enterprise: {
        addCaseFromWeb: true,
        allowCollaboration: true,
        allowDailOut: true,
        allowEscalation: true,
        allowPrescription: true,
        allowSip: true,
        apgar_code_id: null,
        code: 'autointtest',
        database_id: '5cbb5577-3b75-459d-b3b3-35503150c5ed',
        email_settings: {
            confirm: 'confirm.email@avizia.com',
            invite: 'invite@avizia.com',
            operator_message: 'noreply@amwell.com',
            qr_code: 'qrcode@avizia.com',
            reply_to: 'noreply@amwell.com',
            reset_password: 'password-reset@avizia.com',
        },
        enterprise_id: '4fd83c33-cc4a-4dc2-88a8-62b134d79262',
        logo_url: '',
        name: 'Avizia Enterprise',
        ocg_integration_link: 'https://iot16.amwellintegration.com',
        sdk_key: 'consumerWebSdkApiKeyTest1',
        patientWebRTC: true,
        touchpoint_code_id: null,
    },
};

export const TEST_DEVICE_INFO_DATA: IDeviceInfo = {
    appVersion: '2.1.9',
    osVersion: '1.0.1',
    rollbackAppVersion: '1.0.0',
    rollbackOsVersion: '1.0.0',
    hostName: 'amwell',
    ethHwAddress: '11:22:33:44:55:66',
    wifiHwAddress: 'AA:BB:CC:DD:EE:FF',
    environment: '',
    deviceId: 'MOCK_DEVICE_ID',
    secretKey: '',
    endpointSerialNumber: '',
    enterpriseCode: '',
    deviceType: '',
    codecVersion: '',
    onScreenKeyboardOpened: false,
};
